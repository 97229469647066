<template>

  <div>
    <SelectTagsModal :ref="`selectTagsModal${windowId}`"
                     :treeNode="treeNode"
                     :node="node"
                     :windowId="windowId"
                     :resource="selectedResource"
                     @select="select"/>
    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>
  </div>

</template>

<script>
  import axios from 'axios'
  import MnemoService from '@/services/mnemo.service.js'
  import SelectTagsModal from "@/views/graphic/SelectTagsModal";
  import PaletteColors from '@/tools/palette.colors'

  export default {

    name: "Configurator",

    components: {
      SelectTagsModal
    },

    props: ["windowId"],

    data() {
      return {
        isLoading: false,

        result: {
          mnemoInstance: {},
          selectedTags: [],
        },
        selectedMnemoPrimitive: null,
        selectedMnemoDevice: null,

        typeParameters: [],
        isEditMode: false,

        groupId: null,

        node: null,
        treeNode: null,
        selectedNodes: [],
        palette: [],

        selectedResource: null,
      }
    },

    mounted() {
      this.palette = PaletteColors.getPalette();
      this.$emit("mounted");
    },

    methods: {

      init(mnemoInstanceId, typeParameters, isEditMode, groupId, windowId) {

        this.groupId = groupId;
        //this.windowId = windowId;

        if (this.treeNode === null) {
          this.node = {
            id: this.groupId,
            expanded: true,
            isRoot: true,
            isObject: true
          };
          this.treeNode = {};
          this.treeNode.children = [];
          this.treeNode.children.push(this.node);
        }
        //console.log('Init wId:' + this.windowId + ', gId:' + this.groupId + ', mId:' + mnemoInstanceId);

        if (mnemoInstanceId) {
          this.result.selectedTags = [];

          this.isEditMode = isEditMode;
          this.typeParameters = typeParameters;

          this.getGraphic(mnemoInstanceId, groupId);
        } else {
          this.getTimePeriods();
        }
      },

      selectResource(resource) {
        this.selectedResource = resource;
        this.$refs[`selectTagsModal${this.windowId}`].changed(); 
      },

      selectTags() {
        //console.log(this.$refs);
        this.$refs[`selectTagsModal${this.windowId}`].selectedTags = this.result.selectedTags; 
        this.$bvModal.show("selectTagsModal" + this.windowId);
      },

      select() {
        //console.log('select');
        //console.log(this.result.mnemoInstance);

        //this.result.selectedTags = [];
        this.lookingForSelectedTags(this.treeNode.children);
        this.bindColor();
        //console.log(this.result.selectedTags);

        this.$emit("select", this.result);
      },

      remove(tag) {

        //console.log(tag);
        //console.log(this.treeNode);

        const group = this.lookingForNode(this.treeNode.children, tag.id);
        //console.log(group);
        if (group) group.selected = 'not_accepted';

        const tagIndex = this.result.selectedTags.findIndex(a => a.id === tag.id);
        this.result.selectedTags.splice(tagIndex, 1);

        //console.log(this.result.selectedTags);
        this.$emit("select", this.result);
      },

      lookingForNode(nodes, nodeId) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          if (node.id === nodeId) return node;
          else if (node.children && node.children.length > 0) {
            node = this.lookingForNode(node.children, nodeId);
            if (node) return node;
          }
        }
        return null;
      },

      lookingForSelectedTags(nodes) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          //node.parent = parent;
          const index = this.result.selectedTags.findIndex(a => a.id === node.id);
          if (node.selected === 'accepted') {
            if (index === -1) this.result.selectedTags.push(node);
          } else {
            if (index !== -1) this.result.selectedTags.splice(index, 1);
          }
          if (node.children) {
            this.lookingForSelectedTags(node.children);
          }
        }
        return null;
      },

      updateColor(selectedTagId, style) {
        const tag = this.result.selectedTags.find(a => a.id === selectedTagId);
        tag.style = style;
      },

      bindColor() {
        if (this.result.mnemoInstance.mnemoJSONSettings) {
          const settings = JSON.parse(this.result.mnemoInstance.mnemoJSONSettings);
          for (let i = 0; i < this.result.selectedTags.length; i++) {
            const selectedTag = this.result.selectedTags[i];
            const set = settings.colors.find(a => a.objectLogicTagId === selectedTag.objectLogicTagId);
            if (set) {
              selectedTag.style = this.findColor(set);
            } else {
              const random7 = Math.floor(Math.random() * 7);
              const random10 = Math.floor(Math.random() * 10);
              selectedTag.style = this.palette[random7].styles[random10];
            }
          }
        }
        else {
          for (let i = 0; i < this.result.selectedTags.length; i++) {
            const selectedTag = this.result.selectedTags[i];
            const random7 = Math.floor(Math.random() * 7);
            const random10 = Math.floor(Math.random() * 10);
            selectedTag.style = this.palette[random7].styles[random10];
          }
        }
      },

      findColor(set) {
        let style = null;
        for (let i = 0; i < this.palette.length; i++) {
          const row = this.palette[i];
          style = row.styles.find(a => a.id === set.styleId);
          if (style) break;
        }
        return style ? style : this.palette[0].styles[0];
      },

      getTimePeriods() {

        this.isLoading = true;
        MnemoService.getTimePeriods()
          .then(response => {
            this.result.mnemoInstance.timePeriods = response.data ? response.data : [];
            this.isLoading = false;

            this.$emit("update", this.result);
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      },

      getGraphic(mnemoInstanceId) {

        this.isLoading = true;
        //console.log('try get mnemoInstanceId: ' + mnemoInstanceId);
        MnemoService.getGraphic(mnemoInstanceId)
          .then(response => {
            this.result.mnemoInstance = response.data ? response.data : null;
            if (this.result.mnemoInstance) {
              //console.log('mnemoInstanceId: ' + mnemoInstanceId + ', groupId:' + this.groupId + ' loaded');
              //console.log(this.result.mnemoInstance);
              this.result.selectedTags = JSON.parse(JSON.stringify(this.result.mnemoInstance.graphics));
              this.bindColor();
            }
            this.isLoading = false;

            this.$emit("update", this.result);
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      },

      saveGraphic(settings) {

        this.isLoading = true;

        this.result.mnemoInstance.mnemoJSONSettings = JSON.stringify(settings);
        this.result.mnemoInstance.windowId = this.windowId;
        this.result.mnemoInstance.groupId = this.groupId;
        this.result.mnemoInstance.graphics = this.result.selectedTags;

        //console.log(this.result.mnemoInstance);

        MnemoService.saveGraphic(this.result.mnemoInstance)
          .then(response => {
            //console.log(response.data);
            this.result.mnemoInstance = response.data ? response.data : null;
            if (this.result.mnemoInstance) {
              //console.log('mnemoInstanceId: ' + mnemoInstanceId + ', groupId:' + this.groupId + ' loaded');
              //console.log(this.result.mnemoInstance);
              this.result.selectedTags = JSON.parse(JSON.stringify(this.result.mnemoInstance.graphics));
              this.bindColor();

              this.$emit("save", this.result, this.result.mnemoInstance.id);
            }
            this.isLoading = false;

            //this.$bvModal.msgBoxOk(messageBoxText);
          })
          .catch(error => {
            //console.log(error.response.data.errors);
            this.$emit("error");
            if(error.response.data.errors.Name) this.$bvModal.msgBoxOk(error.response.data.errors.Name[0]);
            this.isLoading = false;
          })
      },

    },
  };
</script>
