<template>
  <b-modal id="primitiveBindingModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           size="xl"
           :title="modalTitle"
           cancel-title="Отмена"
           modal-class="inmodal"
           header-class="p-2"
           v-bind:hide-footer="true"
           @shown="modalShown">
    <template v-slot:modal-header="{ ok, cancel }">

      <b-button variant="primary"
                :disabled="isBusy"
                @click="savePrimitiveBinding()">
        <b-spinner small v-show="isBusy" class="mr-1"></b-spinner>
        <span>Сохранить</span>
      </b-button>

      <b-button variant="primary"
                @click="cancel()">Закрыть</b-button>
    </template>

    <b-container fluid v-if="value">
      <b-table :items="value.mnemoTags" :fields="fieldsTags" class="table-striped table-borderless">

        <template #cell(argname)="data">
          {{ data.item.mnemoArgumentName }}
        </template>
        <template #cell(devicecode)="data">
          {{ data.item.primitiveDeviceCode }}
        </template>
        <template #cell(varcode)="data">
          {{ data.item.variableName }}
        </template>
        <template #cell(typeparam)="data">
          {{ data.item.typeParametersName }}
        </template>
        <template #cell(devicename)="data">
          {{ data.item.primitiveDeviceName }}
        </template>
        <template #cell(selectedvarcode)="data">
          {{ data.item.objectLogicTagName }}
        </template>

        <template #cell(selectbutton)="data">
          <b-button v-bind:disabled="!data.item.isDeviceBinded && !data.item.OK"
                    v-bind:class="{ bindedDeviceOK: data.item.OK }"
                    variant="primary"
                    v-on:click="selectVariableForPrimitiveTag(data.item)"
                    title="Выбрать тег">
            <i class="fa fa-ellipsis-h"></i>
          </b-button>
        </template>

        <template #cell(resetbutton)="data">
          <b-button v-bind:disabled="!data.item.isDeviceBinded && !data.item.OK"
                    variant="primary"
                    v-on:click="resetPrimitiveTagBinding(data.item)"
                    title="Сбросить привязку">
            <i class="fa fa-close"></i>
          </b-button>
        </template>

      </b-table>

      <b-row>
        <b-col md="12">
          <label class="control-label">Скрипт</label>
          <b-button type="button"
                    variant="primary"
                    style="margin-left:10px"
                    @click="openPrimitiveCommentDialog">
            <i class="fa fa-info"></i>
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <prism-editor class="my-editor" v-model="value.script" :highlight="highlighter" line-numbers></prism-editor>
        </b-col>
      </b-row>

    </b-container>

    <PrimitiveSelectTagModal v-model="selectedMnemoPrimitiveTag" :node="node" v-on:select="select" />

    <InfoModal v-if="value" v-model="value.svgComment" />

  </b-modal>
</template>

<script>

  import MnemoService from '@/services/mnemo.service.js'
  import InfoModal from '@/views/mnemo/InfoModal'
  import PrimitiveSelectTagModal from '@/views/mnemo/PrimitiveSelectTagModal'
  import { PrismEditor } from 'vue-prism-editor';
  import 'vue-prism-editor/dist/prismeditor.min.css';
  import { highlight, languages } from 'prismjs/components/prism-core';
  import 'prismjs/components/prism-clike';
  import 'prismjs/components/prism-javascript';
  import 'prismjs/themes/prism-tomorrow.css';

  export default {

    name: "PrimitiveBindingModal",

    components: {
      PrimitiveSelectTagModal, InfoModal, PrismEditor
    },

    data() {
      return {
        isBusy: false,
        fieldsTags: [
          { key: 'argname', label: 'Наименование' },
          { key: 'devicecode', label: 'Прибор' },
          { key: 'varcode', label: 'Теги прибора на мнемосхеме' },
          { key: 'typeparam', label: 'Ресурс' },
          { key: 'devicename', label: 'Прибор' },
          { key: 'selectedvarcode', label: 'Теги выбранного прибора' },
          { key: 'selectbutton', label: '' },
          { key: 'resetbutton', label: '' },
        ],
        selectedMnemoPrimitiveTag: null,
        node: null,
      };
    },

    props: ["value", "mnemoInstanceId", "bindedDevices"],

    methods: {

      openPrimitiveCommentDialog() {
        this.$bvModal.show("infoModal");
      },

      highlighter(code) {
        return highlight(code, languages.js);
      },

      modalShown(e) {
        this.isBusy = true;
        this.value.mnemoInstanceId = this.mnemoInstanceId;
        MnemoService.primitivesBinding(this.value)
          .then(response => {
            this.value.script = response.data.script;
            this.value.svgComment = response.data.svgComment;
            const bindedTags = response.data.mnemoTags;
            for (let j = 0; j < this.value.mnemoTags.length; j++) {
              const primitiveTag = this.value.mnemoTags[j];
              primitiveTag.isDeviceBinded = false;
              const bindedTag = bindedTags.find(a => a.variableName === primitiveTag.variableName && a.primitiveDeviceCode === primitiveTag.primitiveDeviceCode);
              //console.log(bindedTag);
              if (bindedTag) {
                primitiveTag.objectLogicTagName = bindedTag.objectLogicTagName;
                primitiveTag.objectLogicTagId = bindedTag.objectLogicTagId;
                primitiveTag.primitiveDeviceName = bindedTag.primitiveDeviceName;
                primitiveTag.isDeviceBinded = true;
              };
            };
            this.isBusy = false;
          });
      },

      savePrimitiveBinding() {
        this.isBusy = true;
        this.value.mnemoInstanceId = this.mnemoInstanceId;
        //this.updatedMnemoPrimitiveName = this.value.name;
        MnemoService.updateMnemoPrimitive(this.value)
          .then(response => {
            //console.log(response.data);
            this.$emit("update", response.data);
            this.checkBinding();

            this.isBusy = false;
          })
          .catch(error => {
            console.log(error);
            this.isBusy = false;
          });
      },

      select(selectedVariable) {
        //console.log(this.selectedMnemoPrimitiveTag);
        if (this.selectedMnemoPrimitiveTag && selectedVariable) {
          this.selectedMnemoPrimitiveTag.objectLogicTagId = selectedVariable.objectLogicTagId;
          this.selectedMnemoPrimitiveTag.objectLogicTagName = selectedVariable.name;
          this.selectedMnemoPrimitiveTag.primitiveDeviceName = selectedVariable.objectLogicName;
        };

        this.checkBinding();
        this.primitiveSelectTagDialog = false;
      },

      selectVariableForPrimitiveTag(mnemoTag) {
        this.selectedMnemoPrimitiveTag = mnemoTag;
        var bindedDevice = this.bindedDevices.find(a => a.mnemoDeviceCode === mnemoTag.primitiveDeviceCode);
        if (bindedDevice) {
          this.node = {
            id: bindedDevice.objectLogicId,
            objectLogicId: bindedDevice.objectLogicId,
            name: bindedDevice.objectLogicName,
            isDevice: true,
            expanded: true,
          };
          this.$bvModal.show("primitiveSelectTagModal");
        }
      },

      resetTagBinding(mnemoTag) {
        //console.log(mnemoTag);
        mnemoTag.objectLogicTagId = null;
        mnemoTag.objectLogicTagName = null;
        this.checkBinding();
      },

      resetPrimitiveTagBinding(mnemoTag) {
        mnemoTag.primitiveDeviceName = null;
        this.resetTagBinding(mnemoTag);
      },

      checkBinding() {
        this.value.mnemoTags.forEach(function (bindedPrimitiveTag, i, arr) {
          if (bindedPrimitiveTag.objectLogicTagId !== null) bindedPrimitiveTag.OK = true;
        });
        const isNotPrimitiveOk = this.value.mnemoTags.find(a => a.objectLogicTagId === null);
        if (!isNotPrimitiveOk) this.value.OK = true;
      },
    },
    computed: {
      modalTitle: function () {
        return this.value && this.value.id
          ? `Свойства примитива ${this.value.name}` : "";
      },
    },
  };
</script>

<style scoped>

  .bindedDeviceOK {
    background-color: #c0f0c0;
  }

  .my-editor {
    /*background: #383C4A;*/
    font-family: Fira code, Fira Mono, Consolas, Menlo, Courier, monospace;
    font-size: 14px;
    line-height: 1.5;
    padding: 5px;
    height: 200px;
  }

</style>
