<template>
  <div>
    <VueApexCharts :ref="`realtimeChart${windowId}`"
                   type="line"
                   :options="options"
                   :series="series"
                   :width="chartWidth"
                   :height="chartHeight">
    </VueApexCharts>
  </div>
</template>

<script>
  import Vue from 'vue'
  import VueApexCharts from 'vue-apexcharts'

  Vue.use(VueApexCharts)

  export default {

    name: 'ApexChartsControl',

    props: ['value', 'windowId', 'graphicName'],

    components: {
      VueApexCharts
    },

    data() {
      return {
        options: {
          chart: {
            show: false,
            id: 'vuechart-' + this.windowId,
          },
          stroke: {
            curve: 'smooth', width: 1
          },
          xaxis: {
            show: false,
            tickAmount: 4,
          },
          yaxis: {
            show: false,
            tickAmount: 4,
          }
        },
        series: [],
        chartWidth: 0,
        chartHeight: 0,
        minX: null,
        maxX: null,
        minY: null,
        maxY: null,
      }
    },

    mounted() {
      //console.log('mounted');
    },

    methods: {

      hideChart() {
        //console.log(result);
        this.$refs[`realtimeChart${this.windowId}`].updateOptions({
          chart: { width: 0 },
        }, true, true)
          .then(response => {
            console.log('hide chart');
          });
      },

      resize(settings, selectedTags, allValues, width, height) {
        const self = this;
        //console.log('resize ' + this.windowId + ' ' + width + ' ' + height);
        //console.log(allValues);
        this.minX = null;
        this.maxX = null;
        this.minY = null;
        this.maxY = null;
        this.chartWidth = width;
        this.chartHeight = height;
        setTimeout(function () {
          self.updateResults(settings, selectedTags, allValues);
        }, 100);
      },

      updateResults(settings, selectedTags, allValues) {

        const self = this;
        //console.log(this.windowId);
        //console.log(allValues);
        //console.log(settings);
        //console.log(selectedTags);

        const series = [];
        const colors = [];

        allValues.map(a => {
          const date = new Date(a.TimeStamp);
          //console.log(date);
          const offset = date.getTimezoneOffset() / 60;
          date.setHours(date.getHours() - offset);
          a.Date = date;
        });
        
        for (let i = 0; i < selectedTags.length; i++) {
          const tag = selectedTags[i];
          const tagValues = allValues.filter(a => a.IdTag === tag.objectLogicTagId);
          //console.log(tag.objectLogicTagId);
          //console.log(tagValues);
          if (tagValues.length > 0) {
            series.push(
              {
                name: tag.objectLogicName + ' ' + tag.name,
                type: settings.chartType === 1 ? 'line' : settings.chartType === 2 ? 'bar' : 'scatter',
                data: tagValues.map(a => {

                  if (this.maxY === null || this.maxY < a.Value) this.maxY = a.Value;
                  if (this.minY === null || this.minY > a.Value) this.minY = a.Value;
                  if (this.maxX === null || this.maxX < a.Value) this.maxX = a.Date;
                  if (this.minX === null || this.minX > a.Value) this.minX = a.Date;

                  return {
                    x: a.Date,
                    y: a.Value
                  }
                })
              });

            if (tag.style && tag.style.backgroundColor) {
              colors.push(tag.style.backgroundColor);
            } else {
              colors.push('#000000');
            }
          }
        }

        //if (series.length > 0) {
        //  console.log(this.minX);
        //  console.log(this.maxX);
        //  console.log(this.minY);
        //  console.log(this.maxY);
        //}

        //console.log(series);
        //console.log(colors);
        //if (settings.legend) console.log(settings.legend);

        if (series.length === 0) {
          this.$refs[`realtimeChart${this.windowId}`].updateOptions({
            noData: {
              text: "За данный период нет данных",
              align: "center",
              verticalAlign: "middle",
            },
          });
        }

        this.$refs[`realtimeChart${this.windowId}`].updateOptions({
          legend: settings.legend ? settings.legend : {},
        });

        this.$refs[`realtimeChart${this.windowId}`].updateOptions({
          series: series,
          colors: colors,
        });

        this.$refs[`realtimeChart${this.windowId}`].updateOptions({
          title: {
            text: this.graphicName !== null ? this.graphicName : '',
            align: 'center',
            margin: 0,
          },
          grid: {
            show: series.length > 0,
            padding: {
              bottom: 2,
            },
          },
          chart: {
            show: series.length > 0,
            id: 'vuechart-' + this.windowId,
            toolbar: {
              offsetX: -20,
              offsetY: -3,
              tools: {
                download: false,
                selection: false,
                zoom: false,
                zoomin: true,
                zoomout: true,
                pan: false,
              },
            },
          },
          xaxis: {
            show: series.length > 0 && settings.graphicTimeScale === 'accepted',
            type: 'datetime',
            min: this.minX,
            max: this.maxX,
            tickAmount: 4,
            tickPlacement: 'on',
            labels: {
              style: {
                colors: '#a0a0a0',
              },
              offsetX: 2,
              show: settings.graphicTimeScale === 'accepted',
              rotate: 0,
              formatter: function (timestamp) {
                return [self.timeFormatting(timestamp, settings.timeFormat), self.dateFormatting(timestamp, settings.dateFormat)];
              },
            },
          },
          yaxis: {
            type: 'numeric',
            tickAmount: 4,
            tickPlacement: 'on',
            show: series.length > 0 && settings.graphicValuesScale === 'accepted',
            min: settings.scaleValue == 2 ? settings.scaleValueMin : this.minY,
            max: settings.scaleValue == 2 ? settings.scaleValueMax : this.maxY,
            labels: {
              style: {
                colors: '#a0a0a0',
              },
              show: settings.graphicValuesScale === 'accepted',
              formatter: (value) => {
                return value ? value.toFixed(2) : '';
              },
            }
          },
          tooltip: {
            x: {
              show: false,
            },
            y: {
              title: {
                formatter: function (seriesName) {
                  return '&nbsp;';
                }
              }
            }
          },
          stroke: {
            width: 1,
            curve: settings.chartType === 1 && settings.lineCurveSmooth === 'accepted' ? 'smooth' : 'stepline',
            // OR provide an array curve: ['smooth', 'straight', 'stepline']
          },
          markers: {
            colors: colors,
            size: settings.chartType === 3 ? 2 : 0,
            shape: "circle",
          },
        }, true, true)
          .then(response => {
            //console.log(response);
          });
      },

      dateFormatting(timestamp, dateFormat) {
        const months = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'];

        const date = new Date(timestamp);
        const year = date.getFullYear();
        const monthOfYear = date.getMonth() + 1;
        const month = "" + (monthOfYear > 9 ? "" : "0") + monthOfYear;
        const dayOfMonth = date.getDate();
        const day = "" + (dayOfMonth > 9 ? "" : "0") + dayOfMonth;

        if (dateFormat === 1) return "" + day + "." + month + "." + year;
        else if (dateFormat === 2) return "" + day + "." + months[monthOfYear-1];
        else return "" + day + "/" + month + "/" + year;
      },

      timeFormatting(timestamp, timeFormat) {

        const date = new Date(timestamp);
        const hours = date.getHours();
        const hour = "" + (hours > 9 ? "" : "0") + hours;
        const minutes = date.getMinutes();
        const minute = "" + (minutes > 9 ? "" : "0") + minutes;
        const seconds = date.getSeconds();
        const second = "" + (seconds > 9 ? "" : "0") + seconds;

        if (timeFormat === 1) return "" + hour + ":" + minute + ":" + second;
        else if (timeFormat === 2) return "" + hour + ":" + minute;
        else return "" + minute + ":" + second;
      },
    }
  }
</script>

<style scoped>
</style>
