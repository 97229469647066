import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const mnemoStorage = localStorage.getItem("mnemoState");
const graphicStorage = localStorage.getItem("graphicState");

export default new Vuex.Store({
  state: {
    mnemoState: mnemoStorage ? JSON.parse(mnemoStorage) : null,
    graphicState: graphicStorage ? JSON.parse(graphicStorage) : null,
    mnemoId: 0,
    status: false
  },
  getters: {
    mnemo: state => {
      return state.mnemoState;
    },
  },
  mutations: {
    SetMnemoState: (state, payload) => {
      state.mnemoState = payload;
      localStorage.setItem("mnemoState", JSON.stringify(payload));
    },
    SetGraphicState: (state, payload) => {
      state.graphicState = payload.message;
      state.mnemoId = parseInt(payload.mnemoId);
      localStorage.setItem("graphicState", JSON.stringify(payload.message));
    },
    MnemoConfigurationUpdated: (state, payload) => {
      state.mnemoId = payload;
    },
    ConnectionLoss: (state, payload) => {
      state.status = payload;
    },
    ConnectionOk: (state, payload) => {
      state.status = payload;
    },
  },
  actions: {
    UpdateMnemoState: async (context, payload) => {
      localStorage.removeItem("mnemoState");
      context.commit('SetMnemoState', payload);
    },
    UpdateGraphicState: async (context, payload) => {
        localStorage.removeItem("graphicState");
        context.commit('SetGraphicState', payload);
    },
    MnemoConfigurationUpdated: async (context, payload) => {
      context.commit('MnemoConfigurationUpdated', payload);
    },
    ConnectionLoss: async (context, payload) => {
      context.commit('ConnectionLoss', payload);
    },
    ConnectionOk: async (context, payload) => {
      context.commit('ConnectionOk', payload);
    },
  },
  modules: {
  }
})
