<template>
  <div :id="`selectColorModal${windowId}`" class="select-color-modal" @focusout="close">
    <b-container fluid class="select-color-modal-container">
      <b-row v-for="row in palette.slice(0, 8)" :key="row.id">
        <b-col md="12" class="v-middle">
          <button v-for="style in row.styles" :key="style.id"
                  class="round-button"
                  v-bind:style="{backgroundColor: style.backgroundColor, color: style.color}"
                  @click="select(style)">
            <span v-if="style.selected">&#10003</span>
          </button>
        </b-col>
      </b-row>
      <label style="font:normal 12px verdana" id="label-standard">СТАНДАРТНАЯ </label><label style="font:normal 14px verdana">&#10000;</label>
      <b-row v-for="row in palette.slice(8)" :key="row.id">
        <b-col md="12" class="v-middle">
          <button v-for="style in row.styles" :key="style.id"
                  class="round-button"
                  v-bind:style="{backgroundColor: style.backgroundColor, color: style.color}"
                  @click="select(style)">
            <span v-if="style.selected">&#10003</span>
          </button>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
  import PaletteColors from '@/tools/palette.colors'

  export default {

    name: "SelectColorModal",
    props: ["value", "windowId"], // style id
    mounted() {
      this.palette = PaletteColors.getPalette();
      //console.log(this.windowId);
      this.modal = document.getElementById('selectColorModal' + this.windowId);
      //console.log(this.modal);
    },
    data() {
      return {
        modal: null,
        palette: [],
      }
    },
    methods: {
      close() {
        this.modal.style.display = 'none';
      },
      select(style) {
        this.$emit("select", style);
        this.close();
      },
      selected(selectedstyle) {
        for (let i = 0; i < this.palette.length; i++) {
          const row = this.palette[i];
          for (let j = 0; j < row.styles.length; j++) {
            const style = row.styles[j];
            style.selected = parseInt(selectedstyle) === parseInt(style.id);
          }
        };
      },
      isOpen() {
        return this.modal.style.display === 'block';
      },
      open(event) {
        if (this.modal && this.modal.style) {
          this.modal.style.display = 'block';
          this.modal.style.position = 'fixed';
          if (event && event.target && event.target.parentNode && event.target.parentNode.parentNode) {
            this.modal.style.left = (event.target.parentNode.parentNode.getBoundingClientRect().left + 5) + 'px';
            this.modal.style.top = (event.target.parentNode.parentNode.getBoundingClientRect().top + 45) + 'px';
          }
        }
      }
    },
    watch: {
      value(newVal, oldVal) {
        this.selected(newVal);
      },
    },
  };
</script>
<style scoped>
  .select-color-modal {
    position: fixed;
    padding: 5px;
    background-color: white;
    border: 1px solid lightgrey;
    display: none;
  }
  .select-color-modal-container {
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
  }
  .round-button {
    margin-right: 2px;
    margin-top: 2px;
    border-radius: 100%;
    min-width: 20px;
    height: 20px;
    border-style: none;
    color: white;
    text-align: center;
    padding: 0;
    line-height: 20px;
  }
    .round-button:hover {
      opacity: 0.7;
    }
  .v-middle {
    display: flex;
  }
</style>
