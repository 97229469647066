<template>
  <b-modal id="templateSelectModal"
           :key="templateSelectModalRefresh"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           size="lg"
           title="Выбор шаблона мнемосхемы"
           cancel-title="Отмена"
           modal-class="inmodal"
           header-class="p-2"
           @ok="selectTemplate"
           @shown="modalShown">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">Выбор шаблона мнемосхемы</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="primary"
                :disabled="isLoading"
                @click="ok()">
        <b-spinner small v-show="isLoading" class="mr-1"></b-spinner>
        <span>Выбрать шаблон</span>
      </b-button>

      <b-button variant="primary"
                @click="cancel()">Отмена</b-button>
    </template>

    <b-container fluid>

      <b-table :items="templates" :fields="fieldsNames" class="table-striped">

        <template #cell(check)="data">
          <b-form-radio name="templatesArea"
                        @change="onSelectedTemplate(data.item)" />
        </template>

        <template #cell(name)="data">
          {{ data.item.name }}
        </template>
        <template #cell(description)="data">
          {{ data.item.description }}
        </template>

        <template #cell(buttonview)="data">
          <b-link variant="primary"
                  v-on:click="openPreviewTemplateDialog(data.item)"
                  title="Просмотреть">
            Просмотреть
          </b-link>
        </template>
        <template #cell(buttondownload)="data">
          <b-link variant="primary"
                  v-on:click="download(data.item)"
                  title="Скачать">
            Скачать
          </b-link>
        </template>
      </b-table>

    </b-container>

    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>

    <!--Окно предварительного просмотра шаблона-->
    <TemplatePreviewModal title="Предварительный просмотр шаблона"
                          v-model="previewFile"
                          v-on:cancel="closePreviewMnemoDialog" />

  </b-modal>
</template>

<script>

  import MnemoService from '@/services/mnemo.service.js'
  import TemplatePreviewModal from '@/views/mnemo/TemplatePreviewModal'

  export default {

    name: "TemplateSelectModal",

    props: ["value"],

    components: {
      TemplatePreviewModal
    },

    data() {
      return {
        isLoading: false,
        fieldsNames: [
          { key: 'check', label: '' },
          { key: 'name', label: '' },
          { key: 'description', label: '' },
          { key: 'buttonview', label: '' },
          { key: 'buttondownload', label: '' },
        ],
        templateSelectModalRefresh: false,
        templates: [],
        selectedTemplate: null,
        previewFile: null,
      };
    },

    methods: {

      updated: function () {
        this.$nextTick(function () {
          //console.log(this.templates);
        });
      },

      modalShown(e) {
        this.isLoading = true;
        MnemoService.getMnemoTemplates()      // только имена файлов
          .then(response => {
            this.templates = response.data;
            this.isLoading = false;
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
          })
      },

      selectTemplate() {
        if (this.selectedTemplate) {
          if (this.selectedTemplate.file) {
            this.$emit("select", this.selectedTemplate);
          } else {
            this.loadTemplate(this.selectedTemplate.id, 1);
          }
        }
      },

      onSelectedTemplate(template) {
        //console.log(template);
        this.selectedTemplate = template;
      },

      openPreviewTemplateDialog(template) {
        //console.log(template.id);
        if (template) {
          if (template.file) {
            this.previewFile = template.file;
            this.$bvModal.show("templatePreviewModal");
          } else {
            this.loadTemplate(template.id, 2);
          }
        }
      },

      download(template) {
        //console.log(template);
        if (template) {
          if (template.file) {
            this.downloadFile(template);
          } else {
            this.loadTemplate(template.id, 3);
          }
        }
      },

      downloadFile(template) {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([template.file]))
        link.download = template.name + '.svg';
        document.body.appendChild(link);
        link.click();
      },

      loadTemplate(templateId, command) {
        //console.log('start loading template');
        this.isLoading = true;
        MnemoService.getMnemoTemplateById(templateId)
          .then(response => {
            const loadedTemplate = response.data;
            const template = this.templates.find(a => a.id === loadedTemplate.id);
            //console.log('end loading template');
            this.isLoading = false;
            if (template) {
              template.file = loadedTemplate.file;
              switch (command) {
                case 1:
                  this.$emit("select", template);
                  break;
                case 2:
                  this.previewFile = loadedTemplate.file;
                  this.$bvModal.show("templatePreviewModal");
                  break;
                case 3:
                  this.downloadFile(template);
                  break;
                default:
                  return;
              }
            }
          })
          .catch(error => {
            console.log(error);
            this.isLoading = false;
          })
      },

      closePreviewMnemoDialog() {
      }

    },
  };
</script>

<style scoped>

  .template-link {
    text-decoration: underline;
    color: steelblue;
  }
</style>
