<template>
  <b-modal id="addEditWindowModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           :title="modalTitle"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @ok="modalOk"
           @shown="modalShown">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">{{modalTitle}}</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="primary" :disabled="isBusy" @click="ok()">
        <b-spinner small v-show="isBusy" class="mr-1"></b-spinner>
        <span>Сохранить</span>
      </b-button>
      <b-button variant="secondary" @click="cancel()">Отмена</b-button>
    </template>
    <form ref="form" @submit.stop.prevent="modalHandleSubmit" v-if="value">
      <b-form-row>
        <b-col md="12">
          <b-form-group label="Наименование окна" label-for="title">
            <b-form-input id="title"
                          ref="focusTitle"
                          v-model="value.title"
                          :class="{ error: validationErrors.Title }"
                          @change="validationErrors.Title = null"
                          trim></b-form-input>
            <ValidationField v-bind:errors="validationErrors.Title" />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row class="mt-2">
        <b-col md="12">
          <b-form-checkbox v-model="value.isShowTitle">
            Показывать заголовок
          </b-form-checkbox>
        </b-col>
      </b-form-row>
    </form>
  </b-modal>
</template>

<script>
import MnemoService from "@/services/mnemo.service";
import ValidationField from "@/components/ValidationField.vue";

export default {
  name: "AddEditWindowModalComponent",
  components: {
    ValidationField,
  },
  data() {
    return {
      isBusy: false,
      validationErrors: [],      
    };
  },
  props: ["value"],
  methods: {
    modalShown(e) {
      this.$refs.focusTitle.focus();
      this.isBusy = false;
      this.validationErrors = [];
    },
    modalOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isBusy = true;
      this.modalHandleSubmit();
    },
    modalHandleSubmit() {
      MnemoService.saveWindow(this.value)
        .then((response) => {
          if (!this.value.id) {
            this.$emit("addWindow", response.data);
          } else {
            this.$emit("editWindow", this.value);
          }
          this.$nextTick(() => {
            this.$refs.modal.hide();
          });
        })
        .catch((error) => {})
        .finally(() => (this.isBusy = false));
    },
  },
  computed: {
    modalTitle: function () {
      return this.value && this.value.id
        ? "Редактирование окна"
        : "Добавление окна";
    },
  },
};
</script>