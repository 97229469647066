<template>
  <div>
    <b-container fluid style="width: 100%; position: relative">
      <b-row>
        <b-col md="12">
          <div style="text-align:center;line-height:40px">{{mnemoInstance.name}}</div>
        </b-col>
      </b-row>
      <b-button v-if="connectionLoss>1"
                :title="connectionLossTitle"
                @click="info"
                variant="primary"
                class="alarm-buttons">
        <i class="fa fa-info"></i>
      </b-button>
    </b-container>
    <div id="content-provider" style="padding: 0px; overflow-y:hidden">
      <div class="col-xs-12">
        <div v-if="!isEmpty"
             v-html="mnemoInstance.mnemoFile"
             v-bind:class="['mnemo-runtime'+windowId]"
             v-on:click="documentClick($event)">
        </div>
        <div v-else class="alert alert-warning" role="alert">
          Нет данных
        </div>
      </div>
    </div>

    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>

    <!--Диалог задания значений параметров тегов и передача значений сервису -->
    <PrimitiveBaseModal :windowId="windowId"
                        v-model="primitiveBASEDIALOGdata"
                        @closePrimitiveBaseDialog="primitiveBASEDIALOGdata=null" />

  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'

  import PrimitiveBaseModal from '@/views/mnemo/PrimitiveBaseModal'

  export default {

    name: 'MnemonicsViewer',

    props: ['value', 'windowId'],

    components: {
      PrimitiveBaseModal
    },

    data() {
      return {

        justifyAlign: 0,
        connectionOk: false,
        connectionLoss: 0,
        connectionLossTime: null,
        connectionOkTime: null,
        connectionLossTitle: null,

        isLoading: false,

        mnemo: null,

        mnemoInstance: {
          groupId: null,
          templateId: null,
          id: null,
          name: null,
          description: null,
          status: null,
          mnemoFile: null,
        },

        isEmpty: false,

        mnemoGroups: [],            // parsing results
        mnemoDevices: [],
        primitives: [],

        bindedPrimitives: [],       // binding results
        bindedDevices: [],          // binding results

        primitiveLEDWrappers: [],
        primitiveBASEDIALOGWrappers: [],
        primitiveBASEDIALOGdata: null,

        svgDocument: null,
        isUpdateBindingResults: false,
        isResize: false,
        mnemoHeight: 0,
        mnemoWidth: 0,
      }
    },

    beforeDestroy() {
      this.unsubscribe();
    },

    created() {
      let self = this;

      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        if (mutation.type === 'SetMnemoState' && state.mnemoState !== null) {
          this.mnemoValuesFromState(state.mnemoState);
        }
        else if (mutation.type === 'ConnectionLoss') {
          this.connectionLoss++;
          //console.log(this.connectionLoss);
          if (this.connectionLoss > 1) {
            if (this.connectionLossTime === null) {
              this.connectionLossTime = new Date().toLocaleString();
              this.connectionLossTitle = `Соединение потеряно в ${this.connectionLossTime ? this.connectionLossTime : ''}`;
            }
            //console.log(this.connectionLossTime);
          }
        }
        else if (mutation.type === 'ConnectionOk') {
          this.connectionLoss = 0;
          this.connectionLossTitle = null;
          this.connectionOkTime = new Date().toLocaleString();
          if (this.mnemoInstance && this.mnemoInstance.id) {
            setTimeout(function () {
              Vue.prototype.initConnection(self.mnemoInstance.id);
              //console.log('Init connection started');
            }, 500);
          };
          //console.log(this.connectionOkTime);
        }
      });

      axios.interceptors.request.use(
        function (config) {
          const token = self.getCookie('cfg-token');
          if (token) {
            config.headers.Authorization = "Bearer " + token;
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
    },

    mounted() {

      //console.log('Mnemonic viewer DOM mounted, windowId: ' + this.windowId);
      this.$emit("mounted");
    },

    updated: function () {
      const self = this;

      this.$nextTick(function () {

        //console.log('updated');
        if (this.isUpdateBindingResults) {
          this.isUpdateBindingResults = false;

          if (this.mnemoInstance.mnemoFile) {

            this.cleanup();
            this.lookingForGroups();
            this.lookingForDevices();

            setTimeout(function () {
              Vue.prototype.initConnection(self.mnemoInstance.id);
              //console.log('Init connection started');
            }, 1);
          }
        }
        if (this.isResize) {
          this.isResize = false;
          if (this.mnemoWidth !== 0 && this.mnemoHeight !== 0) {
            if (this.svgDocument) {
              this.svgDocument.setAttribute('height', this.mnemoHeight-40);
              this.svgDocument.setAttribute('width', this.mnemoWidth);
              this.mnemoWidth = 0;
              this.mnemoHeight = 0;
            }
          }
        }
      })
    },

    methods: {

      resize(width, height) {
        //console.log('resize windowId ' + this.windowId + ' ' + width + ' ' + height);
        this.isResize = true;
        this.mnemoWidth = width;
        this.mnemoHeight = height;
        if (this.svgDocument) {
          //console.log(this.svgDocument.style);
          this.svgDocument.setAttribute('height', height-40);
          this.svgDocument.setAttribute('width', width);
          //this.svgDocument.setAttribute('preserveAspectRatio', 'none');
        }
      },

      updateBindingResults(result) {

        this.isEmpty = true;

        if (!result) {
          this.isEmpty = false;
          this.isLoading = false;
          return;
        }

        this.mnemoInstance = result.mnemoInstance;

        this.mnemoGroups = JSON.parse(JSON.stringify(result.mnemoGroups));
        this.mnemoDevices = JSON.parse(JSON.stringify(result.mnemoDevices));
        this.primitives = JSON.parse(JSON.stringify(result.primitives));
        this.bindedDevices = JSON.parse(JSON.stringify(result.bindedDevices));
        this.bindedPrimitives = JSON.parse(JSON.stringify(result.bindedPrimitives));

        //console.log('end binding');
        //console.log(result);
        //console.log(this.mnemoInstance);

        this.isUpdateBindingResults = true;

        this.isEmpty = false;
        this.isLoading = false;
      },

      info() {

        alert(this.connectionLossTitle);
      },

      mnemoValuesFromState(mnemoStorage) {

        this.svgDocument = document.querySelector('[class="mnemo-runtime' + this.windowId + '"] > svg');

        if (mnemoStorage !== null) {
          const tagValues = JSON.parse(mnemoStorage);
          //console.log('');
          const date = new Date();
          //console.log('Текущее время ' + date.toLocaleTimeString() + '.' + date.getMilliseconds());
          //console.log(tagValues);
          if (tagValues !== null) {
            for (let i = 0; i < tagValues.length; i++) {
              const tagValue = tagValues[i];
              //console.log('IdTag:' + tagValue.IdTag + "; TimeStamp:" + tagValue.TimeStamp + "; Value:" + parseFloat(tagValue.Value).toFixed(2));

              const id = parseInt(tagValue.IdTag);
              const v = parseFloat(tagValue.Value);

              //console.log(tagValue);
              this.lookingForTag(id, v);
              this.lookingForPrimitive(id, v.toFixed(2));
            }
          }
        }
      },

      cleanup() {

        this.svgDocument = document.querySelector('[class="mnemo-runtime' + this.windowId + '"] > svg');
        if (this.svgDocument) {

          const allMnemoDevices = this.svgDocument.querySelectorAll('[id*="Device"]');
          allMnemoDevices.forEach(function (device, i, arr) {
            device.innerHTML = '';
            if (device.r) device.style.fill = '#808080';
          });

          const allPrimitives = this.svgDocument.querySelectorAll('[id*="Primitive."]');
          for (let i = 0; i < allPrimitives.length; i++) {
            const mnemoPrimitive = allPrimitives[i];
            mnemoPrimitive.style.fill = '#808080';
          }
        }

      },

      lookingForGroups() {

        for (let i = 0; i < this.mnemoGroups.length; i++) {
          const mnemoGroup = this.mnemoGroups[i];
          this.setMnemoText(mnemoGroup.mnemoGroupCode + '.Name', mnemoGroup.mnemoGroupName);
        }
      },

      lookingForDevices() {

        for (let i = 0; i < this.bindedDevices.length; i++) {
          const bindedDevice = this.bindedDevices[i];
          for (let j = 0; j < bindedDevice.mnemoDeviceProps.length; j++) {
            const prop = bindedDevice.mnemoDeviceProps[j];
            const mnemoPropName = this.getMnemoPropName(bindedDevice, prop.objectLogicColumnName);
            this.setMnemoText(mnemoPropName, prop.objectLogicPropertyValue);
          }
        }
      },

      lookingForTag(objectLogicTagId, value) {

        for (let i = 0; i < this.bindedDevices.length; i++) {
          const bindedDevice = this.bindedDevices[i];
          for (let j = 0; j < bindedDevice.mnemoTags.length; j++) {
            const tag = bindedDevice.mnemoTags[j];
            if (tag.objectLogicTagId === objectLogicTagId) {
              const mnemoTagName = this.getMnemoTagName(bindedDevice, tag);
              //console.log(tag);
              this.setMnemoText(mnemoTagName, value.toFixed(tag.decimalSymbols) + (tag.unitsMeasureCode?' '+tag.unitsMeasureCode:'') );
              //console.log(bindedDevice);
              //console.log(mnemoTagName + ' ' + value);
              return mnemoTagName;
            }
          }
        }
        return null;
      },

      lookingForPrimitive(objectLogicTagId, value) {

        for (let i = 0; i < this.bindedPrimitives.length; i++) {
          const bindedPrimitive = this.bindedPrimitives[i];
          for (let j = 0; j < bindedPrimitive.mnemoTags.length; j++) {
            const bindedPrimitiveTag = bindedPrimitive.mnemoTags[j];
            if (bindedPrimitiveTag.objectLogicTagId === objectLogicTagId) {
              if (bindedPrimitive.type === 'LED') {
                //console.log(bindedPrimitive.name);
                const tagName = this.getMnemoPrimitiveTagName(bindedPrimitiveTag);
                //console.log(tagName);
                let primitiveWrapper = this.primitiveLEDWrappers.find(a => a.name === bindedPrimitive.name);
                if (!primitiveWrapper) {
                  this.scriptId = 'mnemo.primitive.script.LED';
                  let script = document.getElementById(this.scriptId);
                  if (script === null && bindedPrimitive.jsClass) {
                    script = document.createElement("script");
                    script.id = this.scriptId;
                    document.head.appendChild(script);
                  }
                  script.textContent = bindedPrimitive.jsClass;
                  //console.log(script);

                  primitiveWrapper = new mnemoPrimitiveScriptLED(bindedPrimitive, this.windowId);
                  this.primitiveLEDWrappers.push(primitiveWrapper);
                } else {
                  primitiveWrapper.Init(bindedPrimitive, this.windowId);
                };
                primitiveWrapper.SetValue(objectLogicTagId, value)
                //console.log(primitiveWrapper);
                //console.log("objectLogicTagId: " + objectLogicTagId + ", value: " + value);
              }
              if (bindedPrimitive.type === 'BASEDIALOG') {
                //console.log(bindedPrimitive.name);
                const tagName = this.getMnemoPrimitiveTagName(bindedPrimitiveTag);
                //console.log(tagName);
                let primitiveWrapper = this.primitiveBASEDIALOGWrappers.find(a => a.name === bindedPrimitive.name);
                if (!primitiveWrapper) {
                  this.scriptId = 'mnemo.primitive.script.BASEDIALOG';
                  let script = document.getElementById(this.scriptId);
                  //console.log(script);
                  if (script === null && bindedPrimitive.jsClass) {
                    script = document.createElement("script");
                    script.id = this.scriptId;
                    document.head.appendChild(script);
                  }
                  script.textContent = bindedPrimitive.jsClass;

                  primitiveWrapper = new mnemoPrimitiveScriptBASEDIALOG(bindedPrimitive);
                  this.primitiveBASEDIALOGWrappers.push(primitiveWrapper);
                } else {
                  primitiveWrapper.Init(bindedPrimitive);
                };
                primitiveWrapper.SetValue(objectLogicTagId, value)
                const svgElement = this.svgDocument.getElementById(bindedPrimitive.name);
                svgElement.style.fill = 'black';

                const isOn = parseInt(value) === parseInt('1');

                svgElement.innerHTML = isOn ? 'Включено' : 'Отключено';

                //console.log(bindedPrimitive.name + '_rect');
                const svgElementRect = this.svgDocument.getElementById(bindedPrimitive.name + '_rect');
                if (svgElementRect) {
                  //console.log(svgElementRect);
                  svgElementRect.style.fill = isOn ? '#80f080' : '#808080';
                }
                //console.log(primitiveWrapper);
                //console.log(objectLogicTagId);
              }
            }
          }
        }
        return null;
      },

      setMnemoText(mnemoElementName, value) {

        if (mnemoElementName !== null) {
          const mnemoElement = this.svgDocument.getElementById(mnemoElementName);
          if (mnemoElement) {
            mnemoElement.innerHTML = value;
          }
        }
      },

      documentClick(event) {

        if (event.target.id.indexOf('Primitive.BASEDIALOG') !== -1) {
          const primitive = this.primitiveBASEDIALOGWrappers.find(a => a.name === event.target.id);
          if (primitive) {
            this.primitiveBASEDIALOGdata = primitive;
            this.$bvModal.show("primitiveBaseDialog"+this.windowId);

            //console.log(primitive);
          }
        }
        if (event.target.id.indexOf('Primitive.') !== -1) {
          //console.log(event.target.id);
        }
        else if (event.target.id.indexOf('Device') !== -1) {
          //console.log(event.target.id);
        }
      },

      getMnemoTagName(device, tag) {

        return `${device.mnemoDeviceCode}.TVAR${tag.typeParametersId}.${tag.variableName}`;
      },

      getMnemoPrimitiveTagName(tag) {

        return `${tag.primitiveDeviceCode}.TVAR${tag.typeParametersId}.${tag.variableName}`;
      },

      getMnemoPropName(device, prop) {

        return `${device.mnemoDeviceCode}.${prop}`;
      },

      overMnemoTag(device, mnemoTag) {

        //<!--@mouseover="overMnemoTag(selectedMnemoDevice, mnemoTag)" @mouseleave="leaveMnemoTag(selectedMnemoDevice, mnemoTag)"-->
        //console.log(this.getMnemoTagName(device, mnemoTag));
      },

      leaveMnemoTag(device, mnemoTag) {

        //console.log('Leave ' + mnemoTag.variableName);
      },

      getCookie(name) {

        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },

    }
  }
</script>

<style scoped>

  .justifyAlignCenter {
    justify-content: space-around;
  }

  .justifyAlignLeft {
    justify-content: flex-start;
  }

  .justifyAlignRight {
    justify-content: flex-end;
  }
</style>
<style>
    svg [id^="Primitive."]:hover {
      cursor: pointer;
    }
  svg {
    width: -webkit-fill-available;
    height: auto;
  }
</style>
