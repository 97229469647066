<template>
  <b-modal id="deviceBindingModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           size="xl"
           :title="modalTitle"
           cancel-title="Отмена"
           modal-class="inmodal"
           header-class="p-2"
           v-bind:hide-footer="true"
           @shown="modalShown">
    <template v-slot:modal-header="{ ok, cancel }">


      <b-form-input style="width:250px" list="my-list-id" v-on:change="onSelectObjectDevice($event)"></b-form-input>
      <datalist id="my-list-id">
        <option v-for="objectDevice in objectDevices">{{ objectDevice.name }}</option>
      </datalist>

      <b-button variant="primary"
                :disabled="isBusy"
                @click="resetDeviceBinding()">
        <b-spinner small v-show="isBusy" class="mr-1"></b-spinner>
        <span>Сбросить привязки</span>
      </b-button>

      <b-button variant="primary"
                :disabled="isBusy"
                @click="saveDeviceBinding()">
        <b-spinner small v-show="isBusy" class="mr-1"></b-spinner>
        <span>Сохранить привязки</span>
      </b-button>

      <b-button variant="primary"
                @click="cancel()">Закрыть</b-button>
    </template>

    <b-container fluid v-if="value">

      <b-table :items="value.mnemoDeviceProps" :fields="fieldsProps" class="table-striped table-borderless">
        <template #table-caption>
          <span class="device-props-caption">{{value.objectLogicName}}</span>
        </template>

        <template #cell(mnemoprop)="data">
          {{ data.item.objectLogicColumnName }}
        </template>
        <template #cell(deviceprop)="data">
          {{ data.item.found }}
        </template>
      </b-table>

      <b-table :items="value.mnemoTags" :fields="fieldsTags" class="table-striped table-borderless">
        <template #cell(mnemotag)="data">
          {{ data.item.variableName }}
        </template>
        <template #cell(typeparameter)="data">
          {{ data.item.typeParametersName }}
        </template>
        <template #cell(devicetag)="data">
          <span v-if="data.item.objectLogicTagName">{{ data.item.objectLogicTagName }}</span>
          <b-form-select v-else class="form-control"
                         style="width:250px"
                         v-on:change="onSelectDeviceTag($event, data.item)"
                         :options="data.item.deviceTags"
                         value-field="id"
                         text-field="fullName">
          </b-form-select>
        </template>
        <template #cell(unitsmeasure)="data">
          <b-form-select class="form-control"
                         v-on:change="data.item.selectedUnitsMeasureId=data.item.unitsMeasureId"
                         style="width: 80px; text-align: center"
                         v-model="data.item.unitsMeasureId"
                         :options="unitsMeasures"
                         value-field="id"
                         text-field="code">
          </b-form-select>
        </template>
        <template #cell(decimalsymbols)="data">
          <b-form-input v-model="data.item.decimalSymbols" 
                        v-on:change="data.item.selectedDecimalSymbols=data.item.decimalSymbols"
                        type="number"
                        :step="1"
                        :min="0"
                        :max="9"
                        style="width: 64px; text-align: right" 
                        id="decimal-symbols"></b-form-input>
        </template>

        <template #cell(button)="data">
          <b-button v-if="data.item.objectLogicTagName"
                    variant="primary"
                    v-on:click="resetTagBinding(data.item)"
                    title="Сбросить привязку">
            <i class="fa fa-close"></i>
          </b-button>
        </template>
      </b-table>

    </b-container>
  </b-modal>
</template>

<script>

  import axios from 'axios'
  import MnemoService from '@/services/mnemo.service.js'

  export default {

    name: "DeviceBindingModal",

    data() {
      return {
        isBusy: false,
        devSelectionDisabled: false,
        selectedDeviceInfo: {},
        selectedDeviceTags: [],
        selectedObjectDeviceId: null,
        fieldsProps: [
          { key: 'mnemoprop', label: 'Свойства прибора на мнемосхеме' },
          { key: 'deviceprop', label: 'Свойства выбранного прибора' },
        ],
        fieldsTags: [
          { key: 'mnemotag', label: 'Теги прибора на мнемосхеме' },
          { key: 'typeparameter', label: 'Ресурс' },
          { key: 'devicetag', label: 'Теги выбранного прибора' },
          { key: 'unitsmeasure', label: 'Ед.измерения' },
          { key: 'decimalsymbols', label: 'Зн. после запятой' },
          { key: 'button', label: '' },
        ],
        objectDevicesFilterValue: null,
      };
    },

    props: ["value", "objectDevices", "unitsMeasures"],

    methods: {

      objectDevicesFilter(e) {
        const tagsAvailable = this.objectDevices.filter((item) => item === e);
        if (!tagsAvailable.length) {
          this.objectDevicesFilterValue = ''
        }
      },

      modalShown(e) {
        //console.log(this.value);
        //console.log(this.objectDevices);
        this.selectedObjectDeviceId = null;
        this.devSelectionDisabled = this.value.mnemoTags.find(a => a.objectLogicTagId !== null) !== undefined;
        if (this.value.objectLogicId !== null) {
          //this.selectedObjectDeviceId = this.value.objectLogicId;
          //console.log("DeviceId:" + this.value.objectLogicId);
          this.getDeviceInfo(this.value.objectLogicId, false);
        } else {
          this.selectedDeviceTags = [];
          this.selectedDeviceInfo = {};
        };
      },

      saveDeviceBinding() {
        this.isBusy = true;
        //console.log(this.value);

        MnemoService.updateMnemoDevice(this.value)
          .then(response => {
            //console.log(response.data);
            this.$emit("update", response.data);
            this.checkBinding();

            this.isBusy = false;
          })
          .catch(error => {
            console.log(error);
            this.isBusy = false;
          });
      },

      onSelectObjectDevice(name) {

        const objectDevice = this.objectDevices.find(a=>a.name == name)

        this.devSelectionDisabled = true;
        this.selectedObjectDeviceId = parseInt(objectDevice.id);

        //console.log("DeviceId:" + this.selectedObjectDeviceId);
        this.getDeviceInfo(this.selectedObjectDeviceId, true);
      },

      onSelectDeviceTag(id, mnemoTag) {
        const selectedDeviceTagId = parseInt(id);
        const variable = this.selectedDeviceTags.find(a => a.id === selectedDeviceTagId);
        mnemoTag.objectLogicTagId = variable.id;
        mnemoTag.objectLogicTagName = variable.name;
        this.checkBinding();
      },

      resetTagBinding(mnemoTag) {
        mnemoTag.objectLogicTagId = null;
        mnemoTag.objectLogicTagName = null;
        this.checkBinding();
      },

      resetDeviceBinding() {
        this.selectedObjectDeviceId = null;
        this.value.objectLogicId = null;
        this.value.objectLogicName = null;
        for (let i = 0; i < this.value.mnemoTags.length; i++) {
          const mnemoTag = this.value.mnemoTags[i];
          this.resetTagBinding(mnemoTag);
        }
        this.devSelectionDisabled = false;
      },

      checkBinding() {
        if (this.value !== null) {
          const isNotDevice = this.value.mnemoTags.find(a => a.objectLogicTagId === null);
          if (!isNotDevice) this.value.OK = true;
        }
      },

      getDeviceInfo(deviceId, binding) {
        this.isBusy = true;
        axios
          .all([
            MnemoService.deviceInfo(deviceId),
            MnemoService.getDeviceTagsSettings(deviceId)
          ])
          .then(
            axios.spread((deviceInfo, deviceTags) => {
              //console.log(deviceTags);
              this.selectedDeviceTags = deviceTags.data.map(function (a) {
                return {
                  id: a.id,
                  name: a.variableName,
                  typeParametersId: a.resourceId,
                  fullName: a.variableName + ' (' + a.timePeriodName + ')'
                }
              });
              this.filteringDeviceTags();
              this.selectedDeviceInfo = deviceInfo.data;

              if (binding) this.binding(deviceTags.data, deviceInfo.data);

              this.isBusy = false;
            }))
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      },

      filteringDeviceTags() {
        for (let i = 0; i < this.value.mnemoTags.length; i++) {
          const mnemoTag = this.value.mnemoTags[i];
          const filtered = this.selectedDeviceTags.filter(a => a.typeParametersId === mnemoTag.typeParametersId);
          mnemoTag.deviceTags = filtered.sort((a, b) => a.name.localeCompare(b.name));
        }
        this.value.mnemoTags = [...this.value.mnemoTags];
      },

      binding(deviceTags, deviceInfo) {
        this.value.objectLogicId = this.selectedObjectDeviceId;
        const objectDevice = this.objectDevices.find(a => a.id === this.selectedObjectDeviceId);

        this.value.objectLogicName = objectDevice.name;
        this.lookingForObjectLogicColumnNames(deviceInfo);

        //console.log(this.value);
        //console.log(deviceTags);

        this.value.mnemoTags.forEach(function (mnemoTag, i, arr) {
          //console.log(mnemoTag);
          const deviceTag = deviceTags.find(a => a.variableName === mnemoTag.variableName);
          if (deviceTag) {
            mnemoTag.objectLogicTagName = deviceTag.variableName;
            mnemoTag.objectLogicTagId = deviceTag.id;
          };
        });

        this.checkBinding();
        //console.log('Binding complete');
      },

      lookingForObjectLogicColumnNames(deviceInfo) {
        const validObjectLogicProperties = Object.keys(deviceInfo);
        this.value.mnemoDeviceProps.forEach(function (property, i, arr) {
          const propertyName = property.objectLogicColumnName[0].toLowerCase() + property.objectLogicColumnName.slice(1)
          if (validObjectLogicProperties.find(a => a === propertyName)) {
            //console.log(propertyName);
            //console.log(deviceInfo[propertyName]);
            property.found = property.objectLogicColumnName;
            property.objectLogicPropertyValue = deviceInfo[propertyName];
          }
        });
      },
    },

    computed: {
      modalTitle: function () {
        return this.value && this.value.id
          ? `Свойства прибора ${this.value.mnemoDeviceCode}. ${this.value.objectLogicName}` : "";
      },
    },
  };
</script>
<style>
  .device-props-caption{
    font-weight: 700;
    color: black;
  }
</style>

