class DateConverter {

    getEndDate() {
        const utcNow = new Date();
        const offset = utcNow.getTimezoneOffset() / 60;
        utcNow.setHours(utcNow.getHours() + offset);
        return utcNow;
    }

    getStartDate(utcNow, settings) {
        const utcNowMinusPeriod = new Date(utcNow);
        if (!settings) {
            utcNowMinusPeriod.setDate(utcNowMinusPeriod.getDate() - 1);
        }
        else if (settings.timePeriod === 10) { // �������
            const minutes = parseInt(settings.scaleWidth) * 30;
            utcNowMinusPeriod.setMinutes(utcNowMinusPeriod.getMinutes() - minutes);
        }
        else if (settings.timePeriod === 6) { // ���
            const hours = parseInt(settings.scaleWidth);
            utcNowMinusPeriod.setHours(utcNowMinusPeriod.getHours() - hours);
        }
        else if (settings.timePeriod === 7) { // �����
            const days = parseInt(settings.scaleWidth);
            utcNowMinusPeriod.setDate(utcNowMinusPeriod.getDate() - days);
        }
        else if (settings.timePeriod === 8) { // �����
            const days = parseInt(settings.scaleWidth) * 30;
            utcNowMinusPeriod.setDate(utcNowMinusPeriod.getDate() - days);
        }
        return utcNowMinusPeriod;
    }
}

export default new DateConverter();

