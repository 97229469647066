<template>
  <div ref="windowContent">
    <div v-if="mnemoInstanceId && mnemoInstanceType===1">

      <MnemonicsContent ref="mnemonicsContent"
                        mode="view"
                        v-model="mnemoInstanceId"
                        :windowId="value"
                        v-on:onMounted="onMounted" />
    </div>
    <div v-else-if="mnemoInstanceType===2">

      <GraphicsContent ref="graphicsContent" :mode="graphicsMode"
                       v-model="mnemoInstanceId"
                       :windowId="value"
                       @save="save"
                       @cancel="cancel"
                       v-on:onMounted="onMounted" />

    </div>
    <div v-else-if="!isReadOnly" style="text-align:center" class="mt-3">
      <b-button variant="primary"
                class="mb-2"
                @click="assignMnemo()">
        <i class="fa fa-plus"></i>
        Мнемосхема
      </b-button>
      <br />
      <b-button variant="primary"
                class="add-widget"
                @click="setupChart()">
        <i class="fa fa-plus"></i>
        График
      </b-button>
    </div>
    <b-overlay :show="isLoading" style="margin-top: 10px; height: 100%;"></b-overlay>
  </div>
</template>

<script>
  import MnemoService from "@/services/mnemo.service";
  import MnemonicsContent from "@/views/mnemo/MnemonicsContent";
  import GraphicsContent from "@/views/graphic/GraphicsContent";
  import Axios from "axios";
  export default {
    name: "WindowContent",
    components: { MnemonicsContent, GraphicsContent },
    props: ["value", "groupId", "isReadOnly"],

    data() {
      return {
        isLoading: false,
        mnemoInstanceId: undefined,
        mnemoInstanceType: null,
        graphicsMode: null,
        chartHeight: null,
      };
    },
    methods: {
      save(mnemoInstanceId) {
        const self = this;
        //console.log('save ' + this.value + ' ' + mnemoInstanceId + ' ' + this.mnemoInstanceType);
        this.mnemoInstanceType = 2;
        this.graphicsMode = 'view';
        if (!this.mnemoInstanceId) {
          this.mnemoInstanceId = mnemoInstanceId;
          setTimeout(function () {
            self.resize();
          }, 100);
        }
      },
      cancel(mnemoInstanceId) {
        const self = this;
        //console.log('cancel ' + this.value + ' ' + mnemoInstanceId + ' ' + this.mnemoInstanceType);
        this.graphicsMode = 'view';
        if (!mnemoInstanceId) {
          this.mnemoInstanceType = null;
        }
        else {
          this.mnemoInstanceType = 2;
          if (!this.mnemoInstanceId) {
            this.mnemoInstanceId = mnemoInstanceId;
            //setTimeout(function () {
            //  self.resize();
            //}, 100);
          }
        }
      },
      onResizeEnd() {
        this.resize();
      },
      onMounted() {
        this.resize();
      },
      resize() {
        //console.log('resize ' + this.mnemoInstanceType + ' ' + this.graphicsMode);
        if (this.mnemoInstanceType === 2 && this.graphicsMode == 'view') {
          //console.log(this.$refs.windowContent.parentNode.clientWidth + ' ' + this.$refs.windowContent.parentNode.clientHeight);
          this.$refs.graphicsContent.resize(
            this.$refs.windowContent.parentNode.clientWidth,
            this.$refs.windowContent.parentNode.clientHeight);
        }
        else if (this.mnemoInstanceType === 1) {
          this.$refs.mnemonicsContent.resize(
            this.$refs.windowContent.parentNode.clientWidth,
            this.$refs.windowContent.parentNode.clientHeight);
        }
      },
      assignMnemo() {
        this.$emit("assignMnemoToWindow", this.value);
      },
      setupChart() {
        //alert("Настройка графика");
        this.graphicsMode = 'edit';
        this.mnemoInstanceType = 2;
      },
      getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },
      updateMnemoInstance(value) {
        this.mnemoInstanceType = 1;
        this.mnemoInstanceId = value;
        //console.log('assign mnemo ' + this.mnemoInstanceType + ' ' + this.mnemoInstanceId);
      },
    },
    mounted() {
      //console.log(this.value);
      //console.log('mounted');
      this.$on("assignedMnemoToWindow", function (data) {
        console.log(data);
      });

      this.isLoading = true;
      Axios.all([MnemoService.getMnemoInstanceId(this.value, this.groupId)])
        .then(
          Axios.spread((mnemoInstance) => {
            this.mnemoInstanceId = mnemoInstance.data.id;
            this.mnemoInstanceType = mnemoInstance.data.mnemoType;
            if (this.mnemoInstanceType === 2) {
              if (this.mnemoInstanceId) {
                this.graphicsMode = 'view';
              } else {
                this.graphicsMode = 'edit';
              }
            }
            else {
              this.graphicsMode = null;
            }
          })
        )
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.isLoading = false));
    },
  };
</script>

<style scoped>
</style>