<template>
  <b-modal :id="`editor${windowId}`"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           size="lg"
           title="Настройки графика"
           cancel-title="Отмена"
           modal-class="inmodal"
           @ok="save"
           @cancel="modalCancel"
           @close="modalCancel">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">Настройки графика</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="modalCancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ ok }">
      <label class="graphic-control">Периоды у выбранных тегов: {{tagsTimePeriods}}</label>
      <b-button variant="primary" style="margin-left: auto;"
                @click="save()">
        <i class="fa fa-save"></i>
        Сохранить
      </b-button>
    </template>

    <b-container fluid style="height:460px">
      <b-tabs class="tab-content">

        <b-tab class="graphic-tags" title="Выбор тегов">

          <b-row style="margin-top:10px">
            <b-col md="12">
              <b-button variant="primary"
                        v-on:click="selectTags()"
                        title="Выбрать теги">
                <i class="fa fa-plus"></i>
              </b-button>
              <Resources style="margin-left:5px" @select="onResourceClick" />
            </b-col>
          </b-row>

          <div v-if="selectedTags.length>0" style="margin-top:10px">
            <b-table sticky-header striped borderless :items="selectedTags" :fields="tagBindingFieldsNames">

              <template #cell(color)="data">
                <button class="dropdown-button"
                        v-on:click="openColorSelectionDialog($event, data.item)"
                        title="Выбрать цвет линии">
                  <div class="round-button" v-bind:style="{backgroundColor: data.item.style.backgroundColor}"></div>
                  <i style="font-size: 12px;margin-top:5px" class="fa fa-chevron-down"></i>
                </button>
              </template>

              <template #cell(variable)="data">
                {{data.item.name}}({{data.item.timePeriodName}})
              </template>
              <template #cell(device)="data">
                {{data.item.objectLogicName}}
              </template>

              <template #cell(button)="data">
                <b-button variant="primary"
                          v-on:click="removeSelectedTag(data.item)"
                          title="Удалить тег">
                  <i class="fa fa-close"></i>
                </b-button>
              </template>
            </b-table>
          </div>

        </b-tab>

        <b-tab class="graphic-settings" title="Настройки" @click="graphicTagsTabClicked()">

          <b-container fluid style="margin-top:20px; line-height:40px; height: 400px; overflow-y:auto">

            <b-row>
              <b-col md="12" style="display:flex;">
                <label for="graphic-values-scale" class="graphic-settings-label graphic-settings-label-group">График</label>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <label for="graphic-name" class="graphic-settings-label">Название</label>
              </b-col>
              <b-col md="10">
                <b-form-input id="graphic-name"
                              class="graphic-control form-control"
                              placeholder="Поле не может быть пустым"
                              type="text"
                              v-model="mnemoInstance.name"
                              v-bind:class="{ error: !mnemoInstance.name }">
                </b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <label for="graphic-type" class="graphic-settings-label">Тип</label>
              </b-col>
              <b-col md="4">
                <b-form-select id="graphic-type"
                               class="graphic-control form-control"
                               v-model="settings.chartType"
                               :options="chartTypes"
                               value-field="id"
                               text-field="name">
                </b-form-select>
              </b-col>

              <b-col md="6" v-if="settings.chartType === 1">
                <b-form-checkbox id="graphic-line-curve-smooth"
                                 class="graphic-control-checkbox"
                                 v-model="settings.lineCurveSmooth"
                                 name="graphic-line-curve-smooth"
                                 value="accepted"
                                 unchecked-value="not_accepted">
                  <label for="graphic-line-curve-smooth" class="graphic-settings-checkbox-label">Сглаживание</label>
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12" style="display:flex;">
                <label for="graphic-values-scale" class="graphic-settings-label graphic-settings-label-group">Шкала значений</label>
                <b-form-checkbox id="graphic-values-scale"
                                 class="graphic-control-checkbox"
                                 v-model="settings.graphicValuesScale"
                                 name="graphic-values-scale"
                                 value="accepted"
                                 unchecked-value="not_accepted">
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <label for="graphic-value-scale" class="graphic-settings-label">Тип</label>
              </b-col>
              <b-col md="4">
                <b-form-select id="graphic-value-scale"
                               class="graphic-control form-control"
                               v-model="settings.scaleValue"
                               :options="scaleValues"
                               value-field="id"
                               text-field="name">
                </b-form-select>
              </b-col>
              <b-col md="2">
                <label for="graphic-legend" class="graphic-settings-label">Легенда</label>
              </b-col>
              <b-col md="4">
                <b-form-select id="graphic-legend"
                               class="graphic-control form-control"
                               v-model="settings.legend"
                               :options="legends"
                               name="graphic-legend"
                               value-field="value"
                               text-field="name">
                </b-form-select>
              </b-col>
            </b-row>

            <div v-if="settings.scaleValue==2">
              <b-row>
                <b-col md="2">
                  <label for="graphic-value-scale-min" class="graphic-settings-label">Минимум</label>
                </b-col>
                <b-col md="4">
                  <b-form-input id="graphic-value-scale-min"
                                class="graphic-control form-control"
                                type="number"
                                v-model="settings.scaleValueMin">
                  </b-form-input>
                </b-col>
                <b-col md="2">
                  <label for="graphic-value-scale-max" class="graphic-settings-label">Максимум</label>
                </b-col>
                <b-col md="4">
                  <b-form-input id="graphic-value-scale-max"
                                class="graphic-control form-control"
                                type="number"
                                v-model.number="settings.scaleValueMax">
                  </b-form-input>
                </b-col>
              </b-row>
            </div>

            <b-row>
              <b-col md="12" style="display:flex;">
                <label for="graphic-name" class="graphic-settings-label graphic-settings-label-group">Шкала времени&nbsp;</label>
                <b-form-checkbox id="graphic-time-scale"
                                 class="graphic-control-checkbox"
                                 v-model="settings.graphicTimeScale"
                                 name="graphic-time-scale"
                                 value="accepted"
                                 unchecked-value="not_accepted">
                </b-form-checkbox>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <label for="graphic-time-format" class="graphic-settings-label">Формат</label>
              </b-col>
              <b-col md="4">
                <b-form-select id="graphic-time-format"
                               class="graphic-control form-control"
                               v-model="settings.timeFormat"
                               :options="timeFormats"
                               value-field="id"
                               text-field="name">
                </b-form-select>
              </b-col>
              <b-col md="2">
                <label for="graphic-date-format" class="graphic-settings-label">Дата</label>
              </b-col>
              <b-col md="4">
                <b-form-select id="graphic-date-format"
                               class="graphic-control form-control"
                               v-model="settings.dateFormat"
                               :options="dateFormats"
                               value-field="id"
                               text-field="name">
                </b-form-select>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="2">
                <label for="graphic-unit-type" class="graphic-settings-label">Интервал</label>
              </b-col>
              <b-col md="4">
                <b-form-select id="graphic-unit-type"
                               class="graphic-control form-control"
                               v-model="settings.timePeriod"
                               :options="mnemoInstance.timePeriods"
                               value-field="id"
                               text-field="name">
                </b-form-select>
              </b-col>
              <b-col md="2">
                <label for="graphic-scale-width" class="graphic-settings-label">Период</label>
              </b-col>
              <b-col md="4">
                <b-form-input id="graphic-scale-width"
                              class="graphic-control form-control"
                              type="number"
                              v-model="settings.scaleWidth">
                </b-form-input>
              </b-col>
            </b-row>

          </b-container>
        </b-tab>
      </b-tabs>
    </b-container>

    <SelectColorModal :ref="`selectColorModal${windowId}`"
                      @select="selectColor"
                      :windowId="windowId"
                      v-model="selectedTagStyleId" />

  </b-modal>
</template>

<script>
  //import Vue from 'vue'
  import axios from 'axios'
  //import MnemoService from '@/services/mnemo.service.js'
  import SelectColorModal from "@/views/graphic/SelectColorModal";
  import Resources from "@/components/graphic/Resources";

  export default {

    name: 'GraphicsEditor',

    props: ['value', 'refConfigurator', 'windowId'],

    components: {
      SelectColorModal, Resources
    },

    data() {
      return {

        connectionOk: false,
        connectionLoss: 0,
        connectionLossTime: null,
        connectionOkTime: null,
        connectionLossTitle: null,
        isLoading: false,

        tagBindingFieldsNames: [
          { key: 'color', label: '', tdClass: 'binded-devices-td' },
          { key: 'variable', label: 'Параметр', tdClass: 'binded-devices-td' },
          { key: 'device', label: 'Прибор учета', tdClass: 'binded-devices-td' },
          { key: 'button', label: '', tdClass: 'binded-devices-td' },
        ],

        chartTypes: [
          { id: 1, name: 'Линейный' },
          { id: 2, name: 'Гистограмма' }, {
            id: 3, name: 'Дискретный'
          }],
        legends: [
          { id: 1, name: 'Не показывать', value: { showForSingleSeries: true, show: false } },
          { id: 2, name: 'Под графиком', value: { showForSingleSeries: true, show: true, position: 'bottom', floating: false, offsetX: 0, offsetY: -2, horizontalAlign: 'center' } },
          { id: 3, name: 'Над графиком', value: { showForSingleSeries: true, show: true, position: 'top', floating: false, offsetX: 0, offsetY: -5, horizontalAlign: 'center' } },
          { id: 4, name: 'На графике сверху', value: { showForSingleSeries: true, show: true, position: 'top', floating: true, offsetX: 20, offsetY: 50, horizontalAlign: 'left' } }
        ],
        scaleValues: [
          { id: 1, name: 'Автоматическая' },
          { id: 2, name: 'Ручная' }],
        timeFormats: [
          { id: 1, name: 'чч:мм:сс' },
          { id: 2, name: 'чч:мм' },
          { id: 3, name: 'мм:сс' }],
        dateFormats: [
          { id: 1, name: 'дд.мм.гггг' },
          { id: 2, name: 'дд.ммм' },
          { id: 3, name: 'дд/мм/гггг' }],
        //timePeriods: [
        //  { id: 1, name: 'Полчаса' },
        //  { id: 2, name: 'Час' },
        //  { id: 3, name: 'Сутки' },
        //  { id: 4, name: 'Месяц' }],
        settings: {
          chartType: null,
          lineCurveSmooth: null, // сглаживание только для линейного графика
          legend: null,
          scaleValue: null,
          scaleValueMin: null,
          scaleValueMax: null,
          timeFormat: null,
          dateFormat: null,
          timePeriod: null,
          scaleWidth: null,
          colors: []
        },

        selectedTags: [],
        selectedTag: null,
        selectedTagStyleId: null,
        tagsTimePeriods: null,
        minimumTimePeriod: null,

        scaleValueMinFloat: null,

        mnemoInstance: {},
        isColorDialogOpened: false,
      }
    },

    created() {
      let self = this;

      this.addMode = true;

      axios.interceptors.request.use(
        function (config) {
          const token = self.getCookie('cfg-token');
          if (token) {
            config.headers.Authorization = "Bearer " + token;
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
    },

    mounted() {
      //console.log('DOM mounted');
      this.$emit("mounted");
    },
    updated: function () {
      this.$nextTick(function () {
        //console.log('Virtual DOM updated');
      })
    },
    methods: {

      onResourceClick(resource) {
        this.refConfigurator.selectResource(resource);
      },

      modalCancel() {
        //console.log('cancel');
        this.$emit("cancel", this.mnemoInstance.id);
        this.$nextTick(() => {
          if (this.$refs.modal) this.$refs.modal.hide();
        });
      },

      getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },

      updateResults(result) {

        //console.log(result);

        if (!result) return;
        this.mnemoInstance = result.mnemoInstance;

        if (this.mnemoInstance.mnemoJSONSettings) {
          this.settings = JSON.parse(this.mnemoInstance.mnemoJSONSettings);
        }

        this.selectedTags = JSON.parse(JSON.stringify(result.selectedTags));

        //console.log(this.selectedTags);

        const times = [];
        this.tagsTimePeriods = '';
        for (let i = 0; i < this.selectedTags.length; i++) {
          const selectedTag = this.selectedTags[i];
          const index = times.findIndex(a => a == selectedTag.timePeriodName);
          if (index === -1) times.push(selectedTag.timePeriodName);

          this.tagsTimePeriods = times.join();
        }

        //console.log('end binding');
        //console.log(result);
        //console.log(this.selectedTags);
      },

      onChangeGraphicTypes(event) {
        //console.log(event);
      },

      save() {
        //console.log('save');

        this.settings.scaleValueMax = parseFloat(this.settings.scaleValueMax);
        this.settings.scaleValueMin = parseFloat(this.settings.scaleValueMin);
        this.settings.scaleWidth = parseInt(this.settings.scaleWidth);

        this.settings.colors = [];

        for (let i = 0; i < this.selectedTags.length; i++) {
          const selectedTag = this.selectedTags[i];
          const color = {
            objectLogicTagId: selectedTag.objectLogicTagId,
            styleId: selectedTag.style.id
          }
          this.settings.colors.push(color);
        }

        //console.log(this.settings);

        this.refConfigurator.saveGraphic(this.settings);
      },

      openColorSelectionDialog(event, tag) {
        if (!this.isColorDialogOpened) {
          this.isColorDialogOpened = true;
          this.selectedTag = JSON.parse(JSON.stringify(tag));
          this.selectedTagStyleId = this.selectedTag.style.id;
          this.$refs[`selectColorModal${this.windowId}`].open(event);
        }
        else {
          this.isColorDialogOpened = false;
          this.$refs[`selectColorModal${this.windowId}`].close();
        }
      },

      graphicTagsTabClicked() {
        this.isColorDialogOpened = false;
        this.$refs[`selectColorModal${this.windowId}`].close();
      },

      selectColor(style) {
        //console.log(style);
        if (this.selectedTag) {
          const device = this.selectedTags.find(a => a.id === this.selectedTag.id);
          device.style = style;
          this.refConfigurator.updateColor(this.selectedTag.id, style);
        }
        this.isColorDialogOpened = false;
      },

      removeSelectedTag(tag) {
        //console.log(tag);
        //console.log(this.selectedTags);
        //const tagIndex = this.selectedTags.findIndex(a => a.id === tag.id);
        //this.selectedTags.splice(tagIndex, 1);

        this.refConfigurator.remove(tag);
      },

      selectTags() {
        this.refConfigurator.selectTags();
      }
    }
  }
</script>

<style scoped>
  .dropdown-button {
    border-style: none !important;
    padding: 0;
    display: flex;
    background: none;
  }

    .dropdown-button:hover {
      opacity: 0.7;
    }

  .round-button {
    margin-right: 2px;
    border-radius: 100%;
    min-width: 20px;
    height: 20px;
    border-style: none;
    color: white;
    text-align: center;
    padding: 0;
    line-height: 20px;
  }

  .graphic-control {
    margin-top: 5px;
    font-size: 15px;
    display: inline-block;
  }

  .error-message {
    font-size: small;
  }

  .graphic-settings-label {
    margin-top: 5px;
    font-size: 15px;
    font-family: "Roboto", "Helvetica Neue", "Noto Sans", "Liberation Sans", "Arial", "sans-serif", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .graphic-settings-label-group {
    margin-top: 2px;
    font-weight: 700;
  }

  .graphic-settings-checkbox-label {
    vertical-align: middle;
    margin-left: 5px;
  }
</style>

<style>

  .close {
    border: none;
    font-size: 24px;
  }

  .error {
    border: 1px solid red !important;
  }

  .binded-devices-td {
    vertical-align: middle;
  }

  .graphic-control-checkbox input  {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0 0 10px;
    padding: 0;
    width: 22px;
    height: 22px;
    border: none;
    cursor: pointer;
    background: rgb(217,69,0);
  }
</style>
