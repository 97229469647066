<template>
  <b-modal id="dashboardsModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           title="Рабочие столы"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @shown="modalShown">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">Рабочие столы</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()">Закрыть</b-button>
    </template>
    <form ref="form" @submit.stop.prevent="modalHandleSubmit">
      <b-table striped
               hover
               :items="value"
               :fields="fields"
               thead-class="hidden_header">
        <template v-slot:cell(name)="data">
          <a href="#" v-on:click.prevent="selectDashboard(data.item)">
            {{ data.item.name }}
          </a>
          <div class="table-actions" v-if="!isReadOnly">
            <b-button size="sm"
                      @click="showEditDashboardModal(data.item)"
                      variant="primary"
                      style="margin-right: 5px">
              <i class="fa fa-pencil"></i>
            </b-button>

            <b-button size="sm"
                      @click="deleteDashboardModal(data.item)"
                      variant="primary">
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </template>
      </b-table>
      <b-button variant="primary"
                @click="showAddDashboardModal"
                v-if="!isReadOnly"><i class="fa fa-plus"></i>Добавить</b-button>
    </form>
    <AddEditDashboardModalComponent v-model="editableDashboard"
                                    v-on:addDashboard="addDashboard"
                                    v-on:editDashboard="editDashboard" />
  </b-modal>
</template>

<script>
import MnemoService from "@/services/mnemo.service";
import AddEditDashboardModalComponent from "@/views/dashboard/AddEditDashboardModal";

export default {
  name: "DahsboardsModalComponent",
  components: { AddEditDashboardModalComponent },
  data() {
    return {
      isBusy: false,
      fields: [
        {
          key: "name",
          label: "Наименование",
        },
      ],
      editableDashboard: null,
    };
  },
  props: ["value", "isReadOnly"],
  methods: {
    modalShown(e) {},
    selectDashboard(dashboard) {
      localStorage.setItem("selectedDashboard", dashboard.id);
      this.$emit("selectDashboard", dashboard);
      this.$nextTick(() => {
        this.$refs.modal.hide();
      });
    },
    showAddDashboardModal() {
      this.editableDashboard = {};
      this.$bvModal.show("addEditDashboardModal");
    },
    addDashboard(dashboard) {
      this.value.push(dashboard);
    },
    showEditDashboardModal(dashboard) {
      this.editableDashboard = JSON.parse(JSON.stringify(dashboard));
      this.$bvModal.show("addEditDashboardModal");
    },
    editDashboard(dashboard) {
      let editedDashboard = this.value.find((x) => x.id == dashboard.id);
      editedDashboard.name = dashboard.name;
    },
    deleteDashboardModal(dashboard) {
      this.$bvModal
        .msgBoxConfirm(
          "Вы действительно хотите удалить рабочий стол '" +
            dashboard.name +
            "'?",
          {
            title: "Удаление рабочего",
            okVariant: "danger",
            okTitle: "Удалить",
            cancelTitle: "Отмена",
            titleTag: "h4",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            MnemoService.deleteDashboard(dashboard.id).then(
              (response) => {},
              (error) => {}
            );
            this.deleteDashboard(dashboard);
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    deleteDashboard(dashboard) {
      let index = this.value.findIndex((el) => el.id === dashboard.id);
      this.value.splice(index, 1);
    },
  },
  computed: {},
};
</script>

<style>
.hidden_header {
  display: none;
}
</style>