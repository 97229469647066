<template>
  <b-modal id="assignMnemoToWindowModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           title="Привязка мнемосхемы"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @ok="modalOk"
           @shown="modalShown"
           @show="modalShow">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">Привязка мнемосхемы</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="primary"
              :disabled="isBusy || !selectedMnemoInstance"
              @click="ok()">
        <b-spinner small v-show="isBusy" class="mr-1"></b-spinner>
        <span>Сохранить</span>
      </b-button>
      <b-button variant="secondary" @click="cancel()">Отмена</b-button>
    </template>
    <form ref="form" @submit.stop.prevent="modalHandleSubmit" v-if="value">
      <b-overlay :show="isLoading">
        <b-form-row>
          <b-col md="12" v-if="!isLoading">
            <b-form-group label="Выберите мнемосхему"
                          label-for="title"
                          v-if="mnemoInstances && mnemoInstances.length > 0">
              <b-form-select v-model="selectedMnemoInstance"
                             :options="mnemoInstances"
                             class="form-control"
                             value-field="id"
                             text-field="name"></b-form-select>
            </b-form-group>
            <b-alert v-else show variant="warning">Для выбранного объекта нет доступных мнемосхем</b-alert>
          </b-col>
        </b-form-row>
      </b-overlay>
    </form>
  </b-modal>
</template>

<script>
import MnemoService from "@/services/mnemo.service";
import ValidationField from "@/components/ValidationField.vue";
import Axios from "axios";

export default {
  name: "AssignMnemoToWindowModalComponent",
  components: {},
  data() {
    return {
      isBusy: false,
      isLoading: true,
      selectedMnemoInstance: null,
      mnemoInstances: [],
    };
  },
  props: ["value"],
  methods: {
    modalShow() {
      this.isLoading = true;
      this.mnemoInstances = [];
    },
    modalShown(e) {
      Axios.all([MnemoService.getMnemonics(this.value.groupId)])
        .then(
          Axios.spread((mnemoInstances) => {
            this.mnemoInstances = mnemoInstances.data;
          })
        )
        .catch((error) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    modalOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isBusy = true;
      this.modalHandleSubmit();
    },
    modalHandleSubmit() {
      let data = {
        mnemoInstanceId: this.selectedMnemoInstance,
        windowId: this.value.windowId,
      };
      MnemoService.assignMnemoToWindow(data)
        .then((response) => {
          this.$nextTick(() => {
            this.$emit("assignedMnemoToWindow", data);
            this.$refs.modal.hide();
          });
        })
        .catch((error) => {})
        .finally(() => (this.isBusy = false));
    },
  },
  computed: {},
};
</script>

<style>
.btn-group-toggle input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
</style>