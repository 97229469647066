<template>
  <div>

    <ApexChartsControl :ref="`apexChartsControl${windowId}`"
                       v-model="value"
                       :windowId="windowId"
                       :graphicName="this.mnemoInstance.name">
    </ApexChartsControl>

    <b-button v-if="connectionLoss>1"
              :title="connectionLossTitle"
              @click="info"
              variant="primary"
              class="alarm-buttons">
      <i class="fa fa-info"></i>
    </b-button>

    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>

  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import ApexChartsControl from "@/components/graphic/ApexChartsControl";
  import DateConverter from '@/tools/date.converter'
  
  export default {

    name: 'GraphicsViewer',

    props: ['value', 'refConfigurator', 'windowId'],

    components: {
      ApexChartsControl
    },

    data() {
      return {

        justifyAlign: 0,
        connectionOk: false,
        connectionLoss: 0,
        connectionLossTime: null,
        connectionOkTime: null,
        connectionLossTitle: null,

        isLoading: false,

        mnemoInstance: {
          groupId: null,
          templateId: null,
          id: null,
          name: null,
          description: null,
          status: null,
          mnemoFile: null,
        },

        selectedTags: [],
        settings: {
          chartType: null,
          legend: null,
          scaleValue: null,
          scaleValueMin: null,
          scaleValueMax: null,
          timeFormat: null,
          dateFormat: null,
          timePeriod: null,
          scaleWidth: null,
          colors: []
        },

        allValues: [],
      }
    },

    mounted() {
      //console.log('mounted');
      //console.log('Graphic viewer DOM mounted, windowId: ' + this.windowId);
      this.$emit("mounted");
      //console.log(this.windowId);
    },

    beforeDestroy() {
      this.unsubscribe();
    },

    created() {
      let self = this;

      this.unsubscribe = this.$store.subscribe((mutation, state) => {
        //console.log('subscribe');
        //console.log(this.mnemoInstance);
        if (mutation.type === 'SetGraphicState' && state.graphicState !== null) {
          this.graphicValuesFromState(state.graphicState, state.mnemoId);
        }
        if (mutation.type === 'SetMnemoState' && state.mnemoState !== null) {
          this.updateLastValue(state.mnemoState, state.mnemoId);
        }
        else if (mutation.type === 'ConnectionLoss') {
          this.connectionLoss++;
          //console.log(this.connectionLoss);
          if (this.connectionLoss > 1) {
            if (this.connectionLossTime === null) {
              this.connectionLossTime = new Date().toLocaleString();
              this.connectionLossTitle = `Соединение потеряно в ${this.connectionLossTime ? this.connectionLossTime : ''}`;
            }
            //console.log(this.connectionLossTime);
          }
        }
        else if (mutation.type === 'ConnectionOk') {
          this.connectionLoss = 0;
          this.connectionLossTitle = null;
          this.connectionOkTime = new Date().toLocaleString();
          if (this.mnemoInstance && this.mnemoInstance.id) {
            setTimeout(function () {
              Vue.prototype.initConnection(self.mnemoInstance.id);
              //console.log('Init connection started');
            }, 500);
          };
          //console.log(this.connectionOkTime);
        }
      });

      axios.interceptors.request.use(
        function (config) {
          const token = self.getCookie('cfg-token');
          if (token) {
            config.headers.Authorization = "Bearer " + token;
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
    },

    methods: {

      getCookie(name) {

        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },

      updateResults(result) {
        const self = this;

        //console.log(result);
        this.isEmpty = true;

        if (!result) return;
        this.mnemoInstance = result.mnemoInstance;

        if (this.mnemoInstance.mnemoJSONSettings) {
          this.settings = JSON.parse(this.mnemoInstance.mnemoJSONSettings);
        }
        this.selectedTags = JSON.parse(JSON.stringify(result.selectedTags));

        //console.log(this.selectedTags);
        //console.log(this.mnemoInstance);
        //console.log(this.settings);

        const endDate = new Date(); /// Время с UTC
        const startDate = DateConverter.getStartDate(endDate, this.settings);

        if (this.mnemoInstance.id) {
          setTimeout(function () {
            Vue.prototype.initGraphicConnection(self.mnemoInstance.id, startDate, endDate);
            //console.log('Init connection started');
          }, 1);
        }
      },

      info() {

        alert(this.connectionLossTitle);
      },

      resize(width, height) {
        //console.log('resize ' + width + ' ' + height);
        if (this.settings && this.selectedTags && this.allValues) {
          //console.log(this.allValues);
          this.$refs[`apexChartsControl${this.windowId}`].resize(this.settings, this.selectedTags, this.allValues, width, height);
        }
      },

      graphicValuesFromState(graphicStorage, mnemoId) {

        //console.log('graphicValuesFromState');
        if (this.mnemoInstance.id && this.mnemoInstance.id === mnemoId) {
          //console.log('Data from state ' + this.mnemoInstance.id + ' ' + mnemoId);
          this.allValues = JSON.parse(graphicStorage);

          //const endDate = DateConverter.getEndDate();
          //console.log(endDate);

          if (this.allValues !== null) {
            this.$refs[`apexChartsControl${this.windowId}`].updateResults(this.settings, this.selectedTags, this.allValues);
          }
        }
      },

      updateLastValue(storage) {

        const tags = JSON.parse(storage);

        if (tags !== null) {
          for (let i = 0; i < tags.length; i++) {
            const tag = tags[i];

            const index = this.selectedTags.findIndex(a => a.objectLogicTagId === tag.IdTag);
            if (index !== -1) {
              //console.log('Tag ' + tag.IdTag + ' ' + tag.TimeStamp);

              //console.log(this.allValues);
              this.allValues.push(tag);
              this.removeOldValues(this.settings);
              //console.log(this.allValues);

              this.$refs[`apexChartsControl${this.windowId}`].updateResults(this.settings, this.selectedTags, this.allValues);
            }
          }
        }
      },

      removeOldValues(settings) {
        const endDate = DateConverter.getEndDate();
        const startDate = DateConverter.getStartDate(endDate, settings);

        const validValues = [];
        for (let i = 0; i < this.allValues.length; i++) {
          const tag = this.allValues[i];
          if (new Date(tag.TimeStamp) >= startDate) {
            validValues.push(tag)
          }
          //else {
            //console.log('Old value IdTag ' + tag.IdTag);
            //console.log('Scale width ' + this.settings.scaleWidth);
            //console.log('TimeStamp ' + tag.TimeStamp);
            //console.log('TimeStamp date ' + new Date(tag.TimeStamp));
            //console.log('Start date ' + startDate);
            //console.log('End date ' + endDate);
          //}
        }
        this.allValues = validValues;
      }
    }
  }
</script>

