<template>
  <div role="group" style="margin-left: 10px">
    <div role="treeitem" aria-expanded="false" v-for="child in tree" :key="child.id">
      <span>
        <a href="#" v-on:click.prevent="onExpandCollapseNode(child)" style="margin-right: 5px">
          <i v-if="child.expanded" class="fa fa-minus"></i>
          <i v-else-if="!child.isTag" class="fa fa-plus"></i>

        </a>
        <i class="fa fa-home" style="margin-right: 5px"></i>
        <a href="#" v-on:click.prevent="onSelectNode(child)">{{child.name}}</a>
      </span>
      <TreeNode v-if="!child.isTag && child.expanded" :treeNode="child"
                @onExpandCollapseNode="onExpandCollapseNode" @onSelectNode="onSelectNode" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TreeNode',
    props: {
      treeNode: { type: Object },
    },
    computed: {
      tree() {
        if (this.treeNode && this.treeNode.children && this.treeNode.children.length > 0) {
          return [...this.treeNode.children];
        } else {
          return [];
        }
      },
    },
    methods: {
      onSelectNode(node) {
        //console.log(node);
        //if (node.isTag) {
        this.$emit('onSelectNode', node);
        //}
      },
      onExpandCollapseNode(node) {
        //console.log(node);
        if (!node.isTag) {
          this.$emit('onExpandCollapseNode', node);
        }
      },
    },
  }

</script>

<style scoped>
  .hide-item {
    display: none;
  }
</style>

