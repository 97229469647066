<template>
  <b-modal id="addEditMnemonicModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           :title="modalTitle"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @ok="modalOk"
           @show="modalShow"
           @shown="modalShown">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">{{modalTitle}}</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="primary" :disabled="isBusy" @click="ok()">
        <b-spinner small v-show="isBusy" class="mr-1"></b-spinner>
        <span>Сохранить</span>
      </b-button>
      <b-button variant="secondary" @click="cancel()">Отмена</b-button>
    </template>
    <form ref="form" @submit.stop.prevent="modalHandleSubmit" v-if="value">
      <b-form-row>
        <b-col md="12">
          <b-form-group label="Наименование мнемосхемы" label-for="name">
            <b-form-input id="name"
                          ref="focusName"
                          v-model="value.name"
                          :class="{ error: validationErrors.Name }"
                          @change="validationErrors.Name = null"
                          trim></b-form-input>
            <ValidationField v-bind:errors="validationErrors.Name" />
          </b-form-group>
        </b-col>
      </b-form-row>
    </form>
  </b-modal>
</template>

<script>
import MnemoService from "@/services/mnemo.service";
import ValidationField from "@/components/ValidationField.vue";

export default {
  name: "AddEditMnemonicModal",
  components: {
    ValidationField,
  },
  data() {
    return {
      isBusy: false,
      validationErrors: [],
      refreshTree: false,
      treeNode: null,
      scrollHeight: null,
      filter: null,
      objects: [],
    };
  },
  props: ["value", "groupId"],

  updated: function () {
    this.$nextTick(function () {});
  },

  methods: {
    modalShow() {
      this.isBusy = false;
      this.validationErrors = [];
      this.refreshTree = !this.refreshTree;
    },
    modalShown(e) {
      this.$refs.focusName.focus();
    },

    modalOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.isBusy = true;
      this.modalHandleSubmit();
    },

    modalHandleSubmit() {
      this.validationErrors = [];
      this.value.groupId = this.groupId;

      MnemoService.saveMnemonic(this.value)
        .then((response) => {
          if (!this.value.id) {
            this.$emit("add", response.data);
          } else {
            this.$emit("edit", this.value);
          }
          this.$nextTick(() => {
            this.$refs.modal.hide();
          });
        })
        .catch((error) => {
          if (error.response.status === 400) {
            this.validationErrors = error.response.data.errors;
          }
        })
        .finally(() => (this.isBusy = false));
    },

    /*
      onSelectNode(object) {
        console.log(object);
      },

      selectGroup() {
        if (this.treeNode === null) {
          this.loading = true;
          MnemoService.getGroupsTree()
            .then(response => {
              console.log('getGroupsTree');
              this.objects = this.getGroupsWithChildren(response.data, null);
              //console.log(this.objects);

              this.treeNode = {};
              this.treeNode.children = this.objects;

              this.loading = false;
              this.refreshTree = !this.refreshTree;
            })
            .catch(error => {
              console.log(error);
              this.loading = false;
            });
        } else {
          //console.log(this.treeNode);
          this.refreshTree = !this.refreshTree;
        }
      },

      onChangeFilter(value) {
        this.treeNode.children = this.objects.filter(a => a.name.indexOf(value) !== -1);
        this.refreshTree = !this.refreshTree;
      },

      getGroupsWithChildren(groups, parent) {
        const objects = [];
        const parentId = parent ? parent.id : null;
        const childs = groups.filter(a => a.parentId !== parentId);
        const sortedChilds = childs.sort((a, b) => a.name.localeCompare(b.name));
        for (let i = 0; i < sortedChilds.length; i++) {
          const node = sortedChilds[i];
          node.parent = parent;
          node.expanded = false;
          node.selected = false;
          node.children = this.getGroupsWithChildren(groups, node);
          objects.push(node);
        }
        return objects;
      },

      lookingForNode(nodes, nodeId) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          if (node.id === nodeId) return node;
          else if (node.children && node.children.length > 0) {
            node = this.lookingForNode(node.children, nodeId);
            if (node) return node;
          }
        }
        return null;
      },

      onExpandCollapseNode(node) {
        //console.log('Object');
        //console.log(node);
        //console.log(this.treeNode.children);
        this.scrollHeight = this.$refs.node.scrollHeight;
        const group = this.lookingForNode(this.treeNode.children, node.id);
        //console.log(group);
        if (!group.children) {
          this.loading = true;
          MnemoService.getGroupsTreeWithLevel(node.id)
            .then(response => {
              //console.log(response.data);
              const sorted = response.data.sort((a, b) => a.name.localeCompare(b.name));
              const filtered = sorted.filter(a => a.id !== node.id);

              group.children = filtered;
              group.expanded = true;
              //console.log(group);
              this.loading = false;
              this.refreshTree = !this.refreshTree;
            })
            .catch(error => {
              console.log(error);
              this.loading = false;
            });
        } else {
          group.expanded = !group.expanded;
          this.refreshTree = !this.refreshTree;
          //console.log(node);
        }
      },
      */
  },
  computed: {
    modalTitle: function () {
      return this.value && this.value.id
        ? "Редактирование мнемосхемы"
        : "Добавление мнемосхемы";
    },
  },
};
</script>
