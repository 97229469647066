class PaletteColors {
    getPalette() {
        return [
            {
                id: 1,
                styles: [
                    { id: 1, backgroundColor: 'rgb(0,0,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 2, backgroundColor: 'rgb(67,67,67)', color: 'rgb(255,255,255)', selected: false },
                    { id: 3, backgroundColor: 'rgb(102,102,102)', color: 'rgb(255,255,255)', selected: false },
                    { id: 4, backgroundColor: 'rgb(153,153,153)', color: 'rgb(255,255,255)', selected: false },
                    { id: 5, backgroundColor: 'rgb(183,183,183)', color: 'rgb(255,255,255)', selected: false },
                    { id: 6, backgroundColor: 'rgb(204,204,204)', color: 'rgb(0,0,0)', selected: false },
                    { id: 7, backgroundColor: 'rgb(217,217,217)', color: 'rgb(0,0,0)', selected: false },
                    { id: 8, backgroundColor: 'rgb(239,239,239)', color: 'rgb(0,0,0)', selected: false },
                    { id: 9, backgroundColor: 'rgb(243,243,243)', color: 'rgb(0,0,0)', selected: false },
                    { id: 10, backgroundColor: 'rgb(255,255,255)', color: 'rgb(0,0,0)', selected: false },
                ],
            },
            {
                id: 2,
                styles: [
                    { id: 11, backgroundColor: 'rgb(152,0,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 12, backgroundColor: 'rgb(255,0,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 13, backgroundColor: 'rgb(255,153,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 14, backgroundColor: 'rgb(255,255,0)', color: 'rgb(0,0,0)', selected: false },
                    { id: 15, backgroundColor: 'rgb(1,255,0)', color: 'rgb(0,0,0)', selected: false },
                    { id: 16, backgroundColor: 'rgb(1,255,255)', color: 'rgb(0,0,0)', selected: false },
                    { id: 17, backgroundColor: 'rgb(74,134,232)', color: 'rgb(255,255,255)', selected: false },
                    { id: 18, backgroundColor: 'rgb(0,0,255)', color: 'rgb(255,255,255)', selected: false },
                    { id: 19, backgroundColor: 'rgb(153,0,255)', color: 'rgb(255,255,255)', selected: false },
                    { id: 20, backgroundColor: 'rgb(255,0,255)', color: 'rgb(255,255,255)', selected: false },
                ],
            },
            {
                id: 3,
                styles: [
                    { id: 21, backgroundColor: 'rgb(230,184,175)', color: 'rgb(0,0,0)', selected: false },
                    { id: 22, backgroundColor: 'rgb(244,204,204)', color: 'rgb(0,0,0)', selected: false },
                    { id: 23, backgroundColor: 'rgb(252,229,205)', color: 'rgb(0,0,0)', selected: false },
                    { id: 24, backgroundColor: 'rgb(255,242,204)', color: 'rgb(0,0,0)', selected: false },
                    { id: 25, backgroundColor: 'rgb(217,234,211)', color: 'rgb(0,0,0)', selected: false },
                    { id: 26, backgroundColor: 'rgb(208,224,227)', color: 'rgb(0,0,0)', selected: false },
                    { id: 27, backgroundColor: 'rgb(201,218,248)', color: 'rgb(0,0,0)', selected: false },
                    { id: 28, backgroundColor: 'rgb(207,226,243)', color: 'rgb(0,0,0)', selected: false },
                    { id: 29, backgroundColor: 'rgb(217,210,233)', color: 'rgb(0,0,0)', selected: false },
                    { id: 30, backgroundColor: 'rgb(234,209,220)', color: 'rgb(0,0,0)', selected: false },
                ],
            },
            {
                id: 4,
                styles: [
                    { id: 31, backgroundColor: 'rgb(221,126,107)', color: 'rgb(255,255,255)', selected: false },
                    { id: 32, backgroundColor: 'rgb(234,153,153)', color: 'rgb(255,255,255)', selected: false },
                    { id: 33, backgroundColor: 'rgb(249,203,156)', color: 'rgb(0,0,0)', selected: false },
                    { id: 34, backgroundColor: 'rgb(255,229,153)', color: 'rgb(0,0,0)', selected: false },
                    { id: 35, backgroundColor: 'rgb(182,215,168)', color: 'rgb(0,0,0)', selected: false },
                    { id: 36, backgroundColor: 'rgb(162,196,201)', color: 'rgb(0,0,0)', selected: false },
                    { id: 37, backgroundColor: 'rgb(164,194,244)', color: 'rgb(255,255,255)', selected: false },
                    { id: 38, backgroundColor: 'rgb(159,197,232)', color: 'rgb(255,255,255)', selected: false },
                    { id: 39, backgroundColor: 'rgb(180,167,214)', color: 'rgb(255,255,255)', selected: false },
                    { id: 40, backgroundColor: 'rgb(213,166,189)', color: 'rgb(255,255,255)', selected: false },
                ],
            },
            {
                id: 5,
                styles: [
                    { id: 41, backgroundColor: 'rgb(204,65,37)', color: 'rgb(255,255,255)', selected: false },
                    { id: 42, backgroundColor: 'rgb(224,102,102)', color: 'rgb(255,255,255)', selected: false },
                    { id: 43, backgroundColor: 'rgb(246,178,107)', color: 'rgb(0,0,0)', selected: false },
                    { id: 44, backgroundColor: 'rgb(255,217,102)', color: 'rgb(0,0,0)', selected: false },
                    { id: 45, backgroundColor: 'rgb(147,196,125)', color: 'rgb(0,0,0)', selected: false },
                    { id: 46, backgroundColor: 'rgb(134,176,184)', color: 'rgb(255,255,255)', selected: false },
                    { id: 47, backgroundColor: 'rgb(109,158,235)', color: 'rgb(255,255,255)', selected: false },
                    { id: 48, backgroundColor: 'rgb(111,168,220)', color: 'rgb(255,255,255)', selected: false },
                    { id: 49, backgroundColor: 'rgb(142,124,195)', color: 'rgb(255,255,255)', selected: false },
                    { id: 50, backgroundColor: 'rgb(194,123,160)', color: 'rgb(255,255,255)', selected: false },
                ],
            },
            {
                id: 6,
                styles: [
                    { id: 51, backgroundColor: 'rgb(166,28,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 52, backgroundColor: 'rgb(204,0,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 53, backgroundColor: 'rgb(230,145,56)', color: 'rgb(255,255,255)', selected: false },
                    { id: 54, backgroundColor: 'rgb(241,194,50)', color: 'rgb(255,255,255)', selected: false },
                    { id: 55, backgroundColor: 'rgb(106,168,79)', color: 'rgb(255,255,255)', selected: false },
                    { id: 56, backgroundColor: 'rgb(69,129,142)', color: 'rgb(255,255,255)', selected: false },
                    { id: 57, backgroundColor: 'rgb(60,120,216)', color: 'rgb(255,255,255)', selected: false },
                    { id: 58, backgroundColor: 'rgb(61,133,198)', color: 'rgb(255,255,255)', selected: false },
                    { id: 59, backgroundColor: 'rgb(103,78,167)', color: 'rgb(255,255,255)', selected: false },
                    { id: 60, backgroundColor: 'rgb(166,77,121)', color: 'rgb(255,255,255)', selected: false },
                ],
            },
            {
                id: 7,
                styles: [
                    { id: 61, backgroundColor: 'rgb(133,32,12)', color: 'rgb(255,255,255)', selected: false },
                    { id: 62, backgroundColor: 'rgb(153,0,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 63, backgroundColor: 'rgb(180,95,6)', color: 'rgb(255,255,255)', selected: false },
                    { id: 64, backgroundColor: 'rgb(191,144,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 65, backgroundColor: 'rgb(56,118,29)', color: 'rgb(255,255,255)', selected: false },
                    { id: 66, backgroundColor: 'rgb(19,79,92)', color: 'rgb(255,255,255)', selected: false },
                    { id: 67, backgroundColor: 'rgb(17,85,204)', color: 'rgb(255,255,255)', selected: false },
                    { id: 68, backgroundColor: 'rgb(11,83,148)', color: 'rgb(255,255,255)', selected: false },
                    { id: 69, backgroundColor: 'rgb(53,28,117)', color: 'rgb(255,255,255)', selected: false },
                    { id: 70, backgroundColor: 'rgb(116,27,71)', color: 'rgb(255,255,255)', selected: false },
                ],
            },
            {
                id: 8,
                styles: [
                    { id: 71, backgroundColor: 'rgb(91,15,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 72, backgroundColor: 'rgb(102,0,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 73, backgroundColor: 'rgb(120,63,4)', color: 'rgb(255,255,255)', selected: false },
                    { id: 74, backgroundColor: 'rgb(127,96,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 75, backgroundColor: 'rgb(39,78,19)', color: 'rgb(255,255,255)', selected: false },
                    { id: 76, backgroundColor: 'rgb(12,52,61)', color: 'rgb(255,255,255)', selected: false },
                    { id: 77, backgroundColor: 'rgb(28,69,135)', color: 'rgb(255,255,255)', selected: false },
                    { id: 78, backgroundColor: 'rgb(7,55,99)', color: 'rgb(255,255,255)', selected: false },
                    { id: 79, backgroundColor: 'rgb(32,18,77)', color: 'rgb(255,255,255)', selected: false },
                    { id: 80, backgroundColor: 'rgb(76,17,48)', color: 'rgb(255,255,255)', selected: false },
                ],
            },
            {
                id: 9,
                styles: [
                    { id: 81, backgroundColor: 'rgb(0,0,0)', color: 'rgb(255,255,255)', selected: false },
                    { id: 82, backgroundColor: 'rgb(255,255,255)', color: 'rgb(0,0,0)', selected: false },
                    { id: 83, backgroundColor: 'rgb(66,133,244)', color: 'rgb(255,255,255)', selected: false },
                    { id: 84, backgroundColor: 'rgb(234,67,53)', color: 'rgb(255,255,255)', selected: false },
                    { id: 85, backgroundColor: 'rgb(251,188,4)', color: 'rgb(255,255,255)', selected: false },
                    { id: 86, backgroundColor: 'rgb(52,168,83)', color: 'rgb(255,255,255)', selected: false },
                    { id: 87, backgroundColor: 'rgb(255,109,1)', color: 'rgb(255,255,255)', selected: false },
                    { id: 88, backgroundColor: 'rgb(70,189,198)', color: 'rgb(255,255,255)', selected: false },
                ],
            },
        ];
    }
}

export default new PaletteColors();

