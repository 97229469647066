<template>
  <div id="mnemonics" 
       ref="mnemonics">
    
    <MnemonicsContent ref="mnemonicsContent"
                      :mode="mode" 
                      v-model="selectedMnemonic.id" />

    <div class="control-buttons">
      <b-button
        variant="primary"
        pill
        class="control-button"
        @click="selectMnemo()"
      >
        <i class="fa fa-gear"></i>
      </b-button>

      <b-button
        v-if="mode === 'view' && !isReadOnly"
        v-bind:disabled="editDisabled"
        variant="primary"
        pill
        class="control-button"
        @click="mode = 'edit'"
      >
        <i class="fa fa-pencil"></i>
      </b-button>

      <b-button
        v-if="mode === 'edit'  && !isReadOnly"
        variant="primary"
        pill
        class="control-button"
        @click="mode = 'view'"
      >
        <i class="fa fa-area-chart"></i>
      </b-button>
    </div>

    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>

    <!--Список мнемосхем -->
    <MnemonicsModal
      v-model="mnemonics"
      :groupId="groupId"
      :isReadOnly="isReadOnly"
      v-on:selectMnemonic="selectMnemonic"
      v-on:deleteMnemonic="deleteMnemonic"
    />
  </div>
</template>

<script>
import MnemonicsContent from "@/views/mnemo/MnemonicsContent";
import MnemonicsModal from "@/views/mnemo/MnemonicsModal";
import MnemoService from "@/services/mnemo.service.js";

export default {
  name: "Mnemonics",
  components: {
    MnemonicsContent,
    MnemonicsModal,
  },
  data() {
    return {
      mode: "view",

      mnemonics: [],
      selectedMnemonic: {},
      isLoading: false,
      editDisabled: true,

      groupId: null,
      isReadOnly: false,
    };
  },

  mounted() {
    const self = this;
    //window.addEventListener('resize', function () { 
    //  if(self.$refs.mnemonicsContent) {
    //    self.$refs.mnemonicsContent.resize(
    //      self.$refs.mnemonics.clientWidth,
    //      self.$refs.mnemonics.clientHeight);
    //  }
    //});
    
    //MnemoService.updateTemplate('Тест 120'); // 120 приборов
    //MnemoService.updateTemplate('Тест 30'); // 30 приборов
    //MnemoService.updateTemplate('Регулятор температуры H500');
    //MnemoService.updateTemplate('Бойлер горячего водоснабжения');
    //MnemoService.updateTemplate('Линейная электрическая для Боржоми');

    if (this.$route.query.isReadOnly) {
      this.isReadOnly = this.$route.query.isReadOnly.toLowerCase() === "true";
    }
    this.groupId = this.$route.query.groupId;

    this.isLoading = true;
    this.mnemonics = [];
    MnemoService.getMnemonics(this.groupId)
      .then((response) => {
        this.mnemonics = response.data;
        if (this.mnemonics.length > 0) this.selectMnemonic(this.mnemonics[0]);
        //console.log(this.mnemonics);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  },

  methods: {
    selectMnemo() {
      this.$bvModal.show("mnemonicsModal");
    },

    selectMnemonic(mnemonic) {
      this.selectedMnemonic = mnemonic;
      this.mode = "view";
      this.editDisabled = false;
      //console.log(this.selectedMnemonic);
    },

    deleteMnemonic() {
      this.selectMnemonic(
        this.mnemonics.length > 0 ? this.mnemonics[0] : { id: undefined }
      );
    },
  },
};
</script>
