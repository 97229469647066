<template>

  <b-container fluid>
    <div v-if="mnemoDevices.length>0">
      <b-table :items="mnemoDevices" :fields="mnemoDevicesFieldsNames" class="table-borderless">
        <template #cell(code)="data">
          <label class="label-group-header" style="margin-right:10px">{{data.item.mnemoDeviceCode}}</label>
          <label v-if="data.item.mnemoDeviceText"> Текст на мнемосхеме: {{data.item.mnemoDeviceText}}</label>

          <div v-if="data.item.mnemoDeviceProps.length>0">
            <b-table :items="data.item.mnemoDeviceProps" :fields="mnemoDevicesPropsFieldsNames" class="table-striped table-borderless">
              <template #cell(name)="data">
                <label>{{data.item.objectLogicColumnName}}</label>
              </template>
            </b-table>

            <div v-if="data.item.mnemoTags.length>0">
              <b-table :items="data.item.mnemoTags" :fields="mnemoDevicesTagsFieldsNames" class="table-striped table-borderless">
                <template #cell(mnemotagname)="data">
                  <label>{{data.item.variableName}}</label>
                </template>
                <template #cell(typeparameter)="data">
                  <label>{{data.item.typeParametersName}}</label>
                </template>
                <template #cell(mnemotagvalue)="data">
                  <label>{{data.item.mnemoVarText}}</label>
                </template>
              </b-table>
            </div>
          </div>
        </template>
      </b-table>
    </div>

    <div v-if="primitives.length>0">
      <b-table :items="primitives" :fields="primitivesFieldsNames" class="table-borderless">
        <template #cell(name)="data">
          <label class="label-group-header">{{data.item.name}}</label>

          <div v-if="data.item.mnemoTags.length>0">
            <b-table :items="data.item.mnemoTags" :fields="primitivesTagsFieldsNames" class="table-striped table-borderless">
              <template #cell(argument)="data">
                <label>{{data.item.mnemoArgumentName}}</label>
              </template>
              <template #cell(devicecode)="data">
                <label>{{data.item.primitiveDeviceCode}}</label>
              </template>
              <template #cell(variablename)="data">
                <label>{{data.item.variableName}}</label>
              </template>
              <template #cell(typeparameter)="data">
                <label>{{data.item.typeParametersName}}</label>
              </template>
            </b-table>
          </div>
        </template>
      </b-table>
    </div>

  </b-container>

</template>

<script>

  export default {

    name: "MnemoConfigView",

    props: ["value", "mnemoDevices", "primitives"],

    data() {
      return {

        mnemoDevicesFieldsNames: [
          { key: 'code', label: '' },
        ],
        mnemoDevicesPropsFieldsNames: [
          { key: 'name', label: '' },
        ],
        mnemoDevicesTagsFieldsNames: [
          { key: 'mnemotagname', label: 'Теги прибора на мнемосхеме' },
          { key: 'typeparameter', label: 'Ресурс' },
          { key: 'mnemotagvalue', label: 'Значение на мнемосхеме' },
        ],
        primitivesFieldsNames: [
          { key: 'name', label: '' },
        ],
        primitivesTagsFieldsNames: [
          { key: 'argument', label: 'Наименование' },
          { key: 'devicecode', label: 'Прибор' },
          { key: 'variablename', label: 'Теги прибора на мнемосхеме' },
          { key: 'typeparameter', label: 'Ресурс' },
        ],

      }
    },

    methods: {

      modalCancel() {
        this.$emit("cancel");
      },

    }
  };
</script>
<style>
  .label-group-header {
    font-weight: 700;
  }
</style>