import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import store from "@/store/index.js"

export default {
    install(Vue) {
        // use a new Vue instance as the interface for Vue components to receive/send SignalR events
        // this way every component can listen to events or send new events using this.$questionHub
        const mnemoHub = new Vue({ store })

        Vue.prototype.$mnemoHub = mnemoHub

        // Provide methods to connect/disconnect from the SignalR hub
        let connection = null
        let startedPromise = null
        let manuallyClosed = false

        Vue.prototype.startMnemoHub = () => {
            connection = new HubConnectionBuilder()
                .withUrl(process.env.VUE_APP_MnemoHubPath, {
                        skipNegotiation: true,
                        transport: 1
                    }
                    /*, {
                                        accessTokenFactory: () => {
                                            const authInfo = localStorage.getItem('auth-info');
                                            return authInfo ? JSON.parse(authInfo).access_token : null;
                                        }
                                    }*/
                )
                .configureLogging(LogLevel.None)
                .build()

            // Forward hub events through the event, so we can listen for them in the Vue components
            connection.on('ReceiveMessage', (message) => {
                //console.log('Receive Message');
                mnemoHub.$store.dispatch('UpdateMnemoState', message)
            })
            connection.on('ReceiveHistoryMessage', (message, mnemoId) => {
                //console.log('Receive history message');
                mnemoHub.$store.dispatch('UpdateGraphicState', { message: message, mnemoId: mnemoId })
            })
            connection.on('MnemoConfigurationUpdated', (message) => {
                //console.log('Mnemo Configuration Updated');
                mnemoHub.$store.dispatch('MnemoConfigurationUpdated', message)
            })

            // You need to call connection.start() to establish the connection but the client wont handle reconnecting for you!
            // Docs recommend listening onclose and handling it there.
            // This is the simplest of the strategies
            function start() {
                startedPromise = connection.start()
                    .then(() => {
                        mnemoHub.$store.dispatch('ConnectionOk', true);
                        //console.log('Hub is started')
                    })
                    .catch(err => {
                        mnemoHub.$store.dispatch('ConnectionLoss', false);
                        console.error('Failed to connect with hub', err);
                        setTimeout(() => {
                            Vue.prototype.startMnemoHub();
                        }, 5000);

                        //return new Promise((resolve, reject) => setTimeout(() => start().then(resolve).catch(reject), 5000));
                    })
                return startedPromise
            }
            connection.onclose(() => {
                if (!manuallyClosed) start()
            })

            // Start everything
            manuallyClosed = false
            start()
        }
        Vue.prototype.initConnection = (mnemoId) => {
            if (connection !== null) {
                connection.invoke('InitConnection', mnemoId)
                    .then(() => {
                        //console.log('Init Connection done');
                    })
                    .catch((error) => { console.log('Init Connection failed. Error: ' + error); });
            }
        }
        Vue.prototype.initGraphicConnection = (mnemoId, start, end) => {
            if (connection !== null) {
                //console.log(start);
                //console.log(end);
                connection.invoke('InitGraphicConnection', mnemoId, start, end)
                    .then(() => {
                        //console.log('Init Connection done');
                    })
                    .catch((error) => { console.log('Init Graphic Connection failed. Error: ' + error); });
            }
        }
        Vue.prototype.setTagsValues = (values) => {
            if (connection !== null) {
                connection.invoke('SetTagsValues', values)
                    .then(() => {
                        //console.log('SetTagsValues done');
                    })
                    .catch((error) => { console.log('SetTagsValues failed. Error: ' + error); });
            }
        }
        Vue.prototype.stopMnemoHub = () => {
            if (!startedPromise) return

            manuallyClosed = true
            return startedPromise
                .then(() => connection.stop())
                .then(() => { startedPromise = null })
        }
    }
}