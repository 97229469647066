<template>
  <b-modal id="mnemonicsModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           title="Мнемосхемы"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @shown="modalShown">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">Мнемосхемы</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="primary" @click="cancel()">Закрыть</b-button>
    </template>
    <form ref="form" @submit.stop.prevent="modalHandleSubmit">
      <b-table striped
               hover
               :items="value"
               :fields="fields"
               thead-class="hidden_header">
        <template v-slot:cell(name)="data">
          <a href="#" v-on:click.prevent="selectMnemonic(data.item)">
            {{ data.item.name }}
          </a>
          <div class="table-actions" v-if="!isReadOnly">
            <b-button size="sm"
                      @click="showEditMnemonicModal(data.item)"
                      variant="primary"
                      style="margin-right: 5px">
              <i class="fa fa-pencil"></i>
            </b-button>

            <b-button size="sm"
                      @click="deleteMnemonicModal(data.item)"
                      variant="primary">
              <i class="fa fa-trash"></i>
            </b-button>
          </div>
        </template>
      </b-table>
      <b-button v-if="!isReadOnly" variant="primary" @click="showAddMnemonicModal"><i class="fa fa-plus"></i>Добавить</b-button>
    </form>
    <AddEditMnemonicModal v-model="editableMnemonic"
                          :groupId="groupId"
                          v-on:add="addMnemonic"
                          v-on:edit="editMnemonic" />
  </b-modal>
</template>

<script>
  import MnemoService from "@/services/mnemo.service";
  import AddEditMnemonicModal from "@/views/mnemo/AddEditMnemonicModal";

  export default {

    name: "MnemonicsModal",

    props: ['value', 'groupId','isReadOnly'],

    components: { AddEditMnemonicModal },

    data() {
      return {
        isBusy: false,
        fields: [
          {
            key: "name",
            label: "Наименование",
          },
        ],
        editableMnemonic: null,
      };
    },

    methods: {
      modalShown(e) { },
      selectMnemonic(mnemonic) {
        this.$emit("selectMnemonic", mnemonic);
        this.$nextTick(() => {
          this.$refs.modal.hide();
        });
      },
      showAddMnemonicModal() {
        this.editableMnemonic = {};
        this.$bvModal.show("addEditMnemonicModal");
      },
      addMnemonic(mnemonic) {
        this.value.push(mnemonic);
      },
      showEditMnemonicModal(mnemonic) {
        this.editableMnemonic = JSON.parse(JSON.stringify(mnemonic));
        this.$bvModal.show("addEditMnemonicModal");
      },
      editMnemonic(mnemonic) {
        let editedMnemonic = this.value.find((x) => x.id == mnemonic.id);
        editedMnemonic.name = mnemonic.name;
      },
      deleteMnemonicModal(mnemonic) {
        this.$bvModal
          .msgBoxConfirm(
            "Вы действительно хотите удалить мнемосхему '" +
            mnemonic.name +
            "'?",
            {
              title: "Удаление мнемосхемы",
              okVariant: "danger",
              okTitle: "Удалить",
              cancelTitle: "Отмена",
              titleTag: "h4",
              footerClass: "p-2",
              hideHeaderClose: false,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              MnemoService.deleteMnemonic(mnemonic.id).then(
                (response) => { },
                (error) => { }
              );
              this.deleteMnemonic(mnemonic);
              this.$nextTick(() => {
                this.$emit("deleteMnemonic");
              });
            }
          })
          .catch((err) => {
            // An error occurred
          });
      },
      deleteMnemonic(mnemonic) {
        let index = this.value.findIndex((el) => el.id === mnemonic.id);
        this.value.splice(index, 1);
      },
    },
    computed: {},
  };
</script>

<style>
  .hidden_header {
    display: none;
  }
</style>
