<template>
  <b-modal :id="'primitiveBaseDialog'+windowId"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           title="Задание параметров"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @ok="modalOk"
           @shown="modalShown">
    <template v-slot:modal-header="{ }">
      <label>Параметры {{primitiveBaseDialogTitle}}</label>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="primary" :disabled="loading" @click="ok()">
        <b-spinner small v-show="loading" class="mr-1"></b-spinner>
        <span>Передать на прибор</span>
      </b-button>
      <b-button variant="primary" @click="closePrimitiveBaseDialog()">Отмена</b-button>
    </template>
    <form ref="form" @submit.stop.prevent="modalHandleSubmit" v-if="value">
      <b-form-row v-for="(tag, key) in tags" :key="key">
        <b-col md="12">
          <label>{{tag.name}}, {{tag.unit}}</label>
        </b-col>
        <b-col md="12">
          <b-form-input v-model="tag.value"></b-form-input>
        </b-col>
      </b-form-row>
    </form>
  </b-modal>
</template>

<script>
  import Vue from 'vue'
  import MnemoHub from '@/models/mnemoHub.js'

  Vue.use(MnemoHub)

  export default {

    name: 'PrimitiveBaseDialog',

    props: ['value', 'windowId'],

    data() {
      return {
        tags: [],
        primitiveBaseDialogTitle: null,
        loading: false,
      }
    },

    methods: {

      closePrimitiveBaseDialog() {
        this.$emit('closePrimitiveBaseDialog');
        this.$refs.modal.hide();
      },

      modalShown(e) {

        if (this.value) {
          if (this.value.primitive.mnemoTags.length > 0) {
            const tag = this.value.primitive.mnemoTags[0];
            this.primitiveBaseDialogTitle = tag.primitiveDeviceName;

            const filteredTags = this.value.primitive.mnemoTags.filter(a => a.objectLogicTagId !== null);

            this.tags = filteredTags.map(a => {
              return {
                id: a.objectLogicTagId,
                name: a.objectLogicTagName,
                value: a.mnemoArgumentValue,
                unit: a.unitsMeasureCode
              }
            });
            //console.log(this.value);
            //console.log(this.tags);
          }
        }

      },

      modalOk() {
        //this.loading = true;
        const values = this.tags.map(a => {
          return {
            idTag: a.id,
            timeStamp: new Date().toISOString(),
            value: parseFloat(a.value)
          }
        });
        //console.log(values);
        setTimeout(function () {
          //this.loading = false;
          Vue.prototype.setTagsValues(values);
          //console.log('setTagsValues started');
        }, 10);
      },
    },
  }

</script>

<style scoped>
</style>

