<template>
  <div v-html="mnemonic" class="svg-parser"></div>
</template>

<script>
  export default {

    name: "Parser",

    data() {
      return {

        isParsingStarted: false,

        mnemonic: null,
        clearHtmlMnemoTagValues: false,

        result: {
          mnemoGroups: [],
          mnemoDevices: [],
          primitives: [],

          bindedDevices: [],
          bindedPrimitives: [],
        },

        svgDocument: null,
      }
    },

    updated: function () {
      this.$nextTick(function () {
        this.svgDocument = document.querySelector('[class="svg-parser"] > svg');
        //console.log(this.svgDocument);
        if (this.svgDocument) {
          this.svgDocument.style.width = 'calc(100vw * 0.001)';
          this.svgDocument.style.height = 'calc(100vh * 0.001)'
        }
        if (!this.isParsingStarted) {
          this.isParsingStarted = true;
          //console.log('start parsing');

          this.start();
          this.$emit("update", this.result);
        }
      });
    },

    methods: {

      parse(value, typeParameters) {
        this.mnemonic = value;
        this.isParsingStarted = false;
        this.typeParameters = typeParameters;
      },

      reset() {
        this.mnemonic = null;
      },

      start() {
        if (this.mnemonic === null) {
          return;
        }
        let self = this;
        this.result.mnemoGroups = [];
        this.result.mnemoDevices = [];
        this.result.primitives = [];

        const uniqueGroupCodes = this.getAllMnemoGroupCodes();
        //console.log(uniqueGroupCodes);
        uniqueGroupCodes.forEach(function (mnemoGroupCode, i, arr) {
          const mnemoGroupNameTag = self.svgDocument.querySelector('[id^="' + mnemoGroupCode + '.Name"]');
          //console.log(mnemoGroupNameTag);
          self.result.mnemoGroups.push(
            {
              mnemoGroupCode: mnemoGroupCode,
              mnemoGroupName: mnemoGroupNameTag ? mnemoGroupNameTag.innerHTML : ''
            });
        });

        const uniqueDeviceCodes = this.getAllMnemoDeviceCodes();
        //console.log(uniqueDeviceCodes);
        uniqueDeviceCodes.forEach(function (mnemoDeviceCode, i, arr) {
          const mnemoDeviceNameTag = self.svgDocument.querySelector('[id^="' + mnemoDeviceCode + '.LogicName"]');
          const mnemoDevice = {
            bindingId: i,
            mnemoDeviceCode: mnemoDeviceCode,
            mnemoDeviceText: mnemoDeviceNameTag ? mnemoDeviceNameTag.innerHTML : '',
            mnemoTags: [],
            mnemoDeviceProps: [],
            objectLogicId: null,
            objectLogicName: null,
            OK: false,
          };
          self.getMnemoDeviceTagIds(mnemoDevice);
          self.result.mnemoDevices.push(mnemoDevice);
        });

        //console.log(this.result.mnemoDevices);
        this.result.bindedDevices = JSON.parse(JSON.stringify(this.result.mnemoDevices));
        //console.log(this.result.bindedDevices);

        this.getAllMnemoPrimitives();

        this.result.bindedPrimitives = JSON.parse(JSON.stringify(this.result.primitives));
        //console.log(this.result.bindedPrimitives);
      },

      getMnemoDeviceTagIds(mnemoDevice) {
        //console.log(mnemoDevice);
        const allDeviceTags = this.svgDocument.querySelectorAll('[id*="' + mnemoDevice.mnemoDeviceCode + '."]');
        //console.log(allDeviceTags);
        for (let i = 0; i < allDeviceTags.length; i++) {
          const deviceTag = allDeviceTags[i];
          var mnemoTag = this.parseTag(deviceTag.id, deviceTag.innerHTML);
          if (mnemoTag !== null) mnemoDevice.mnemoTags.push(mnemoTag);

          const splittedId = deviceTag.id.split('.');
          if (splittedId.length === 2) {
            const mnemoProp = {
              objectLogicColumnName: splittedId[1],
              objectLogicPropertyValue: null,
              found: null,
            }
            mnemoDevice.mnemoDeviceProps.push(mnemoProp);
          }
        };
      },

      parseTag(deviceTagId, innerHTML) {
        const splittedId = deviceTagId.split('.');
        //console.log(splittedId);
        if (splittedId.length === 3) {
          if (deviceTagId.indexOf('TVAR') !== -1) {
            const typeParameterId = parseInt(splittedId[1].replace('TVAR', ''));
            const typeParameter = this.typeParameters.find(a => a.id === typeParameterId);
            const mnemoTag = {
              variableName: splittedId[2],
              mnemoVarText: innerHTML,
              typeParametersId: typeParameterId ? typeParameterId : '',
              typeParametersName: typeParameter ? typeParameter.name : '',
              objectLogicTagName: null,
              objectLogicTagId: null,
            }
            return mnemoTag;
          }
        }
        return null;
      },

      getAllMnemoPrimitives() {
        const allPrimitives = this.svgDocument.querySelectorAll('[id*="Primitive."]');
        for (let i = 0; i < allPrimitives.length; i++) {
          const mnemoPrimitive = allPrimitives[i];

          //console.log(mnemoPrimitive.id);
          const notAdditionTag = mnemoPrimitive.id.indexOf('_rect');
          //console.log(notAdditionTag);
          if (notAdditionTag === -1) {

            //console.log(mnemoPrimitive);
            const primitive = {
              name: mnemoPrimitive.id,
              mnemoTags: [],
            };
            //console.log(primitive.dataset.primitive_value1);
            const datasets = Object.keys(mnemoPrimitive.dataset);
            for (let j = 0; j < datasets.length; j++) {
              const key = datasets[j];
              const deviceTagId = mnemoPrimitive.dataset[key];
              //console.log(deviceTagId);
              const splittedId = deviceTagId.split('.');
              if (splittedId.length === 3) {
                var mnemoTag = this.parseTag(deviceTagId, null);
                if (mnemoTag !== null) {
                  mnemoTag.primitiveDeviceCode = splittedId[0];
                  mnemoTag.mnemoArgumentName = key.replace('primitive_', '');
                  mnemoTag.isDeviceBinded = false;
                  primitive.mnemoTags.push(mnemoTag);
                }
              }
            }
            if (this.clearHtmlMnemoTagValues) {
              mnemoPrimitive.style.fill = '#808080';
            }
            this.result.primitives.push(primitive);
          }
        };
      },

      getAllMnemoDeviceCodes() {
        let self = this;
        const allMnemoDevices = this.svgDocument.querySelectorAll('[id*="Device"]');
        const rawDevices = [];
        allMnemoDevices.forEach(function (device, i, arr) {
          const splittedId = device.id.split('.');

          const deviceName = splittedId[0];
          const index = rawDevices.findIndex(a => a === deviceName);
          if (index === -1) rawDevices.push(deviceName);

          if (self.clearHtmlMnemoTagValues) {
            device.innerHTML = '';
            if (device.r) device.style.fill = '#808080';
          }
        });
        return [...rawDevices];
      },

      getAllMnemoGroupCodes() {
        const allGroups = this.svgDocument.querySelectorAll('[id*="Group"]');
        const rawGroups = [];
        allGroups.forEach(function (group, i, arr) {
          const splittedId = group.id.split('.');

          const groupName = splittedId[0];
          const index = rawGroups.findIndex(a => a === groupName);
          if (index === -1) rawGroups.push(groupName);
        });
        return [...rawGroups];
      },
    },

  };
</script>