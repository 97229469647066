<template>
  <div>
    <b-container fluid style="padding:15px; border-radius:5px; background-color:white;">

      <b-row>
        <b-col md="12" class="mt-2">
          <b-button title="Сохранить мнемосхему"
                    variant="primary"
                    @click="saveMnemo">
            <b-spinner small v-show="isLoading" class="mr-1"></b-spinner>
            <i class="fa fa-save"></i>&nbsp;Сохранить
          </b-button>
          <b-button title="Просмотр мнемосхемы"
                    variant="primary"
                    @click="viewMnemo"
                    style="margin-left:10px">
            <i class="fa fa-search"></i>&nbsp;Просмотр мнемосхемы
          </b-button>
        </b-col>
        <input type="file" ref="upload" v-on:change="uploadMnemoFile($event)" style="display:none;" accept=".svg">
      </b-row>


      <b-tabs class="tab-content mt-2" style="max-width:740px;">

        <b-tab class="mnemo-info mt-2" title="Мнемосхема" v-bind:class="{ active: isMnemoInfoTab }">
          <b-row>
            <b-col md="12" class="mt-1">
              <label for="mnemo-name" class="control-label">Название</label>
            </b-col>
            <b-col md="12">
              <b-form-input class="control-input" v-model="mnemoInstance.name" v-bind:class="{ error: !mnemoInstance.name }" id="mnemo-name"></b-form-input>
              <span class="error-message control-label" v-if="!mnemoInstance.name">Поле "Название" не может быть пустым</span>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="mt-3">
              <label for="mnemo-description" class="control-label">Описание</label>
            </b-col>
            <b-col md="12">
              <b-form-textarea class="control-input" v-model="mnemoInstance.description" id="mnemo-description" rows="7"></b-form-textarea>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12" class="mt-2 mb-2">
              <label for="template-name" class="control-label" style="margin-right:10px">Графическая подложка (SVG)</label>
              <b-link v-on:click="downloadMnemoFile()"
                      :title="`Скачать подложку '${mnemoInstance.mnemoName}'`" style="margin-right: 10px; height:17px; display: inline-block; max-width: 180px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis ">
                {{ mnemoInstance.mnemoName }}
              </b-link>
              <b-button title="Выбрать предустановленный шаблон из списка"
                        v-bind:disabled="!mnemoInstance.name"
                        style="margin-right:10px"
                        variant="primary"
                        @click="openTemplatesDialog">Выбрать шаблон</b-button>
              <b-button title="Загрузить графическую подложку для выбранной мнемосхемы"
                        v-bind:disabled="!mnemoInstance.name"
                        variant="primary"
                        @click="$refs.upload.click()">Загрузить</b-button>
            </b-col>
          </b-row>
        </b-tab>

        <b-tab class="config-devices mt-2" title="Конфигурация" v-bind:class="{ active: isConfigDevicesTab }">
          <div v-if="mnemoGroups.length>0">
            <b-table :items="mnemoGroups" :fields="mnemoGroupsFieldsNames" class="table-striped table-borderless" style="margin-left:17px;line-height:40px;">
              <template #cell(name)="data">
                {{data.item.mnemoGroupCode}}
              </template>
              <template #cell(description)="data">
                <b-form-input v-model="data.item.mnemoGroupName" style="margin-left:0px"></b-form-input>
              </template>

            </b-table>
          </div>
          <MnemoConfigView :mnemoDevices="mnemoDevices" :primitives="primitives" />
        </b-tab>

        <b-tab class="binding-devices mt-2" title="Привязка приборов" v-bind:class="{ active: isBindingDevicesTab }">
          <div v-if="bindedDevices.length>0">
            <b-table :items="bindedDevices" :fields="deviceBindingFieldsNames" class="table-striped table-borderless">

              <template #cell(code)="data">
                {{data.item.mnemoDeviceCode}}
              </template>
              <template #cell(name)="data">
                {{data.item.objectLogicName ? data.item.objectLogicName : data.item.mnemoDeviceText}}
              </template>

              <template #cell(buttonprop)="data">
                <b-button variant="primary"
                          v-bind:class="{ bindedDeviceOK: data.item.OK }"
                          v-on:click="openDeviceBindingDialog(data.item)"
                          title="Свойства прибора">
                  <i class="fa fa-gear"></i>
                </b-button>
              </template>
            </b-table>
          </div>
        </b-tab>

        <b-tab class="binding-primitives mt-2" title="Привязка примитивов" v-bind:class="{ active: isBindingPrimitivesTab }">
          <div v-if="bindedPrimitives.length>0">
            <b-table :items="bindedPrimitives" :fields="primitiveBindingFieldsNames" class="table-striped table-borderless">
              <template #cell(name)="data">
                {{data.item.name}}
              </template>

              <template #cell(buttonprop)="data">
                <b-button variant="primary"
                          v-bind:class="{ bindedDeviceOK: data.item.OK }"
                          v-on:click="openPrimitiveBindingDialog(data.item)"
                          title="Свойства примитива">
                  <i class="fa fa-gear"></i>
                </b-button>
              </template>
            </b-table>
          </div>
        </b-tab>

      </b-tabs>

    </b-container>

    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>

    <!--Диалог предварительного просмотра мнемосхемы-->
    <MnemoPreviewModal title="Предварительный просмотр мнемосхемы"
                       v-model="mnemoInstance.mnemoFile"
                       v-on:cancel="closePreviewMnemoDialog"
                       v-on:click="previewDocumentClick" />

    <!--Диалог задания значений параметров тегов и передача значений сервису -->
    <PrimitiveBaseModal :data="primitiveBASEDIALOGdata"
                        @closePrimitiveBaseDialog="primitiveBASEDIALOGdata=null" />

  </div>
</template>

<script>
  import axios from 'axios'
  import Drag from '@/tools/drag.js'
  import PrimitiveBaseModal from '@/views/mnemo/PrimitiveBaseModal'

  import MnemoPreviewModal from '@/views/mnemo/MnemoPreviewModal'
  import MnemoConfigView from '@/views/mnemo/MnemoConfigView'

  export default {

    name: 'MnemonicsEditor',

    props: ['refBinder'],

    components: {
      PrimitiveBaseModal, MnemoPreviewModal, MnemoConfigView
    },

    data() {
      return {
        mnemoGroupsFieldsNames: [
          { key: 'name', label: 'Название' },
          { key: 'description', label: 'Описание' },
        ],
        deviceBindingFieldsNames: [
          { key: 'code', label: 'Идентификатор прибора на мнемосхеме' },
          { key: 'name', label: 'Наименование прибора' },
          { key: 'buttonprop', label: 'Свойства' },
        ],
        primitiveBindingFieldsNames: [
          { key: 'name', label: 'Наименование примитива' },
          { key: 'buttonprop', label: 'Свойства' },
        ],

        isMnemoInfoTab: false,
        isConfigDevicesTab: false,
        isBindingDevicesTab: false,
        isBindingPrimitivesTab: false,

        justifyAlign: 0,
        //dragzoomnode: null,
        connectionOk: false,
        connectionLoss: 0,
        connectionLossTime: null,
        connectionOkTime: null,
        connectionLossTitle: null,
        isLoading: false,

        mnemo: null,

        addMode: false,
        isEmpty: false,
        templatesDialogVisibility: false,
        previewMnemoDialogVisibility: false,
        editedMnemo: null,
        isTemplateSelected: false,
        typeParameters: [
          { id: 1, name: 'Газ' },
          { id: 2, name: 'ЭC' },
          { id: 3, name: 'Тепло' },
          { id: 4, name: 'ХВС' },
          { id: 5, name: 'ГВС' },
          { id: 6, name: 'Датчики' }
        ],

        mnemoGroups: [],            // parsing results
        mnemoDevices: [],
        primitives: [],

        bindedPrimitives: [],       // binding results
        bindedDevices: [],          // binding results

        mnemoInstance: {
          groupId: null,
          templateId: null,
          id: null,
          name: null,
          description: null,
          status: null,
          mnemoFile: null,
        },
        loadedMnemo: null,

        isUpdateEvent: false,
        updatedElement: {},
        primitiveLEDWrappers: [],
        primitiveBASEDIALOGWrappers: [],
        primitiveBASEDIALOGdata: null,
      }
    },

    created() {
      let self = this;

      this.addMode = true;

      axios.interceptors.request.use(
        function (config) {
          const token = self.getCookie('cfg-token');
          if (token) {
            config.headers.Authorization = "Bearer " + token;
          }
          return config;
        },
        function (error) {
          return Promise.reject(error);
        }
      );
    },

    mounted() {
      //console.log('DOM mounted');

      this.$emit("mounted");
    },
    updated: function () {
      this.$nextTick(function () {

        //console.log('Virtual DOM updated');

        if (this.previewMnemoDialogVisibility) {
          const drag = new Drag();
          drag.dragElement("dragdiv");
        }
      })
    },
    methods: {

      updateBindingResults(result) {

        if (!result) return;
        this.mnemoInstance = result.mnemoInstance;

        this.mnemoGroups = JSON.parse(JSON.stringify(result.mnemoGroups));
        this.mnemoDevices = JSON.parse(JSON.stringify(result.mnemoDevices));
        this.primitives = JSON.parse(JSON.stringify(result.primitives));
        this.bindedDevices = JSON.parse(JSON.stringify(result.bindedDevices));
        this.bindedPrimitives = JSON.parse(JSON.stringify(result.bindedPrimitives));

        //console.log('end binding');
        //console.log(result);
        if (this.isLoading) this.$bvModal.msgBoxOk('Мнемосхема успешно сохранена');

        this.isLoading = false;
      },

      previewDocumentClick(event) {

        if (event.target.id.indexOf('Device') !== -1) {
          const split = event.target.id.split('.');
          //console.log(event.target.id);
          const bindedDevice = this.bindedDevices.find(a => a.mnemoDeviceCode === split[0]);
          if (bindedDevice) {
            //this.editMnemo();
            this.refBinder.openDeviceBindingDialog(bindedDevice);
            //this.tabbinding();
          }
        }
        if (event.target.id.indexOf('Primitive.') !== -1) {
          const bindedPrimitive = this.bindedPrimitives.find(a => a.name === event.target.id);
          //console.log(event.target.id);
          if (bindedPrimitive) {
            //this.editMnemo();
            this.refBinder.openPrimitiveBindingDialog(bindedPrimitive);
            //this.tabprimitive();
          }
        }
      },

      openDeviceBindingDialog(value) {
        this.refBinder.openDeviceBindingDialog(value);
      },

      openPrimitiveBindingDialog(value) {
        this.refBinder.openPrimitiveBindingDialog(value);
      },

      getCookie(name) {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
      },

      viewMnemo() {

        this.previewMnemoDialogVisibility = true;
        this.$bvModal.show("mnemoPreviewModal");
      },
      editMnemo() {
        const svg = document.querySelector('[class="svg-preview"]');
        //console.log(svg)
        if (svg) {
          this.editedMnemo = svg.innerHTML;
        }
      },
      addMnemo() {
        this.addMode = true;
        this.isTemplateSelected = true;
        //this.tabconfig();
        //if (this.bindedDevices.length > 0) {
        //    this.onSelectMnemoDevice(this.bindedDevices[0]);
        //};
      },

      saveMnemo() {
        this.isLoading = true;
        this.refBinder.saveMnemo();
      },

      openTemplatesDialog() {
        this.refBinder.openTemplatesDialog();
      },

      selectTemplate(selectedTemplate) {
        this.refBinder.selectTemplate(selectedTemplate);
      },

      uploadMnemoFile(event) {
        if (this.mnemoInstance && this.mnemoInstance.id) {
          this.refBinder.uploadMnemoFile(event, this.mnemoInstance.id);
          this.$refs.upload.value = null;
        }
      },

      downloadMnemoFile() {
        //console.log(mnemoInstanceId);
        //console.log(this.mnemoInstance);
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(new Blob([this.mnemoInstance.mnemoFile]))
        link.download = this.mnemoInstance.mnemoName + '.svg';
        document.body.appendChild(link);
        link.click();
      },

      closePreviewMnemoDialog() {
        this.previewMnemoDialogVisibility = false;
      },

      tabbinding() {
        this.isConfigDevicesTab = false;
        this.isMnemoInfoTab = false;
        this.isBindingDevicesTab = true;
        this.isBindingPrimitivesTab = false;
      },

      tabinfo() {
        this.isConfigDevicesTab = false;
        this.isMnemoInfoTab = true;
        this.isBindingDevicesTab = false;
        this.isBindingPrimitivesTab = false;
      },

      tabconfig() {
        this.isConfigDevicesTab = true;
        this.isMnemoInfoTab = false;
        this.isBindingDevicesTab = false;
        this.isBindingPrimitivesTab = false;
      },

      tabprimitive() {
        this.isConfigDevicesTab = false;
        this.isMnemoInfoTab = false;
        this.isBindingDevicesTab = false;
        this.isBindingPrimitivesTab = true;
      },
    }
  }
</script>

<style scoped>

  .control-label{
    margin-left: 25px;
    font-weight: 700;
  }
  .control-input {
    margin-left: 12px;
    border: 1px solid #dee2e6;
    border-radius: 5px;
  }

  .preview-template {
    margin: 10px auto;
    width: 80vw;
  }

  .error-message {
    font-size: small;
  }

  .bindedDeviceOK {
    background-color: #c0f0c0 !important;
  }

  .justifyAlignCenter {
    justify-content: space-around;
  }

  .justifyAlignLeft {
    justify-content: flex-start;
  }

  .justifyAlignRight {
    justify-content: flex-end;
  }

  #dragdiv {
    position: absolute;
    z-index: 9;
    text-align: center;
  }

  #dragdivheader {
    padding: 10px;
    cursor: move;
    z-index: 10;
  }
</style>
<style>
  .form-control{
    border-radius: unset !important;
  }
  label {
    margin: 0;
  }
  svg [id^="Primitive."]:hover {
    cursor: pointer;
  }
</style>
