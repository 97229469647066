<template>
  <div>
    <input
      type="hidden"
      id="selectedDashboard"
      v-model="currentDashboard.id"
      v-if="currentDashboard"
    />
    <dashboard :id="'dashExample'">
      <dash-layout
        v-for="layout in layouts"
        :key="layout.breakpoint"
        v-bind="layout"
      >
        <dash-item
          v-for="item in layout.items"
          :key="item.id"
          v-bind.sync="item"
          @resizeEnd="onResizeEnd(item.id)"
        >
          <div
            class="content"
            @mouseover="showRemoveButton(item.id)"
            @mouseleave="hideRemoveButton(item.id)"
          >
            <a
              @click="deleteWindow(item)"
              v-if="showWindowTools.indexOf(item.id) > -1"
              style="position: absolute; right: 5px; top: 0px; cursor: pointer; z-index:5000"
            >
              <i class="fa fa-trash"></i>
            </a>
            <WindowContent
              :ref="`window-${item.id}`"
              v-model="item.id"
              v-bind:groupId="groupId"
              v-bind:isReadOnly="isReadOnly"
              v-on:assignMnemoToWindow="showAssignMnemoToWindowModal"
              :chartHeight="chartHeight"
            />
          </div>
        </dash-item>
      </dash-layout>
    </dashboard>
    <div class="control-buttons">
      <b-button
        variant="primary"
        pill
        class="control-button"
        title="Сменить, добавить, изменить или удалить рабочий стол"
        @click="switchDashboard()"
      >
        <i class="fa fa-window-restore"></i>
      </b-button>
      <b-button
        v-if="!isReadOnly"
        variant="primary"
        pill
        class="control-button"
        title="Добавить окно на рабочий стол"
        @click="showAddWindowModal()"
      >
        <i class="fa fa-plus"></i>
      </b-button>
    </div>
    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>
    <DashboardsModalComponent
      v-model="dashboards"
      v-bind:isReadOnly="isReadOnly"
      v-on:selectDashboard="selectDashboard"
    />
    <AddEditWindowModalComponent
      v-model="selectedWindow"
      v-on:addWindow="addWindow"
      v-on:editWindow="editWindow"
    />
    <AssignMnemoToWindowModalComponent
      v-model="assignMnemoContent"
      v-on:assignedMnemoToWindow="setMnemoInstanceToWindow"
    />
  </div>
</template>
<script>
import MnemoService from "@/services/mnemo.service";
import DashboardsModalComponent from "@/views/dashboard/DashboardsModal";
import AddEditWindowModalComponent from "@/views/dashboard/AddEditWindowModal";
import WindowContent from "@/views/dashboard/WindowContent";
import { Dashboard, DashLayout, DashItem } from "vue-responsive-dash";
import Axios from "axios";
import MnemonicsViewer from "@/components/mnemo/MnemonicsViewer";
import AssignMnemoToWindowModalComponent from "@/views/dashboard/AssignMnemoToWindowModal";

export default {
  name: "dashboards",
  components: {
    Dashboard,
    DashLayout,
    DashItem,
    DashboardsModalComponent,
    AddEditWindowModalComponent,
    MnemonicsViewer,
    WindowContent,
    AssignMnemoToWindowModalComponent,
  },
  data() {
    return {
      isLoading: false,
      upHere: false,
      currentDashboard: null,
      dashboards: [],
      selectedWindow: null,
      selectedMnemonic: null,
      layouts: [
        {
          breakpoint: "xxs",
          breakpointWidth: 0,
          numberOfCols: 100,
          items: [],
        },
      ],
      groupId: null,
      assignMnemoContent: null,
      isReadOnly: false,
      chartHeight: null,
      showWindowTools: [],
    };
  },
  methods: {
    switchDashboard() {
      this.$bvModal.show("dashboardsModal");
    },
    selectDashboard(dashboard) {
      this.isLoading = true;
      this.currentDashboard = dashboard;
      this.layouts[0].items = [];
      MnemoService.getWindows(dashboard.id)
        .then((response) => {
          //console.log(response.data);
          this.layouts[0].items = response.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    showAddWindowModal() {
      this.selectedWindow = {
        x: 0,
        y: this.maxY,
        width: 20,
        height: 20,
        isShowTitle: true,
        dashboardId: this.currentDashboard.id,
      };
      this.$bvModal.show("addEditWindowModal");
    },
    addWindow(window) {
      this.layouts[0].items.push(window);
    },
    showEditWindowModal(window) {
      this.selectedWindow = JSON.parse(JSON.stringify(window));
      this.$bvModal.show("addEditWindowModal");
    },
    editWindow(window) {
      this.isLoading = true;
      let editedWindow = this.layouts[0].items.find((x) => x.id == window.id);
      editedWindow.title = window.title;
      editedWindow.isShowTitle = window.isShowTitle;
      this.isLoading = false;
    },
    deleteWindow(window) {
      this.$bvModal
        .msgBoxConfirm(
          "Вы действительно хотите удалить окно '" + window.title + "'?",
          {
            title: "Удаление окна",
            okVariant: "danger",
            okTitle: "Удалить",
            cancelTitle: "Отмена",
            titleTag: "h4",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            MnemoService.deleteWindow(window.id).then(
              (response) => {
                let index = this.layouts[0].items.findIndex(
                  (el) => el.id === window.id
                );
                this.layouts[0].items.splice(index, 1);
                this.$nextTick(() => {
                  this.$bvModal.hide();
                });
              },
              (error) => {}
            );
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    saveWindows(windows) {
      MnemoService.saveWindows(windows)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    setupWindow(window) {
      this.selectedWindow = JSON.parse(JSON.stringify(window));
      this.$bvModal.show("windowSetupModal");
    },
    showAssignMnemoToWindowModal(windowId) {
      this.assignMnemoContent = {
        windowId: windowId,
        groupId: this.groupId,
      };
      this.$bvModal.show("assignMnemoToWindowModal");
    },
    setMnemoInstanceToWindow(value) {
      this.$refs[`window-${value.windowId}`][0].updateMnemoInstance(
        value.mnemoInstanceId
      );
    },
    onResizeEnd(windowId) {
      //console.log(windowId);
      this.$refs[`window-${windowId}`][0].onResizeEnd();
    },
    showRemoveButton(windowId) {
      const index = this.showWindowTools.indexOf(windowId);
      if (index == -1) {
        this.showWindowTools.push(windowId);
      }
    },
    hideRemoveButton(windowId) {
      const index = this.showWindowTools.indexOf(windowId);
      if (index > -1) {
        this.showWindowTools.splice(index, 1);
      }
    },
  },
  watch: {
    layouts: {
      handler: function (val, oldVal) {
        if (!this.isLoading) {
          this.saveWindows(this.layouts[0].items);
        }
      },
      deep: true,
    },
  },
  mounted() {
    //console.log('mounted');
    if (this.$route.query.isReadOnly) {
      this.isReadOnly = this.$route.query.isReadOnly.toLowerCase() === "true";
    }
    this.groupId = this.$route.query.groupId;
    this.isLoading = true;    
    let dashboardId = localStorage.getItem("selectedDashboard");
    Axios.all([MnemoService.getDashboards()])
      .then(
        Axios.spread((dashboards) => {
          this.dashboards = dashboards.data;
          if (this.dashboards.length > 0) {
            if (dashboardId) {
              let dashboard = this.dashboards.find((x) => x.id == dashboardId);
              if (dashboard) {
                this.selectDashboard(dashboard);
              } else {
                this.selectDashboard(this.dashboards[0]);
              }
            } else {
              this.selectDashboard(this.dashboards[0]);
            }
          }
          this.isLoading = false;
        })
      )
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
  computed: {
    maxY() {
      let maxY = 0;
      let bottomY = 0;
      for (let item of this.layouts[0].items) {
        bottomY = item.y + item.height;
        if (bottomY > maxY) {
          maxY = bottomY;
        }
      }
      return maxY;
    },
  },
};
</script>

<style scoped>
.content {
  height: 100%;
  width: 100%;
  border: 2px solid grey;
  background-color: white;
}

.ibox-title h5 {
  font-size: 16px;
}
</style>