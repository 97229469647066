<template>
  <b-modal :id="`selectTagsModal${windowId}`"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           size="xl"
           title="Выбор тега"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @ok="modalOk"
           @cancel="modalCancel"
           @close="modalCancel"
           @shown="modalShown">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">Выбор тега</h4><a style="font-size:26px;line-height:40px;">&nbsp;&nbsp;&nbsp;({{resource.name}})</a>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ ok, cancel }">
      <b-button variant="primary" @click="ok()">Выбрать</b-button>
      <b-button variant="secondary" @click="cancel()">Закрыть</b-button>
    </template>
    <b-container fluid style="height:450px; overflow: auto">
      <b-row style="line-height:40px">
        <b-col md="12">
          <TreeNodeChecked :treeNode="treeNode"
                           :key="refreshTree"
                           @onExpandCollapseNode="onExpandCollapseNode" />
        </b-col>
      </b-row>
    </b-container>
    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>
  </b-modal>
</template>

<script>

  import MnemoService from '@/services/mnemo.service.js'
  import TreeNodeChecked from '@/components/graphic/TreeNodeChecked.vue'

  export default {

    name: "SelectTagsModal",

    components: {
      TreeNodeChecked
    },

    props: ["treeNode", "node", "windowId", "resource"],

    data() {
      return {
        refreshTree: false,
        selectedTags: [],
        isLoading: false,
        hasResourceBeenChanged: false
      }
    },

    methods: {

      refresh() {
        this.refreshTree = !this.refreshTree;
      },

      changed() {
        this.hasResourceBeenChanged = true;
        if (this.treeNode && this.treeNode.children && this.treeNode.children.length > 0) {
          this.treeNode.children[0].children = null;
          this.refreshTree = !this.refreshTree;
        }
        //console.log(this.resource);
      },

      modalOk() {
        //console.log(this.selectedTags);
        this.$emit("select");
      },

      modalShown(e) {
        //console.log(this.selectedTags);
        this.onExpandCollapseNode(this.node);
      },

      modalCancel() {
        this.$emit("cancel");
      },

      lookingForNode(nodes, nodeId) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          if (node.id === nodeId) return node;
          else if (node.children && node.children.length > 0) {
            node = this.lookingForNode(node.children, nodeId);
            if (node) return node;
          }
        }
        return null;
      },

      resetSelectedNode(nodes) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          //console.log(node);
          node.selected = 'not_accepted';
          if (node.children && node.children.length > 0) {
            this.resetSelectedNode(node.children);
          }
        }
      },

      onExpandCollapseDevicesTree(node) {
        let self = this;
        //console.log('Device');
        //console.log(node);
        const group = this.lookingForNode(this.treeNode.children, node.id);
        if (!group.children) {
          this.isLoading = true;
          MnemoService.getDeviceTagsSettings(node.objectLogicId)
            .then(response => {
              //console.log(response.data);
              //console.log(node);
              group.expanded = true;

              const sorted = response.data.sort((a, b) => a.variableName.localeCompare(b.variableName));
              //console.log(sorted);
              group.children = sorted.map(function (a) {
                //console.log(node.id);
                //console.log(a.id);
                return {
                  id: node.id + '_' + a.id,
                  objectLogicTagId: a.id,
                  name: a.variableName,
                  objectLogicId: node.objectLogicId,
                  objectLogicName: node.name,
                  isTag: true,
                  selected: (self.selectedTags.find(s => s.objectLogicTagId === a.id)) ? 'accepted' : 'not_accepted',
                  edName: a.edName,
                  nameParameterHW: a.nameParameterHW,
                  typeParametersId: a.resourceId,
                  timePeriodId: a.timePeriodId,
                  timePeriodName: a.timePeriodName,
                  style: ''
                }
              });
              //console.log(group.children);
              this.isLoading = false;
              this.refreshTree = !this.refreshTree;
            });
        } else {
          group.expanded = !group.expanded;
          this.refreshTree = !this.refreshTree;
        }
      },

      onExpandCollapseNode(node) {
        if (node.isDevice) {
          this.onExpandCollapseDevicesTree(node);
        } else {
          //console.log('Object');
          //console.log(node);
          //console.log(this.treeNode.children);
          const group = this.lookingForNode(this.treeNode.children, node.id);
          if (this.hasResourceBeenChanged) {
            this.hasResourceBeenChanged = false;
            group.children = null;
          }
          //console.log(group);
          if (!group.children) {
            this.isLoading = true;
            MnemoService.getGroupsTreeWithLevel(node.id)
              .then(response => {
                //console.log(response.data);

                const sorted = response.data.sort((a, b) => a.name.localeCompare(b.name));
                //console.log(sorted);
                //console.log(node);
                const filtered = sorted.filter(a => a.id !== node.id);
                //console.log(filtered);

                group.children = filtered;
                group.expanded = true;
                if (group.children.length === 0) {
                  MnemoService.getObjectDevices(node.id, this.resource.id)
                    .then(response => {
                      //console.log(response.data);
                      group.children = response.data.map(function (a) {
                        return {
                          id: node.id + '_' + a.id,
                          objectLogicId: a.id,
                          name: a.fullName,
                          place: a.place,
                          status: a.status,
                          isDevice: true,
                          expanded: false,
                        }
                      });
                      //console.log(group.children);
                      this.isLoading = false;
                      this.refreshTree = !this.refreshTree;
                    });
                } else {
                  for (let i = 0; i < group.children.length; i++) {
                    const child = group.children[i];
                    child.isObject = true;
                  }
                  //console.log(group);
                  this.isLoading = false;
                  this.refreshTree = !this.refreshTree;
                }
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
              });
          } else {
            //console.log(group);
            if (!group.isRoot) group.expanded = !group.expanded;
            this.refreshTree = !this.refreshTree;
            //console.log(node);
          }
        }
      },

    }
  };
</script>
<style>
  .close {
    border: none;
    font-size: 24px;
  }

</style>