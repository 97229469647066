<template>
  <b-button-group :key="refreshGroup">
    <b-button :title="item.name" 
              variant="primary" 
              v-for="item in items" 
              :key="item.id"
              v-bind:class="item.classNameChecked?'active':''"
              v-on:click.prevent="onClick(item)">
      <b-icon class="icon" 
         v-if="item.id!==0" 
         v-bind:class="item.classNameChecked ? item.iconType + ' ' + item.classNameChecked : item.iconType">
      </b-icon>{{item.displayName}}
    </b-button>
  </b-button-group>

</template>

<script>
import MnemoService from '@/services/mnemo.service';

  export default {

    name: 'Resources',

    mounted() {
      MnemoService.getResources()
        .then(response => {
          this.items = this.handleData(response.data);
          //console.log(this.items);
        });
    },
    data() {
      return {
        items: [],
        refreshGroup: false,
      }
    },
    methods: {
      onClick(item) {
        //console.log('click');
        const selectedItem = this.items.find(a => a.id === item.id);
        if (selectedItem.classNameChecked === 'res-select') return;

        this.items.forEach(a => a.classNameChecked = null);
        selectedItem.classNameChecked = 'res-select';
        this.refreshGroup = !this.refreshGroup;

        this.$emit("select", item);
      },

      handleData(resources) {

        //resources.push({ id: 0, shortName: 'Все', name: 'Все ресурсы', webPrefix: 'Все ресурсы', });
        for (let i = 0; i < resources.length; i++) {
          const resource = resources[i];
          this.getIconType(resource);
        }
        const selectedItem = resources.find(a => a.id === 2);
        if (selectedItem) {
          selectedItem.classNameChecked = 'res-select';
          this.$emit("select", selectedItem);
        }

        //console.log(resources);
        return resources.sort((a, b) => a.order - b.order);
      },

      getIconType(item) {
        switch (item.id) {
          case 1:
            item.order = 5;
            item.iconType = 'icon-gas';
            break;
          case 2:
            item.order = 1;
            item.iconType = 'icon-es';
            break;
          case 3:
            item.order = 2;
            item.iconType = 'icon-heat';
            break;
          case 4:
            item.order = 4;
            item.iconType = 'icon-hvs';
            break;
          case 5:
            item.order = 3;
            item.iconType = 'icon-gvs';
            break;
          case 6:
            item.order = 6;
            item.iconType = 'icon-equipment';
            break;
          default:
            item.order = 7;
            item.iconType = 'icon-all';
            break;
        }
      }
    }
  }  

</script>

<style scoped>

  .active {
    background-color: #FFD3BF !important;
    border: 1px solid #DE8B65 !important;
    color: black !important;
  }

  .icon {
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-right: 2px;
  }

  .icon.icon-equipment.res-select {
    background-image: url(~@/assets/images/equipment.svg);
  }

  .icon.icon-equipment {
    background-image: url(~@/assets/images/equipment_grey.svg);
  }

  .icon.icon-es.res-select {
    background-image: url(~@/assets/images/es.svg);
  }

  .icon.icon-es {
    background-image: url(~@/assets/images/es_grey.svg);
  }

  .icon.icon-gas.res-select {
    background-image: url(~@/assets/images/gas.svg);
  }

  .icon.icon-gas {
    background-image: url(~@/assets/images/gas_grey.svg);
  }

  .icon.icon-gvs.res-select {
    background-image: url(~@/assets/images/gvs.svg);
  }

  .icon.icon-gvs {
    background-image: url(~@/assets/images/gvs_grey.svg);
  }

  .icon.icon-hvs.res-select {
    background-image: url(~@/assets/images/hvs.svg);
  }

  .icon.icon-hvs {
    background-image: url(~@/assets/images/hvs_grey.svg);
  }

  .icon.icon-heat.res-select {
    background-image: url(~@/assets/images/teplo.svg);
  }

  .icon.icon-heat {
    background-image: url(~@/assets/images/teplo_grey.svg);
  }

</style>


