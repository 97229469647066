<template>
  <div id="mnemonicsContent">
    <MnemonicsViewer
      ref="viewer"
      v-if="!isEdit"
      v-model="value"
      :windowId="windowId"
      @mounted="viewerMounted"
    />

    <MnemonicsEditor
      ref="editor"
      :refBinder="this.$refs.binder"
      v-if="isEdit"
      v-model="value"
      @mounted="editorMounted"
    />

    <!--Привязка тегов приборов к элементам мнемосхемы -->
    <Binder
      ref="binder"
      :addMode="isEdit"
      :typeParameters="typeParameters"
      @bind="updateBindingResults"
    />

    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import MnemoHub from '@/models/mnemoHub.js'
import MnemonicsViewer from "@/components/mnemo/MnemonicsViewer";
import MnemonicsEditor from "@/components/mnemo/MnemonicsEditor";
import Binder from "@/components/mnemo/Binder";

Vue.use(MnemoHub)
Vue.prototype.startMnemoHub();

export default {
  name: "MnemonicsContent",

  components: {
    MnemonicsViewer,
    MnemonicsEditor,
    Binder,
  },

    props: ["value", // selectedMnemonic.id
      "mode",
      "windowId"],

  data() {
    return {
      typeParameters: [
        { id: 1, name: "Газ" },
        { id: 2, name: "ЭC" },
        { id: 3, name: "Тепло" },
        { id: 4, name: "ХВС" },
        { id: 5, name: "ГВС" },
        { id: 6, name: "Датчики" },
      ],

      isLoading: false,
      result: null,
      isEdit: false,
      needUpdateResults: false,
    };
  },

  beforeDestroy() {
    this.unsubscribe();
  },

  created() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'MnemoConfigurationUpdated') {
        //console.log(state.mnemoId);
        if (state.mnemoId && state.mnemoId === this.value) {
          this.refresh();
        }
      }
    });
  },

  watch: {
    value(newVal, oldVal) {
      this.refresh();
    },
    mode(newVal, oldVal) {
      //console.log(newVal);
      this.needUpdateResults = true;
      this.isEdit = newVal === "edit";
    },
  },
  mounted() {
    if (this.value) {
      this.refresh();
    }
  },
  methods: {
    resize(width, height) {
      //console.log('resize ' + width + ' ' + height);
      //this.$refs[`viewer${this.windowId}`].resize(width, height);
      this.$refs[`viewer`].resize(width, height);
    },

    refresh() {
      //console.log('refresh ' + this.value);
      if (this.value) {
        this.isLoading = true;
        const groupId = this.$route.query.groupId;
        this.$refs.binder.binding(
          this.value,
          this.typeParameters,
          false,
          groupId
        );
      } else {
        this.result.mnemoInstance.mnemoFile = null;
        this.updateBindingResults(this.result);
      }
    },
    updateBindingResults(result) {
      this.result = result;
      //console.log(this.result);
      //console.log(this.mode);

      if (this.mode === "edit") {
        this.$refs.editor.updateBindingResults(result);
      } else {
        this.$refs.viewer.updateBindingResults(result);
      }

      this.$emit("onMounted");
      //console.log(this.mode);
      this.isLoading = false;
    },

    viewerMounted() {
      if (this.needUpdateResults && this.result) {
        //console.log("Viewer DOM mounted. Update binding results");
        this.$refs.viewer.updateBindingResults(this.result);
      }
    },

    editorMounted() {
      if (this.needUpdateResults && this.result) {
        //console.log("Editor DOM mounted. Update binding results");
        this.$refs.editor.updateBindingResults(this.result);
      }
    },
  },
};
</script>
