<template>
  <b-modal id="primitiveSelectTagModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           size="xl"
           title="Выбор тега"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @cancel="modalCancel"
           @close="modalCancel"
           @shown="modalShown">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">Выбор тега</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="secondary" @click="cancel()">Закрыть</b-button>
    </template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <TreeNode :treeNode="treeNode" :key="refreshTree"
                    @onExpandCollapseNode="onExpandCollapseNode"
                    @onSelectNode="onSelectNode" />
        </b-col>
      </b-row>
    </b-container>
    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>
  </b-modal>
</template>

<script>

  import MnemoService from '@/services/mnemo.service.js'
  import TreeNode from '@/components/mnemo/TreeNode.vue'

  export default {

    name: "PrimitiveSelectTagModal",

    components: {
      TreeNode
    },

    props: ["value", "node"],

    data() {
      return {
        treeNode: null,
        refreshTree: false,
        selectedVariable: null,
        isLoading: false,
      }
    },

    methods: {

      modalShown(e) {
        //console.log(this.node);
        this.treeNode = {};
        this.treeNode.children = [];
        this.treeNode.children.push(this.node);

        this.onExpandCollapseNode(this.node);

        this.refreshTree = !this.refreshTree;
      },

      onSelectNode(tag) {
        //console.log(tag);
        //console.log(this.treeNode);
        this.selectedVariable = tag;
        this.resetSelectedNode(this.treeNode.children);
        tag.selected = true;

        this.$emit("select", this.selectedVariable);

        this.$refs.modal.hide();
      },

      modalCancel() {
        this.$emit("cancel");
      },

      lookingForNode(nodes, nodeId) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          if (node.id === nodeId) return node;
          else if (node.children && node.children.length > 0) {
            node = this.lookingForNode(node.children, nodeId);
            if (node) return node;
          }
        }
        return null;
      },

      resetSelectedNode(nodes) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          //console.log(node);
          node.selected = false;
          if (node.children && node.children.length > 0) {
            this.resetSelectedNode(node.children);
          }
        }
      },

      onExpandCollapseDevicesTree(node) {
        //console.log('Device');
        //console.log(node);
        const group = this.lookingForNode(this.treeNode.children, node.id);
        if (!group.children) {
          this.isLoading = true;
          MnemoService.getDeviceTagsSettings(node.objectLogicId)
            .then(response => {
              //console.log(response.data);
              //console.log(node);
              group.expanded = true;

              const sorted = response.data.sort((a, b) => a.variableName.localeCompare(b.variableName));

              group.children = sorted.map(function (a) {
                return {
                  id: node.id + '_' + a.id,
                  objectLogicTagId: a.id,
                  name: a.variableName,
                  objectLogicId: node.objectLogicId,
                  objectLogicName: node.name,
                  isDevice: true,
                  isTag: true,
                  selected: false,
                }
              });
              //console.log(group.children);
              this.isLoading = false;
              this.refreshTree = !this.refreshTree;
            });
        } else {
          group.expanded = !group.expanded;
          this.refreshTree = !this.refreshTree;
        }
      },

      onExpandCollapseNode(node) {
        if (node.isDevice) {
          this.onExpandCollapseDevicesTree(node);
        } else {
          //console.log('Object');
          //console.log(node);
          //console.log(this.treeNode.children);
          const group = this.lookingForNode(this.treeNode.children, node.id);
          //console.log(group);
          if (!group.children) {
            this.isLoading = true;
            MnemoService.getGroupsTreeWithLevel(node.id)
              .then(response => {
                //console.log(response.data);

                const sorted = response.data.sort((a, b) => a.name.localeCompare(b.name));
                const filtered = sorted.filter(a => a.id !== node.id);

                group.children = filtered;
                group.expanded = true;
                if (group.children.length === 0) {
                  MnemoService.getObjectDevices(node.id, null)
                    .then(response => {
                      group.children = response.data.map(function (a) {
                        return {
                          id: node.id + '_' + a.id,
                          objectLogicId: a.id,
                          name: a.fullName,
                          isDevice: true,
                          expanded: false,
                        }
                      });
                      //console.log(group.children);
                      this.isLoading = false;
                      this.refreshTree = !this.refreshTree;
                    });
                } else {
                  //console.log(group);
                  this.isLoading = false;
                  this.refreshTree = !this.refreshTree;
                }
              })
              .catch(error => {
                console.log(error);
                this.isLoading = false;
              });
          } else {
            group.expanded = !group.expanded;
            this.refreshTree = !this.refreshTree;
            //console.log(node);
          }
        }
      },

      selectVariableFromRoot() {
        if (this.treeNode === null) {
          this.isLoading = true;
          const groupId = 1; // this.mnemoInstance.groupId;
          MnemoService.getGroupsTreeWithLevel(groupId)
            .then(response => {

              const sorted = response.data.sort((a, b) => a.name.localeCompare(b.name));
              const filtered = sorted.filter(a => a.id !== groupId);

              this.treeNode = {
                id: groupId,
                children: _.reject(_.sortBy(response.data, 'name'), a => a.id === groupId),
                expanded: true,
              };
              if (this.treeNode.children.length === 0) {
                MnemoService.getObjectDevices(groupId, null)
                  .then(response => {
                    this.treeNode.children = response.data.map(function (a) {
                      return {
                        id: groupId + '_' + a.id,
                        objectLogicId: a.id,
                        name: a.fullName,
                        isDevice: true,
                        expanded: false,
                      }
                    });
                    //console.log(group.children);
                    this.isLoading = false;
                    this.refreshTree = !this.refreshTree;
                    this.primitiveSelectTagDialog = true;
                  });
              } else {
                //console.log(group);
                this.isLoading = false;
                this.refreshTree = !this.refreshTree;
                this.primitiveSelectTagDialog = true;
              }
            })
            .catch(error => {
              console.log(error);
              this.isLoading = false;
            });
        } else {
          //console.log(this.treeNode);
          this.refreshTree = !this.refreshTree;
          this.primitiveSelectTagDialog = true;
        }
      },

    }
  };
</script>
