<template>
  <b-modal id="mnemoPreviewModal"
           :no-close-on-backdrop="true"
           ref="modal"
           title-tag="h4"
           size="xl"
           :title="modalTitle"
           ok-title="ОК"
           cancel-title="Отмена"
           modal-class="inmodal"
           footer-class="p-2"
           @cancel="modalCancel"
           @close="modalCancel">
    <template v-slot:modal-header="{ cancel }">
      <h4 class="modal-title">{{modalTitle}}</h4>
      <b-button variant="primary" style="margin-left: auto;"
                class="modal-close"
                v-on:click="cancel()"
                title="Закрыть">
        <i class="fa fa-close"></i>
      </b-button>
    </template>
    <template v-slot:modal-footer="{ cancel }">
      <b-button variant="primary" @click="cancel()">Отмена</b-button>
    </template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <div v-html="value" @click="click($event)"></div>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>

  export default {
  name: "MnemoPreviewModal",
    props: ["value", "title"],
    methods: {
      modalCancel() {
        this.$emit("cancel");
      },
      click(event) {
        this.$emit("click", event);
      }
    },
    computed: {
      modalTitle: function () {
        return this.title ? this.title : "";
      },
    },
  };
</script>