import axios from "axios";

class MnemoService {

    getDashboards() {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Dashboard/GetDashboards`);
    }

    saveDashboard(dashboard) {
        if (dashboard.id)
            return axios.put(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Dashboard/SaveDashboard`, dashboard);
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Dashboard/CreateDashboard`, dashboard);
    }

    deleteDashboard(dashboardId) {
        return axios.delete(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Dashboard/DeleteDashboard?id=${dashboardId}`);
    }

    getWindows(dashboardId) {
        return axios.get(`${process.env.VUE_APP_TopLevelAPIPath}/api/Dashboard/GetWindows?dashboardId=${dashboardId}`);
    }

    saveWindow(widget) {
        if (widget.id)
            return this.saveWindows([widget]);
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Dashboard/CreateWindow`, widget);
    }

    saveWindows(widgets) {
        return axios.put(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Dashboard/SaveWindows`, widgets);
    }

    deleteWindow(widgetId) {
        return axios.delete(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Dashboard/DeleteWindow?id=${widgetId}`);
    }

    getMnemoInstanceId(windowId, groupId) {
        return axios.get(`${process.env.VUE_APP_TopLevelAPIPath}/api/Dashboard/GetMnemoInstanceId?windowId=${windowId}&groupId=${groupId}`);
    }

    assignMnemoToWindow(data) {
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Dashboard/AssignMnemoToWindow`, data);
    }

    getMnemonics(groupId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/GetMnemosByGroupId?groupId=` + groupId);
    }

    getMnemo(mnemoInstanceId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/GetMnemo?mnemoInstanceId=` + mnemoInstanceId);
    }

    saveMnemonic(data) {
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/addEditMnemo`, data);
    }

    deleteMnemonic(mnemoInstanceId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/RemoveMnemo?mnemoInstanceId=` + mnemoInstanceId);
    }

    updateMnemoDevice(data) {
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/UpdateMnemoDevice`, data);
    }

    updateMnemoPrimitive(data) {
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/UpdateMnemoPrimitive`, data);
    }

    primitivesBinding(data) {
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/PrimitivesBinding`, data);
    }

    updateTemplate(fileName) {
        axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/UpdateTemplate?fileName=` + fileName);
    }

    saveMnemoFile(data) {
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/SaveMnemoFile`, data);
    }

    getMnemoTemplateById(templateId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/GetMnemoTemplateById?templateId=` + templateId);
    }

    getMnemoTemplates() {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/GetMnemoTemplates`);
    }

    getUnitsMeasures() {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Mnemo/GetUnitsMeasures`);
    }

    getObjectDevices(groupId, resourceId) {
      return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Objects/ObjectDevices?groupId=${groupId}&resourceId=${resourceId !== null ? resourceId : ''}`);
    }

    getGroupsTreeWithLevel(groupId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Nsi/GetGroupsTreeWithLevel?groupId=` + groupId + '&countLevels=2');
    }

    getGroupsTree() {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Nsi/GetGroupsTree`);
    }

    getDeviceTagsSettings(deviceId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Objects/GetDeviceTagsSettings?deviceId=` + deviceId);
    }

    deviceInfo(deviceId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Objects/DeviceInfo?deviceId=` + deviceId);
    }

    getCurrentValue(tagId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Objects/GetCurrentValue?tagId=` + tagId);
    }

    getGraphicByWindowId(windowId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Graph/GetGraphicByWindowId?windowId=` + windowId);
    }

    getGraphic(mnemoInstanceId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Graph/GetGraphic?mnemoInstanceId=` + mnemoInstanceId);
    }

    saveGraphic(data) {
        return axios.post(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Graph/AddEditGraphic`, data);
    }

    deleteGraphic(mnemoInstanceId) {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Graph/RemoveGraphic?mnemoInstanceId=` + mnemoInstanceId);
    }

    getTimePeriods() {
        return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Graph/GetTimePeriods`);
    }

    getResources() {
      return axios.get(`${process.env.VUE_APP_WTIIOTAPIPath}/api/Graph/GetTypeParameters`);
    }

}
export default new MnemoService();
