import Vue from 'vue'
import VueRouter from 'vue-router'
import Dashboards from '../views/dashboard/Dashboard.vue'
import Mnemonics from '../views/mnemo/Mnemonics.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/dashboards',
    name: 'dashboards',
    component: Dashboards,
}, {
    path: '/mnemonics',
    name: 'mnemonics',
    component: Mnemonics,
}]

const router = new VueRouter({
    routes
})

export default router