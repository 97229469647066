<template>
  <div id="app">
    <router-view />

    <!-- <dashboard v-if="!isMnemo" :id="'dashExample'">
      <dash-layout v-for="layout in layouts"
                   :key="layout.breakpoint"
                   v-bind="layout">
        <dash-item v-for="item in layout.items"
                   :key="item.id"
                   v-bind.sync="item">
          <div class="content">
            <div class="ibox float-e-margins">
              <div class="ibox-title">
                <h5>{{ item.title }}</h5>
                <div class="ibox-tools">
                  <a @click="showEditWindowModal(item)">
                    <i class="fa fa-pencil"></i>
                  </a>
                  <a @click="setupWindow(item)">
                    <i class="fa fa-gear"></i>
                  </a>
                  <a @click="deleteWindow(item)">
                    <i class="fa fa-times"></i>
                  </a>
                </div>
              </div>
              <div class="ibox-content text-center">
                <b-button variant="primary" @click="setupWindow(item)">Настроить</b-button>
              </div>
            </div>
          </div>
        </dash-item>
      </dash-layout>
    </dashboard>
    <Mnemonics v-if="isMnemo" v-model="selectedMnemonic.id" />
    <div id="small-chat">
      <b-button variant="primary"
                pill
                class="switch-dashboard"
                @click="selectMnemo()">
        <i class="fa fa-gear"></i>
      </b-button>
      <b-button variant="primary"
                pill
                class="switch-dashboard"
                @click="switchDashboard()">
        <i class="fa fa-window-restore"></i>
      </b-button>
      <b-button v-if="currentDashboard != null"
                variant="primary"
                pill
                class="add-window"
                @click="showAddWindowModal()">
        <i class="fa fa-plus"></i>
      </b-button>
    </div>
    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>
    <DashboardsModalComponent v-model="dashboards"
                              v-on:selectDashboard="selectDashboard" />
    <AddEditWindowModalComponent v-model="selectedWindow"
                                 v-on:addWindow="addWindow"
                                 v-on:editWindow="editWindow" />
    <MnemonicsModal v-model="mnemonics"
                    v-on:selectMnemonic="selectMnemonic" /> -->
  </div>
</template>

<script>
import TopLevelService from "@/services/toplevel.service";
import MnemoService from "@/services/mnemo.service";
import Axios from "axios";

export default {
  name: "App",
  components: {},
  data() {
    return {
      isWidgetSetup: false,
      isMnemo: false,
      mnemonics: [],
      selectedMnemonic: null,
    };
  },
  methods: {
    selectMnemo() {
      this.$bvModal.show("mnemonicsModal");
    },
    selectMnemonic(mnemonic) {
      this.selectedMnemonic = mnemonic;
      this.isMnemo = true;
    },
    getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(";").shift();
    },
  },
  created() {
    let urlParams = new URLSearchParams(window.location.hash.split('?')[1]);  
    let token = null;

    if (urlParams.has("token")) {
      token = urlParams.get("token");
    }
    Axios.interceptors.request.use(
      function (config) {       
        if (token) {
          config.headers.Authorization = "Bearer " + token;
        }
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
    );
  },
};
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
}
</style>
