<template>

  <div>
    <!--Компонент svg парсер -->
    <Parser ref="parser"
            @update="updateParserResults" />

    <!--Диалог свойств примитива -->
    <PrimitiveBindingModal :mnemoInstanceId="result.mnemoInstance.id"
                           :bindedDevices="result.bindedDevices"
                           v-model="selectedMnemoPrimitive"
                           @update="updatePrimitiveBinding" />

    <!--Диалог свойств прибора -->
    <DeviceBindingModal :objectDevices="result.objectDevices"
                        :unitsMeasures="result.unitsMeasures"
                        v-model="selectedMnemoDevice"
                        @update="updateDeviceBinding" />

    <!--Окно выбора шаблона-->
    <TemplateSelectModal @select="selectTemplate" />

  </div>

</template>

<script>
  import axios from 'axios'
  import MnemoService from '@/services/mnemo.service.js'
  import Parser from '@/components/mnemo/Parser'
  import DeviceBindingModal from '@/views/mnemo/DeviceBindingModal'
  import PrimitiveBindingModal from '@/views/mnemo/PrimitiveBindingModal'
  import TemplateSelectModal from '@/views/mnemo/TemplateSelectModal'

  export default {

    name: "Binder",

    components: {
      Parser, DeviceBindingModal, PrimitiveBindingModal, TemplateSelectModal
    },

    data() {
      return {
        loadedMnemo: null,

        result: {
          mnemoInstance: {
            groupId: null,
            templateId: null,
            id: null,
            name: null,
            description: null,
            status: null,
            mnemoFile: null,
          },
          mnemoGroups: [],
          mnemoDevices: [],
          primitives: [],
          bindedDevices: [],
          bindedPrimitives: [],
          objectDevices: [],
          unitsMeasures: [],
        },
        selectedMnemoPrimitive: null,
        selectedMnemoDevice: null,

        typeParameters: [],
        isEditMode: false,

        groupId: null,
      }
    },

    methods: {

      binding(mnemoInstanceId, typeParameters, isEditMode, groupId) {

        this.groupId = groupId;
        //console.log('start binding, groupId:' + groupId);

        if (this.result.mnemoInstance.templateId === null || mnemoInstanceId !== this.result.mnemoInstance.id) {
          this.result.mnemoGroups = [];
          this.result.mnemoDevices = [];
          this.result.primitives = [];
          this.result.bindedDevices = [];
          this.result.bindedPrimitives = [];
          this.result.objectDevices = [];
          this.isEditMode = isEditMode;
          this.typeParameters = typeParameters;
          this.getMnemo(mnemoInstanceId, groupId);
        } else {
          this.$emit("bind", this.result);
        }
      },

      updateParserResults(result) {

        if (!result) {
          this.$emit("bind", this.result);
          return;
        }

        this.result.mnemoGroups = result.mnemoGroups;
        this.result.mnemoDevices = result.mnemoDevices;
        this.result.primitives = result.primitives;
        this.result.bindedDevices = result.bindedDevices;
        this.result.bindedPrimitives = result.bindedPrimitives;

        //console.log('end parsing');

        if (this.result.mnemoInstance !== null) {

          this.bindingOnLoadingMnemo();                             // биндинг
          this.$refs.parser.reset();
          //console.log(this.result);
          this.$emit("bind", this.result);
        }
      },

      openTemplatesDialog() {
        this.$bvModal.show("templateSelectModal");
      },

      getMnemo(mnemoInstanceId) {

        this.loading = true;
        //console.log('try get mnemoInstanceId: ' + mnemoInstanceId);
        axios
          .all([
            MnemoService.getMnemo(mnemoInstanceId),
            MnemoService.getObjectDevices(this.groupId, null),
            MnemoService.getUnitsMeasures()
          ])
          .then(
            axios.spread((mnemo, objectDevices, unitsMeasures) => {

              this.result.unitsMeasures = unitsMeasures.data;
              //console.log(this.result.unitsMeasures);

              this.result.objectDevices = [];
              for (let i = 0; i < objectDevices.data.length; i++) {
                const objectDevice = objectDevices.data[i];
                if (objectDevice.statusId !== 4) {
                  const alreadyExists = this.result.objectDevices.find(a => a.id === objectDevice.id);
                  if (!alreadyExists) {
                    this.result.objectDevices.push({ id: objectDevice.id, name: objectDevice.fullName });
                  }
                }
              }
              //this.result.objectDevices = objectDevices.data.map(function (a) { return { id: a.id, name: a.fullName } });
              //console.log(objectDevices);
              //console.log(this.result.objectDevices);
              //console.log(this.result.mnemoInstance);
              this.result.mnemoInstance = mnemo.data;
              if (this.result.mnemoInstance) {
                //console.log('mnemoInstanceId: ' + mnemoInstanceId + ', groupId:' + this.groupId + ' loaded');
                //console.log(this.result.mnemoInstance);

                if (this.result.mnemoInstance.mnemoFile) {
                  this.$refs.parser.parse(this.result.mnemoInstance.mnemoFile, this.typeParameters);
                }
                else {
                  this.$emit("bind", this.result);
                }
              }

              this.loading = false;
            }))
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      },

      selectTemplate(selectedTemplate) {

        this.result.mnemoInstance.templateId = selectedTemplate.id;
        this.result.mnemoInstance.mnemoName = selectedTemplate.name
        this.result.mnemoInstance.mnemoFile = selectedTemplate.file;

        this.$refs.parser.parse(this.result.mnemoInstance.mnemoFile, this.typeParameters);
      },

      uploadMnemoFile(event, mnemoInstanceId) {

        if (event && event.target && event.target.files && event.target.files.length > 0) {
          this.readFile(event.target.files[0], mnemoInstanceId);
        }
      },

      readFile(file, mnemoInstanceId) {

        const reader = new FileReader();
        reader.onload = e => {
          this.loading = true;

          const template = {
            id: mnemoInstanceId,
            name: null,
            description: null,
            fileSize: file.size,
            fileName: file.name,
            file: e.target.result
          };

          MnemoService.saveMnemoFile(template)
            .then((response) => {
              //console.log(response.data);
              this.result.mnemoInstance.mnemoName = response.data.fileName;
              this.result.mnemoInstance.mnemoFile = response.data.file;

              this.$emit("bind", this.result);
              this.loading = false;

              this.$bvModal.msgBoxOk('Подложка успешно сохранена');
            })
            .catch(error => {
              console.log(error);
              this.loading = false;
            })
        };
        reader.readAsText(file);
      },

      saveMnemo() {

        this.loading = true;
        //this.result.mnemoInstance.mnemoFile = this.editedMnemo;
        this.result.mnemoInstance.mnemoGroups = this.result.mnemoGroups;
        //console.log(this.editedMnemo)
        MnemoService.saveMnemonic(this.result.mnemoInstance)
          .then(mnemo => {
            //console.log(mnemo.data);

            this.result.mnemoInstance = mnemo.data;
            if (this.result.mnemoInstance) {
              //console.log('mnemoInstanceId: ' + mnemoInstanceId + ', groupId:' + this.groupId + ' loaded');
              //console.log(this.result.mnemoInstance);

              //if (this.result.mnemoInstance.mnemoFile) {
              //  this.$refs.parser.parse(this.result.mnemoInstance.mnemoFile, this.typeParameters);
              //}
              //else {
                this.$emit("bind", this.result);
              //}
            }

            this.loading = false;
          })
          .catch(error => {
            console.log(error);
            this.loading = false;
          })
      },

      bindingOnLoadingMnemo() {

        for (let i = 0; i < this.result.bindedDevices.length; i++) {
          const bindedDevice = this.result.bindedDevices[i];
          const mnemoDevice = this.result.mnemoInstance.mnemoDevices.find(a => a.mnemoDeviceCode === bindedDevice.mnemoDeviceCode);
          if (mnemoDevice) {
            bindedDevice.objectLogicId = mnemoDevice.objectLogicId;
            bindedDevice.objectLogicName = mnemoDevice.objectLogicName;
            for (let j = 0; j < bindedDevice.mnemoTags.length; j++) {
              const bindedDeviceTag = bindedDevice.mnemoTags[j];
              const mnemoTag = mnemoDevice.mnemoTags.find(a => a.variableName === bindedDeviceTag.variableName);
              if (mnemoTag) {
                bindedDeviceTag.id = mnemoTag.id;
                bindedDeviceTag.objectLogicTagId = mnemoTag.objectLogicTagId;
                bindedDeviceTag.objectLogicTagName = mnemoTag.objectLogicTagName;
                bindedDeviceTag.TypeParametersName = mnemoTag.TypeParametersName;
                bindedDeviceTag.unitsMeasureId = mnemoTag.unitsMeasureId;
                bindedDeviceTag.unitsMeasureCode = mnemoTag.unitsMeasureCode;
                bindedDeviceTag.decimalSymbols = mnemoTag.decimalSymbols;
              }
            }
            const isThereNotBindedTag = bindedDevice.mnemoTags.find(a => a.objectLogicTagId === null);
            bindedDevice.OK = !isThereNotBindedTag ? true : undefined;

            const validObjectLogicProperties = Object.keys(mnemoDevice.deviceInfo);
            bindedDevice.mnemoDeviceProps.forEach(function (property, i, arr) {
              const propertyName = property.objectLogicColumnName[0].toLowerCase() + property.objectLogicColumnName.slice(1)
              if (validObjectLogicProperties.find(a => a === propertyName)) {
                //console.log(propertyName);
                //console.log(mnemoDevice.deviceInfo[propertyName]);
                property.found = property.objectLogicColumnName;
                property.objectLogicPropertyValue = mnemoDevice.deviceInfo[propertyName];
              }
            });
          }
        };

        for (let i = 0; i < this.result.mnemoGroups.length; i++) {
          const mnemoGroup = this.result.mnemoGroups[i];
          const loadedGroup = this.result.mnemoInstance.mnemoGroups.find(a => a.mnemoGroupCode === mnemoGroup.mnemoGroupCode);
          if (loadedGroup) {
            mnemoGroup.mnemoGroupName = loadedGroup.mnemoGroupName;
          }
        }

        for (let i = 0; i < this.result.bindedPrimitives.length; i++) {
          const bindedPrimitive = this.result.bindedPrimitives[i];
          const mnemoPrimitive = this.result.mnemoInstance.mnemoPrimitives.find(a => a.name === bindedPrimitive.name);
          if (mnemoPrimitive) {
            bindedPrimitive.id = mnemoPrimitive.id;
            bindedPrimitive.mnemoInstanceId = mnemoPrimitive.mnemoInstanceId;
            bindedPrimitive.primitiveLibraryId = mnemoPrimitive.primitiveLibraryId;
            bindedPrimitive.script = mnemoPrimitive.script;
            bindedPrimitive.svg = mnemoPrimitive.svg;
            bindedPrimitive.type = mnemoPrimitive.type;
            bindedPrimitive.svgComment = mnemoPrimitive.svgComment;
            bindedPrimitive.jsClass = mnemoPrimitive.jsClass;
            bindedPrimitive.jsClassComments = mnemoPrimitive.jsClassComments;
            for (let j = 0; j < bindedPrimitive.mnemoTags.length; j++) {
              const bindedPrimitiveTag = bindedPrimitive.mnemoTags[j];
              bindedPrimitiveTag.isDeviceBinded = true;
              const mnemoTag = mnemoPrimitive.mnemoTags.find(a => a.primitiveDeviceCode === bindedPrimitiveTag.primitiveDeviceCode
                && a.variableName === bindedPrimitiveTag.variableName);
              if (mnemoTag) {
                bindedPrimitiveTag.id = mnemoTag.id;
                bindedPrimitiveTag.objectLogicDeviceBindingId = mnemoTag.objectLogicDeviceBindingId;
                bindedPrimitiveTag.objectLogicTagId = mnemoTag.objectLogicTagId;
                bindedPrimitiveTag.objectLogicTagName = mnemoTag.objectLogicTagName;
                bindedPrimitiveTag.primitiveDeviceName = mnemoTag.primitiveDeviceName;
                bindedPrimitiveTag.unitsMeasureId = mnemoTag.unitsMeasureId;
                bindedPrimitiveTag.unitsMeasureCode = mnemoTag.unitsMeasureCode;
              }
            }
            bindedPrimitive.mnemoTags.forEach(function (bindedPrimitiveTag, i, arr) {
              bindedPrimitiveTag.OK = bindedPrimitiveTag.objectLogicTagId !== null ? true : undefined;
            });
            const isThereNotBindedTag = bindedPrimitive.mnemoTags.find(a => a.objectLogicTagId === null);
            bindedPrimitive.OK = !isThereNotBindedTag ? true : undefined;

            //console.log(bindedPrimitive);
          }
        }
        //console.log(this.bindedPrimitives);
      },

      openPrimitiveBindingDialog(value) {

        //console.log(value);
        if (value) {
          this.selectedMnemoPrimitive = JSON.parse(JSON.stringify(value));
          this.selectedMnemoPrimitive.mnemoInstanceId = this.result.mnemoInstance.id;
        }
        else {
          this.selectedMnemoPrimitive = null;
        }

        this.$bvModal.show("primitiveBindingModal");
      },

      openDeviceBindingDialog(value) {

        //console.log(value);
        if (value) {
          this.selectedMnemoDevice = JSON.parse(JSON.stringify(value));
          this.selectedMnemoDevice.mnemoInstanceId = this.result.mnemoInstance.id;
        }
        else {
          this.selectedMnemoDevice = null;
        }
        this.$bvModal.show("deviceBindingModal");
      },

      updatePrimitiveBinding(value) {

        //console.log('updatePrimitiveBinding');
        //console.log(value);
        if (value) {
          const bindedPrimitive = this.result.bindedPrimitives.find(a => a.name === value.name);
          if (bindedPrimitive) {
            //console.log(bindedPrimitive);
            bindedPrimitive.script = value.script;
            for (let j = 0; j < bindedPrimitive.mnemoTags.length; j++) {
              const bindedPrimitiveTag = bindedPrimitive.mnemoTags[j];
              const valueTag = value.mnemoTags.find(a => a.primitiveDeviceCode === bindedPrimitiveTag.primitiveDeviceCode
                && a.variableName === bindedPrimitiveTag.variableName);
              if (valueTag) {
                bindedPrimitiveTag.id = valueTag.id;
                bindedPrimitiveTag.objectLogicDeviceBindingId = valueTag.objectLogicDeviceBindingId;
                bindedPrimitiveTag.objectLogicTagId = valueTag.objectLogicTagId;
                bindedPrimitiveTag.objectLogicTagName = valueTag.objectLogicTagName;
                bindedPrimitiveTag.primitiveDeviceName = valueTag.primitiveDeviceName;
                bindedPrimitiveTag.unitsMeasureId = valueTag.unitsMeasureId;
                bindedPrimitiveTag.unitsMeasureCode = valueTag.unitsMeasureCode;
              }
              else {
                bindedPrimitiveTag.id = null;
                bindedPrimitiveTag.objectLogicDeviceBindingId = null;
                bindedPrimitiveTag.objectLogicTagId = null;
                bindedPrimitiveTag.objectLogicTagName = null;
                bindedPrimitiveTag.primitiveDeviceName = null;
                bindedPrimitiveTag.unitsMeasureId = null;
                bindedPrimitiveTag.unitsMeasureCode = null;
              }
            }
            bindedPrimitive.mnemoTags.forEach(function (bindedPrimitiveTag, i, arr) {
              bindedPrimitiveTag.OK = bindedPrimitiveTag.objectLogicTagId !== null ? true : undefined;
            });
            const isThereNotBindedTag = bindedPrimitive.mnemoTags.find(a => a.objectLogicTagId === null);
            bindedPrimitive.OK = !isThereNotBindedTag ? true : undefined;

            this.selectedMnemoPrimitive = JSON.parse(JSON.stringify(bindedPrimitive));
            this.selectedMnemoPrimitive.mnemoInstanceId = this.result.mnemoInstance.id;
          }
          this.$emit("bind", this.result);
        }
      },

      updateDeviceBinding(value) {

        //console.log('updateDeviceBinding');
        //console.log(value);
        if (value) {
          const bindedDevice = this.result.bindedDevices.find(a => a.mnemoDeviceCode === value.mnemoDeviceCode);
          if (bindedDevice) {
            bindedDevice.objectLogicId = value.objectLogicId;
            bindedDevice.objectLogicName = value.objectLogicName;
            for (let j = 0; j < bindedDevice.mnemoTags.length; j++) {
              const bindedDeviceTag = bindedDevice.mnemoTags[j];
              const valueTag = value.mnemoTags.find(a => a.variableName === bindedDeviceTag.variableName);
              if (valueTag) {
                bindedDeviceTag.id = valueTag.id;
                bindedDeviceTag.objectLogicTagId = valueTag.objectLogicTagId;
                bindedDeviceTag.objectLogicTagName = valueTag.objectLogicTagName;
                bindedDeviceTag.TypeParametersName = valueTag.TypeParametersName;
                bindedDeviceTag.unitsMeasureId = valueTag.unitsMeasureId;
                bindedDeviceTag.unitsMeasureCode = valueTag.unitsMeasureCode;
                bindedDeviceTag.decimalSymbols = valueTag.decimalSymbols;
              }
              else {
                bindedDeviceTag.id = null;
                bindedDeviceTag.objectLogicTagId = null;
                bindedDeviceTag.objectLogicTagName = null;
                bindedDeviceTag.TypeParametersName = null;
                bindedDeviceTag.unitsMeasureId = null;
                bindedDeviceTag.unitsMeasureCode = null;
                bindedDeviceTag.decimalSymbols = null;
              }
            }
            const isThereNotBindedTag = bindedDevice.mnemoTags.find(a => a.objectLogicTagId === null);
            bindedDevice.OK = !isThereNotBindedTag ? true : undefined;

            if (value.deviceInfo) {
              const validObjectLogicProperties = Object.keys(value.deviceInfo);
              bindedDevice.mnemoDeviceProps.forEach(function (property, i, arr) {
                const propertyName = property.objectLogicColumnName[0].toLowerCase() + property.objectLogicColumnName.slice(1)
                if (validObjectLogicProperties.find(a => a === propertyName)) {
                  //console.log(propertyName);
                  //console.log(value.deviceInfo[propertyName]);
                  property.found = property.objectLogicColumnName;
                  property.objectLogicPropertyValue = value.deviceInfo[propertyName];
                } else {
                  property.found = null;
                  property.objectLogicPropertyValue = null;
                }
              });
            }

            this.selectedMnemoDevice = JSON.parse(JSON.stringify(bindedDevice));
            this.selectedMnemoDevice.mnemoInstanceId = this.result.mnemoInstance.id;
          }
          //console.log(this.result);
          this.$emit("bind", this.result);
        }
      },
    },
  };
</script>
