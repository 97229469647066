<template>
  <div role="group" class="group-tree" style="margin-left: 10px; font-size:15px;">
    <div role="treeitem" class="group-tree-item" aria-expanded="false" v-for="child in treeNode.children" :key="child.id">
      <b-container fluid v-if="child.isTag">
        <b-row style="white-space:nowrap;">
          <b-col md="1">
            <b-form-checkbox v-model="child.selected"
                             class="tree-node-checkbox"
                             value="accepted"
                             unchecked-value="not_accepted">
            </b-form-checkbox>
          </b-col>
          <b-col md="6">{{child.name}} ({{child.timePeriodName}})</b-col>
          <b-col md="2" style="overflow:hidden" :title="child.edName">{{child.edName}}</b-col>
          <b-col md="2">{{child.nameParameterHW}}</b-col>
        </b-row>
      </b-container>
      <b-container fluid v-else-if="child.isDevice">
        <b-row>
          <b-col md="1">
            <a v-if="child.name" href="#" v-on:click.prevent="onExpandCollapseNode(child)" style="margin-right: 5px">
              <i v-if="child.expanded" class="fa fa-minus"></i>
              <i v-else class="fa fa-plus"></i>
            </a>
          </b-col>
          <b-col md="4">{{child.name}}</b-col>
          <b-col md="3">{{child.place}}</b-col>
          <b-col md="3">{{child.status}}</b-col>
        </b-row>
        <b-row v-if="child.expanded">
          <b-col>
            <b-container fluid>
              <b-row>
                <b-col md="1"></b-col>
                <b-col md="5" class="tree-node-label">Наименование</b-col>
                <b-col md="3" class="tree-node-label">Единица измерения</b-col>
                <b-col md="3" class="tree-node-label">Имя в устройстве</b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
      <b-container fluid v-else-if="child.isObject">
        <b-row>
          <b-col md="1">
            <a v-if="child.name" href="#" v-on:click.prevent="onExpandCollapseNode(child)" style="margin-right: 5px">
              <i v-if="child.expanded" class="fa fa-minus"></i>
              <i v-else class="fa fa-plus"></i>
            </a>
          </b-col>
          <b-col md="4">{{child.name}}</b-col>
        </b-row>
        <b-row v-if="child.expanded && child.children && child.children.length>0 && child.children[0].isDevice" style="line-height:40px">
          <b-col md="2"></b-col>
          <b-col md="3" class="tree-node-label">Прибор</b-col>
          <b-col md="3" class="tree-node-label">Точка учета</b-col>
          <b-col md="3" class="tree-node-label">Статус прибора</b-col>
        </b-row>
      </b-container>

      <b-container fluid v-if="!child.isTag && child.expanded">
        <b-row style="line-height:40px">
          <b-col md="12">

            <TreeNodeChecked :treeNode="child"
                             @onExpandCollapseNode="onExpandCollapseNode" />
          </b-col>
        </b-row>
      </b-container>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'TreeNodeChecked',
    props: {
      treeNode: { type: Object },
    },
    methods: {
      onExpandCollapseNode(node) {
        //console.log(node);
        if (!node.isTag) {
          this.$emit('onExpandCollapseNode', node);
        }
      },
    },
  }

</script>

<style scoped>
  .hide-item {
    display: none;
  }

  .checkbox {
    position: relative;
    display: block;
    margin-top: 2px;
    margin-bottom: 2px;
  }
</style>


