<template>
  <div class="graphic-content"
       @mouseenter="showGraphicButtonsOnHover=true"
       @mouseleave="showGraphicButtonsOnHover=false">

    <GraphicsViewer :ref="`viewer${windowId}`"
                    v-model="value"
                    :windowId="windowId"
                    @mounted="viewerMounted"
                    :chartHeight="chartHeight"
                    :chartWidth="chartWidth" />

    <Configurator :ref="`configurator${windowId}`"
                  :windowId="windowId"
                  @update="updateConfigResults"
                  @save="save"
                  @error="error"
                  @select="select"/>

    <GraphicsEditorModal :ref="`editor${windowId}`"
                         :refConfigurator="this.$refs[`configurator${this.windowId}`]"
                         :windowId="windowId"
                         v-model="value"
                         @cancel="cancel"
                         @mounted="editorMounted" />

    <div class="graphic-buttons" v-if="showGraphicButtonsOnHover">
      <b-button variant="primary"
                pill
                class="graphic-button"
                @click="edit">
        <i class="fa fa-pencil"></i>
      </b-button>
    </div>

    <b-overlay :show="isLoading" :bg-color="'transparent'" no-wrap> </b-overlay>
  </div>
</template>

<script>
  import GraphicsViewer from "@/components/graphic/GraphicsViewer";
  import GraphicsEditorModal from "@/views/graphic/GraphicsEditorModal";
  import Configurator from "@/components/graphic/Configurator";
  import Vue from 'vue'
  import MnemoHub from '@/models/mnemoHub.js'

  Vue.use(MnemoHub)
  Vue.prototype.startMnemoHub();

  export default {
    name: "GraphicsContent",

    components: {
      GraphicsViewer,
      GraphicsEditorModal,
      Configurator,
    },

    props: ["value", // selectedMnemonic.id
      "mode",
      "windowId"],

    data() {
      return {
        typeParameters: [
          { id: 1, name: "Газ" },
          { id: 2, name: "ЭC" },
          { id: 3, name: "Тепло" },
          { id: 4, name: "ХВС" },
          { id: 5, name: "ГВС" },
          { id: 6, name: "Датчики" },
        ],

        isLoading: false,
        result: null,
        isEdit: null,
        showGraphicButtonsOnHover: null,
        chartWidth: null,
        chartHeight: null,
      };
    },

    watch: {
      value(newVal, oldVal) {
        this.refresh();
      },
      mode(newVal, oldVal) {
        this.isEdit = newVal === "edit";
        //console.log(this.isEdit);
      },
    },
    mounted() {
      //console.log('mounted');
      //console.log(this.windowId);
      this.refresh();
    },
    methods: {

      resize(width, height) {
        //console.log('resize ' + width + ' ' + height);
        this.$refs[`viewer${this.windowId}`].resize(width, height);
      },

      edit() {
        //console.log('edit');
        //console.log(this.result);
        this.$refs[`editor${this.windowId}`].updateResults(this.result);
        this.$bvModal.show("editor" + this.windowId);
      },

      refresh() {
        this.isEdit = this.mode === "edit";
        this.isLoading = true;
        const groupId = parseInt(this.$route.query.groupId);
        //const windowId = parseInt(this.$route.query.windowId);
        //console.log(this.windowId);
        this.$refs[`configurator${this.windowId}`].init(
          this.value,
          this.typeParameters,
          false,
          groupId,
          this.windowId
        );
        if (!this.value) {
          this.$bvModal.show("editor" + this.windowId);
        }
      },
      cancel(mnemoInstanceId) {
        this.isLoading = false;

        //this.$refs[`viewer${this.windowId}`].updateResults(this.result);
        this.$emit("cancel", mnemoInstanceId);
      },
      save(result, mnemoInstanceId) {
        const self = this;
        this.isLoading = false;
        this.result = result;
        this.$bvModal.hide("editor" + this.windowId);

        setTimeout(function () {
          self.$refs[`viewer${self.windowId}`].updateResults(result);
          self.$emit("save", mnemoInstanceId);
          //console.log('save');
        }, 500);
      },
      error() {
        //console.log("error");
      },
      select(result) {
        this.result = result;
        this.isLoading = false;

        this.$refs[`editor${this.windowId}`].updateResults(result);
      },
      updateConfigResults(result) {
        this.result = result;
        this.isLoading = false;
        //console.log("Update results");

        this.$refs[`editor${this.windowId}`].updateResults(result);
        this.$refs[`viewer${this.windowId}`].updateResults(result);
      },

      viewerMounted() {
        this.$emit("onMounted");
        if (this.result) {
          //console.log("Viewer DOM mounted. Update results");
          this.$refs[`viewer${this.windowId}`].updateResults(this.result);
        }
      },

      editorMounted() {
        if (this.result) {
          //console.log("Editor DOM mounted. Update results");
          this.$refs[`editor${this.windowId}`].updateResults(this.result);
        }
      },
    },
  };
</script>
<style scoped>
  .graphic-content {
    position: relative;
  }

  .graphic-buttons {
    position: absolute;
    bottom: 12px;
    right: 2px;
    z-index: 100;
  }

    .graphic-buttons .graphic-button {
      height: 38px;
      width: 38px;
      display: block;
      padding: 0px;
      text-align: center;
      border-radius: 50%;
      margin-bottom: 5px;
      border-width: 0;
    }
</style>

